import { ModuleExportColumn, ModuleExportSettings } from './models';
import { CellObject, ExcelDataType, Range, utils, WorkBook, WorkSheet, write } from 'xlsx';
import FileSaver from 'file-saver';

const LIST_NAME = 'list-1';

class ExportExcel {
  async export(props: {
    columns: ModuleExportColumn[];
    data: any[];
    settings: ModuleExportSettings;
  }) {
    const { WB, WS } = ExportExcel.CreateScope(props.settings);

    const { range } = ExportExcel.CreateTable({ WS, ...props });
    ExportExcel.setMainArea({ WS, range });

    WB.Sheets[LIST_NAME] = WS;

    const wb_out = write(WB, { type: 'binary', bookType: 'xlsx', bookSST: false });

    return { wb_out };
  }

  async save({ wb_out, name }: { wb_out: any; name: string }) {
    return Promise.resolve().then(() => {
      FileSaver.saveAs(
        new Blob([ExportExcel.s2ab(wb_out)], { type: 'application/octet-stream' }),
        name + '.xlsx',
      );
    });
  }

  static CreateScope(settings: ModuleExportSettings): { WB: WorkBook; WS: WorkSheet } {
    const WB = utils.book_new();
    WB.Props = {
      Title: settings.title,
      CreatedDate: new Date(),
    };
    WB.SheetNames.push(LIST_NAME);

    const WS = ExportExcel.CreateWS();
    return {
      WB,
      WS,
    };
  }
  static setMainArea(props: { WS: WorkSheet; range: Range }) {
    const { WS, range } = props;
    WS['!ref'] = utils.encode_range({
      ...range,
    });
  }

  static CreateTable(props: {
    WS: WorkSheet;
    columns: ModuleExportColumn[];
    data: any[];
    startRow?: number;
  }): { range: Range } {
    const { startRow = 0, columns, data, WS } = props;
    let rowIndex = startRow;

    this.CreateTR({ WS, columns, rowIndex: rowIndex++ });

    data.forEach((rowData) => {
      this.CreateTR({ WS, columns, rowData, rowIndex: rowIndex++ });
    });

    return {
      range: {
        s: { c: 0, r: startRow },
        e: { c: columns.length - 1, r: rowIndex },
      },
    };
  }

  static CreateTR(props: {
    WS: WorkSheet;
    columns: ModuleExportColumn[];
    rowData?: any;
    rowIndex: number;
  }) {
    const { columns, rowData, WS, rowIndex } = props;
    return columns.forEach((column, i) => {
      return rowData
        ? this.CreateTD({ WS, column, rowIndex, columnIndex: i, rowData })
        : this.CreateHT({ WS, column, rowIndex, columnIndex: i });
    });
  }

  static CreateHT(props: {
    WS: WorkSheet;
    column: ModuleExportColumn;
    rowIndex: number;
    columnIndex: number;
  }) {
    const { column, WS, columnIndex, rowIndex } = props;
    const { title, width = 50 } = column;
    WS['!cols']?.push({ wch: width });

    const cell: CellObject = {
      v: title,
      t: this.GetCellType(),
      s: { font: { bold: true } }, // style
    };

    const position = utils.encode_cell({ c: columnIndex, r: rowIndex });
    WS[position] = ExportExcel.normalizeCellObject(cell);
  }

  static CreateTD(props: {
    WS: WorkSheet;
    column: ModuleExportColumn;
    rowData: any;
    rowIndex: number;
    columnIndex: number;
  }) {
    const { column, rowData, rowIndex, columnIndex, WS } = props;
    const { field, valueTemplate, type, tdStyle, valueLink } = column;

    const position = utils.encode_cell({ c: columnIndex, r: rowIndex });

    const cell = {
      t: this.GetCellType(type),
      v: valueTemplate ? valueTemplate(rowData) : rowData[field],
      s: tdStyle,
      l: valueLink && valueLink(rowData),
    };
    WS[position] = ExportExcel.normalizeCellObject(cell);
  }

  static GetCellType(type: ModuleExportColumn['type'] = 'string'): ExcelDataType {
    switch (type) {
      case 'boolean':
        return 'b';
      case 'date':
        return 'd';
      case 'link':
      case 'string':
        return 's';
    }
  }

  static CreateWS(): WorkSheet {
    return {
      '!cols': [],
      '!merges': [],
    };
  }

  static s2ab(s: any) {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  static normalizeCellObject(o: object) {
    const json_ = JSON.parse(JSON.stringify(o));
    return {
      ...json_,
      v: String(json_.v),
    };
  }
}

export const ModuleExportExcel = new ExportExcel();
