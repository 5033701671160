import { select } from 'modules/typed-saga';
import { toast } from 'react-toastify';
import { Action } from 'redux';
import { selectMapLabels } from 'store/labels/selectors';
import { parseErrorData } from 'utils/service';
import { calcRequestLabel } from 'utils/templates';

export const actionCreator =
  <T extends string>(type: T) =>
  <P = void>() =>
  (payload: P) => ({ type, payload });

export interface SagaActions {
  request(...args: any[]): any;

  success(...args: any[]): any;

  fail(...args: any[]): any;
}

type PayloadAsync<T> = T extends Object ? T & { onSuccess?: () => void; onError?: () => void } : T;
export const asyncActionCreator =
  <TR extends string, TS extends string, TF extends string>(
    typeRequest: TR,
    typeSuccess: TS,
    typeFail: TF,
  ) =>
  <PR = void, PS = void, PF = void>() => ({
    request: actionCreator<TR>(typeRequest)<PayloadAsync<PR>>(),
    success: actionCreator<TS>(typeSuccess)<PS>(),
    fail: actionCreator<TF>(typeFail)<PF>(),
  });

export type ExtractAction<T extends (p: any) => void> = ReturnType<T>;
export type ExtractPayload<T extends (p: any) => void> = ExtractAction<T>;

export type ExtractAsyncAction<T extends SagaActions> =
  | ReturnType<T['request']>
  | ReturnType<T['success']>
  | ReturnType<T['fail']>;

export type ExtractPromise<T> = T extends Promise<infer U> ? U : never;
export type ExtractCall<T> = T extends (...args: any) => Promise<any>
  ? ExtractPromise<ReturnType<T>>
  : never;

export const createCombineAction = <R, S, F>(request: R, success: S, fail: F) => {
  return { request, success, fail };
};

export const combineActions = <R, S, F>(request: R, success: S, fail: F) => {
  return { request, success, fail };
};

interface ThunkAction<E extends { message: string }> extends Action<string> {
  error: E;
}
export function* workerErrorNotifyThunk<T extends ThunkAction<Error>>(data: T) {
  if (data.error) {
    yield* notifyErrorSaga(data.error);
  }
}

interface SagaAction<E extends { message: string }> extends Action<any> {
  payload: { error: E };
}
export function* workerErrorNotifySaga<T extends SagaAction<Error>>(action: T) {
  if (action?.payload?.error) {
    yield* notifyErrorSaga(action.payload.error);
  }
}

export function* notifyErrorSaga(e: { message: string }) {
  const error = parseErrorData(e);
  const mapLabels = yield* select(selectMapLabels);

  const title = calcRequestLabel(error.message, (key) => {
    return mapLabels[key]?.title || key;
  });

  yield toast.error(title);
}
export function* notifySuccessSaga(message: string) {
  const mapLabels = yield* select(selectMapLabels);

  const title = calcRequestLabel(message, (key) => {
    return mapLabels[key]?.title || key;
  });

  yield toast.success(title);
}
