import { api, apiRtk, BASE_URL, transformResponseDynamic, uploadImage } from 'utils/service';
import { API_ACCOUNTS, AppUser, JWT, UserCompanyPatch, VisitorNew } from './models';

export * from './models';

class Service {
  async refreshToken(data: { token: string; refreshToken: string }) {
    const response = await api.post(API_ACCOUNTS.REFRESH_TOKEN, data);
    const {
      data: { token },
    } = response;

    return fetch(`${BASE_URL}${API_ACCOUNTS.GET_CURRENT_USER}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        cache: 'no-store',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        mode: 'cors',
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return response;
    });
  }
  async verifyReCaptcha(token: string) {
    return api
      .post(API_ACCOUNTS.VERIFY_RECAPTCHA, { token })
      .then(() => {
        return true;
      })
      .catch((e: Error) => {
        throw e;
      });
  }

  async getCurrentAppUser() {
    return api.get<AppUser>(API_ACCOUNTS.GET_CURRENT_USER, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 0,
      },
    });
  }

  async patch(newData: Partial<AppUser>, oldData: AppUser) {
    let dataToSave = { ...newData };

    dataToSave.userPhoto = await uploadImage(newData.userPhoto, oldData.userPhoto);
    return api.patch<string>(API_ACCOUNTS.PATCH, dataToSave);
  }

  async patchCompany(data: UserCompanyPatch) {
    const { pharmaCompanyUserID, ...rest } = data;
    return api.patch(API_ACCOUNTS.PATCH_COMPANY(pharmaCompanyUserID), rest);
  }

  async registerVisitor(data: VisitorNew) {
    return api.post<JWT>(API_ACCOUNTS.LOGIN_OR_REGISTER_VISITOR, data);
  }

  async generateCode(data: { mobilePhone: string; email: string }) {
    return api.post(API_ACCOUNTS.GENERATE_CODE, data);
  }

  async loginWithCode(p: { mobilePhone: string; code: string }) {
    return api.post<JWT>(API_ACCOUNTS.LOGIN_WITH_CODE, p);
  }

  async logout() {
    return api.post(API_ACCOUNTS.LOGOUT);
  }
}

export const ServiceAccount = new Service();

export const apiAccounts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getPharmaCompanies: build.query<{ id: string; title: string }[], void>({
      query: () => ({
        url: API_ACCOUNTS.GET_PHARMA_COMPANIES,
        params: { select: 'id,companyName as title' },
      }),
      transformResponse: transformResponseDynamic,
    }),
  }),
});
