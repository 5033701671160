import { ThemeOptions } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import VAR from 'styles/_variables.scss';

const appBreakpoints = {
  values: {
    xs: Number(VAR.xs),
    sm: Number(VAR.sm),
    md: Number(VAR.md),
    lg: Number(VAR.lg),
    xl: Number(VAR.xl),
  },
};

const breakpoints = createBreakpoints({ ...appBreakpoints });

export const themeOptions: ThemeOptions = {
  breakpoints: breakpoints,
  typography: {
    allVariants: {
      lineHeight: 1.43,
      fontWeight: 'normal',
      // color: '#fff',
    },
    fontSize: 14,
    htmlFontSize: 10,
    fontWeightRegular: 400,
    fontFamily: 'Arial, Helvetica, sans-serif;',
    h1: {
      fontSize: '3.2rem',
    },
    h2: {
      fontSize: '2.4rem',
      [breakpoints.down('md')]: {
        fontSize: '2.2rem',
      },
    },
    h3: {},
    h4: {
      fontSize: '2rem',
    },
    h5: {
      fontSize: '1.8rem',
    },
    h6: {
      fontSize: '1.6rem',
    },
    subtitle1: {},
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.2rem',
    },
  },

  palette: {
    primary: {
      main: VAR.colorPrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: VAR.colorSecondary,
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(225, 225, 225, .5)',
      disabled: 'rgba(225, 225, 225, .5)',
    },
    action: {
      disabled: 'rgba(225, 225, 225, .5)',
    },
  },

  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        minHeight: '8rem',
        height: 'inherit',
        padding: '0.8rem',
        borderBottom: '1px solid #eee',
        '& + .MuiPickersBasePicker-pickerView': {
          backgroundColor: VAR.colorPrimary,
          minWidth: '100%',
        },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        height: '2.8rem',
        width: '2.8rem',
        color: '#fff',
        backgroundColor: VAR.colorSecondary,
        '&:hover': {
          backgroundColor: '#428000',
        },
      },
      switchHeader: {
        padding: '0.6rem 1rem 1.6rem',
        marginTop: '0',
        marginBottom: '0',
      },
      daysHeader: {
        maxWidth: '25.2rem',
        margin: '0 auto',
      },
      dayLabel: {
        color: '#fff',
        flexGrow: '1',
      },
    },
    MuiPickersCalendar: {
      week: {
        display: 'flex',
        maxWidth: '25.2rem',
        margin: '0 auto',
        '& > *': {
          flexGrow: '1',
        },
      },
      transitionContainer: {
        marginTop: '0.6rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 4,
        minWidth: 50,
        fontSize: '1.4rem',
        lineHeight: '1.1',
        textAlign: 'center',
        minHeight: '3.6rem',
      },
      sizeLarge: {
        fontSize: '1.4rem',
        lineHeight: '1.2',
        minHeight: '4.6rem',
      },
      sizeSmall: {
        fontSize: '1.2rem',
        lineHeight: '1',
        minHeight: '2.6rem',
      },
    },

    MuiFab: {
      root: {
        boxShadow: 'none',
        height: '4.6rem',
        width: '4.6rem',
      },
      sizeMedium: {
        height: '3.6rem',
        width: '3.6rem',
      },
    },

    MuiPaper: {
      root: {
        color: '#fff',
        backgroundColor: VAR.colorPrimaryLight,
      },
      rounded: {
        borderRadius: '0.8rem',
      },
    },

    MuiInputBase: {
      root: {
        color: '#fff',
      },
      input: {
        height: '1.215em',
      },
    },

    MuiFormLabel: {
      root: {
        color: VAR.colorBlue1,
      },
    },

    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: VAR.colorBlue1,
        },
      },
      outlined: {
        '&.MuiInputLabel-marginDense': {
          top: '2px',
        },
        '&.MuiInputLabel-shrink': {
          top: '0',
        },
      },
    },

    MuiFormControl: {
      root: {
        '& .MuiSvgIcon-root': {
          color: VAR.colorBlue1,
        },
        '& .MuiInputAdornment-positionStart': {
          marginRight: '0',
        },
        '& .MuiAutocomplete-tag': {
          maxWidth: 'calc(100% - 30px)',
        },
      },
    },

    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: VAR.colorBlue1,
          opacity: 0.2,
        },
        '&:hover&:before': {
          borderBottomColor: VAR.colorBlue1,
          opacity: 0.5,
        },
        '&:after': {
          borderBottomColor: VAR.colorWhite,
        },
      },
      input: {
        '&::placeholder': {
          color: VAR.colorBlue1,
          opacity: 1,
        },
      },
    },

    MuiOutlinedInput: {
      input: {
        height: '1.7859em',
      },
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: VAR.colorBlue1,
          opacity: 0.5,
        },
        '&$focused': {
          borderColor: VAR.colorWhite,
        },
      },
      notchedOutline: {
        borderColor: VAR.colorBlue1,
        opacity: 0.2,
      },
    },

    MuiChip: {
      root: {
        backgroundColor: VAR.colorSecondary,
        height: '2.263rem',
        color: VAR.colorBlue1,
      },
      deleteIcon: {
        height: '1.8rem',
        width: '1.8rem',
        '&:hover': {
          color: VAR.colorWhite,
        },
      },
    },

    MuiTab: {
      root: {
        fontSize: '1.8rem',
        border: '1px solid rgba(207,220,231,0.2)',
        borderBottom: 'none',
        borderTop: '3px solid #7095AC',
        borderRadius: '0.8rem 0.8rem 0 0',
        marginRight: '1rem',
        padding: ' 0.7rem 2.5rem 0.5rem',
        minHeight: '100%',
        height: '100%',
        maxWidth: '40rem',
        [breakpoints.down('md')]: {
          fontSize: '1.4rem',
          minWidth: '5rem',
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
          marginRight: '0.4rem',
          maxWidth: '26rem',
        },
        [breakpoints.down('sm')]: {
          maxWidth: '20rem',
        },
        '&$selected': {
          backgroundColor: VAR.colorPrimaryLight,
          borderColor: '#2d4f71',
          borderTopColor: VAR.colorSecondary,
        },
      },
      textColorPrimary: {
        color: VAR.colorBlue1,
        '&$selected': {
          color: VAR.colorWhite,
        },
      },
    },

    MuiTabs: {
      root: {
        minHeight: '5.4rem',
        [breakpoints.down('md')]: {
          minHeight: '4.8rem',
        },
      },
      indicator: {
        display: 'none',
      },
      flexContainer: {
        height: '100%',
      },
    },

    MuiDivider: {
      light: {
        backgroundColor: 'rgba(225,225,225, .1)',
      },
    },
  },

  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
};
