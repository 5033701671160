import { call, put, select, takeEvery, throttle } from 'modules/typed-saga';
import { Message, ServiceMessages } from 'services/messages';
import { selectAccountCompany, selectAccountPermissions } from 'store/account';
import { createFilter, createFilterDate, createFilterSmartSearch, mergeFilters } from 'utils';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifySaga } from 'utils/store';
import { selectMessagesFilters, selectMessagesTab } from './selectors';
import { actionMessagesGet, actionMessagesSetTab, MESSAGE_FILTER } from './slice';

const createTabFilter = (
  tabFilter: MESSAGE_FILTER,
  pharmaUserID: string,
  isAdmin: boolean,
  pharmaCompanyDepartmentID: string,
) => {
  const isAdminFilter = !isAdmin
    ? `&&pharmaCompanyDepartmentID=="${pharmaCompanyDepartmentID}"`
    : '';

  switch (tabFilter) {
    case MESSAGE_FILTER.INBOX: {
      return `reply!=null&pharmaCompanyUserID=="${pharmaUserID}"`;
    }
    case MESSAGE_FILTER.SENT: {
      return `isDraft==false&reply==null&pharmaCompanyUserID=="${pharmaUserID}"`;
    }
    case MESSAGE_FILTER.DRAFT: {
      return `isDraft==true&pharmaCompanyUserID=="${pharmaUserID}"`;
    }
    case MESSAGE_FILTER.TEAM: {
      return `pharmaCompanyUserID!="${pharmaUserID}"${isAdminFilter}`;
    }
  }
};

function* workerGetMessages() {
  const { search, date } = yield* select(selectMessagesFilters);
  const currentTab = yield* select(selectMessagesTab);

  const company = yield* select(selectAccountCompany);

  const { isAdmin } = yield* select(selectAccountPermissions);

  if (!company) {
    throw new Error('company-is-not-defined');
  }

  const { pharmaCompanyID, pharmaCompanyUserID, pharmaCompanyDepartmentID } = company;

  const filter = mergeFilters(
    [
      mergeFilters(
        [
          createFilterSmartSearch<Message>(
            ['userExpertProfileLastName', 'userExpertProfileFirstName'],
            search,
          ),
          createFilter<Message>(['message', 'subject'], 'contains', search),
        ],
        '||',
      ),
      createFilterDate<Message>('messageDate', date),
      createTabFilter(currentTab, pharmaCompanyUserID, isAdmin, pharmaCompanyDepartmentID),
    ],
    '&&',
  );

  try {
    const {
      data: { value },
    } = yield* call(ServiceMessages.getAllMessagesDynamic, {
      pharmaCompanyID,
      filter,
      orderBy: 'messageDate desc',
    });

    yield* put(actionMessagesGet.success({ value }));
  } catch (e) {
    yield* put(actionMessagesGet.fail({ error: parseErrorData(e) }));
  }
}

function* watchSetFilters(action: ReturnType<typeof actionMessagesSetTab>) {
  const { isGuest } = yield* select(selectAccountPermissions);
  if (isGuest && action.payload !== MESSAGE_FILTER.TEAM) {
    yield* put(actionMessagesSetTab(MESSAGE_FILTER.TEAM));
  }
  // if (action.payload === MESSAGE_FILTER.TEAM && !isAllowViewMessagesTabTeam) {
  //   yield* put(actionMessagesSetTab(MESSAGE_FILTER.INBOX));
  // }
}

export const sagasMessages = [throttle(1500, actionMessagesGet.request, workerGetMessages)];
export const sagasCurrentMessage = [
  takeEvery(actionMessagesSetTab, watchSetFilters),
  takeEvery([actionMessagesGet.fail], workerErrorNotifySaga),
];
