import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUser } from 'services/account';
import {
  actionAccountGenerateCode,
  actionAccountGetUser,
  actionAccountLogin,
  actionAccountLogout,
} from 'store/account/actions';
import { combineActions } from 'utils/store';

interface State {
  init: boolean;
  loading: boolean;
  user: null | AppUser;
  error: null | Error;
  code: boolean;
  userCompanyID: string | null;
}

const initState = (): State => {
  return {
    init: false,
    loading: false,
    user: null,
    error: null,
    code: false,
    userCompanyID: null,
  };
};

const slice = createSlice({
  name: 'ACCOUNT',
  initialState: initState(),
  reducers: {
    actionAccountCompanySet(state, action: PayloadAction<string | null>) {
      state.userCompanyID = action.payload;
    },
    actionAccountAutoSaveRequest(state, action: PayloadAction<Partial<AppUser>>) {},
    actionAccountAutoSaveSuccess(state) {},
    actionAccountAutoSaveFail(state, action: PayloadAction<{ error: Error }>) {},
  },
  extraReducers: (build) => {
    build.addCase(actionAccountGetUser.fulfilled, (state, action) => {
      const currentCompany = action.payload.companies.find(
        ({ pharmaCompanyID }) => pharmaCompanyID === state.userCompanyID,
      );

      state.loading = false;
      state.user = action.payload;
      state.init = true;
      state.userCompanyID = currentCompany
        ? currentCompany.pharmaCompanyID
        : action.payload.companies.length === 1
        ? action.payload.companies[0].pharmaCompanyID
        : null;
    });
    build.addCase(actionAccountGenerateCode.fulfilled, (state, action) => {
      state.loading = false;
      state.init = true;
      state.code = true;
    });
    build.addCase(actionAccountLogout.pending, (state) => {
      state.loading = true;
    });
    build.addCase(actionAccountLogout.fulfilled, (state) => {
      return initState();
    });
    build.addCase(actionAccountGetUser.rejected, (state) => {
      state.loading = false;
      state.init = true;
    });

    build.addMatcher(
      (action) => {
        return [
          actionAccountGetUser.pending,
          actionAccountGenerateCode.pending,
          actionAccountLogin.pending,
        ].some((item) => item.type === action.type);
      },
      (state) => {
        state.loading = true;
        state.error = null;
      },
    );

    build.addMatcher(
      (action) => {
        return [
          actionAccountGenerateCode.rejected,
          actionAccountLogin.rejected,
          actionAccountLogout.rejected,
        ].some((item) => item.type === action.type);
      },
      (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.init = true;
      },
    );
  },
});
const actions = slice.actions;
export const { actionAccountCompanySet } = actions;
export const actionAccountAutoSave = combineActions(
  actions.actionAccountAutoSaveRequest,
  actions.actionAccountAutoSaveSuccess,
  actions.actionAccountAutoSaveFail,
);
export const reducerAccount = slice.reducer;
