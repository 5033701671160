import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { all } from 'modules/typed-saga';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistMigrate } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { reducerDashboardActivities, sagasDashboardActivities } from 'store/dashboard-activities';
import {
  reducerDashboardInteractions,
  sagasDashboardInteractions,
} from 'store/dashboard-interactions';
import { rtkErrorLoggerMiddleware } from 'store/middleware';
import { reducerMyDoctors, sagasMyDoctors } from 'store/my-doctors';
import { apiRtk } from 'utils/service';
import { version } from '../../package.json';
import { reducerAccount, sagasAccount } from './account';
import { reducerArticle, sagasArticle } from './article';
import { reducerArticles, sagasArticles } from './articles';
import { reducerDocuments, sagasDocuments } from './documents';
import { reducerExperts, sagasExperts } from './experts';
import { reducerLabels, sagasLabels } from './labels';
import {
  reducerReplyMessageTemplates,
  sagasMessageReplyTemplates,
} from './message-reply-templates';
import { reducerMessageTemplates, sagasMessageTemplates } from './message-templates';
import { reducerMessages, sagasCurrentMessage, sagasMessages } from './messages';

const VERSION = parseInt(version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state, currentVersion) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const rootReducer = combineReducers({
  auth: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'auth',
      storage,
      whitelist: ['userCompanyID'],
    },
    reducerAccount,
  ),
  messages: reducerMessages,
  articles: reducerArticles,
  article: reducerArticle,
  documents: reducerDocuments,
  messageTemplates: reducerMessageTemplates,
  messageReplyTemplates: reducerReplyMessageTemplates,
  experts: reducerExperts,
  myDoctors: reducerMyDoctors,
  dashboardInteractions: reducerDashboardInteractions,
  dashboardActivities: reducerDashboardActivities,
  labels: reducerLabels,
  [apiRtk.reducerPath]: apiRtk.reducer,
});

function* rootSaga() {
  yield all([
    ...sagasArticles,
    ...sagasDocuments,
    ...sagasMessages,
    ...sagasCurrentMessage,
    ...sagasLabels,
    ...sagasExperts,
    ...sagasAccount,
    ...sagasArticle,
    ...sagasDashboardInteractions,
    ...sagasDashboardActivities,
    ...sagasMessageTemplates,
    ...sagasMessageReplyTemplates,
    ...sagasMyDoctors,
  ]);
}

const sagaMiddleware = createSagaMiddleware();
export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(rtkErrorLoggerMiddleware)
      .concat(apiRtk.middleware);
  },
});

export const appPersistor = persistStore(appStore);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
