import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppAsyncThunkConfig } from 'store';
import { parseErrorData } from 'utils/service';
import { AppUser, ServiceAccount } from 'services/account';

export const actionDashboardInteractions = createAsyncThunk<AppUser, void, AppAsyncThunkConfig>(
  `DASHBOARD_INTERACTIONS/getUser`,
  async () => {
    try {
      const { data: user } = await ServiceAccount.getCurrentAppUser();
      return user;
    } catch (e) {
      throw parseErrorData(e);
    }
  },
);
