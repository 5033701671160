import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArticleBase } from 'services/articles';
import { actionAccountCompanySet, actionAccountLogout } from 'store/account';
import { createCombineAction } from 'utils/store';

interface Filters {
  search: string;
  category: string;
  date: string[];
  showOnlyMyCompany: boolean;
}

export enum ARTICLE_SORT {
  POPULAR = 'totalExposures desc',
  LATEST = 'publishDate desc',
}

interface State {
  isLoading: boolean;
  isInit: boolean;
  error: null | Error;
  data: ArticleBase[];
  filters: Filters;
  take: number;
  page: number;
  count: number;
  orderBy: string;
}

const initState = (): State => {
  return {
    isLoading: false,
    isInit: false,
    error: null,
    data: [],
    filters: {
      search: '',
      category: '',
      date: [],
      showOnlyMyCompany: true,
    },
    take: 6,
    page: 1,
    count: 0,
    orderBy: ARTICLE_SORT.LATEST,
  };
};

const slice = createSlice({
  name: 'ARTICLES',
  initialState: initState(),
  reducers: {
    getArticlesListRequest(state, action: PayloadAction<{ page: number } | undefined>) {
      state.error = null;
      state.isLoading = true;
      state.page = (action.payload && action.payload.page) || state.page;
    },
    getArticlesListSuccess(
      state,
      action: PayloadAction<{
        value: ArticleBase[];
        count: number;
      }>,
    ) {
      const { value, count } = action.payload;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
      state.count = count;
    },
    getArticlesListFail(state, action: PayloadAction<{ error: Error }>) {
      state.error = action.payload.error;
      state.isLoading = false;
      state.isInit = true;
    },
    setArticlesListFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setArticlesListOrder(state, action: PayloadAction<string>) {
      state.orderBy = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAccountCompanySet, () => {
      return initState();
    });
    build.addMatcher(
      (action) => {
        return [actionAccountLogout].some(
          (actionItem) => actionItem.fulfilled.type === action.type,
        );
      },
      () => {
        return initState();
      },
    );
  },
});

const actions = slice.actions;

export const actionArticlesGet = createCombineAction(
  actions.getArticlesListRequest,
  actions.getArticlesListSuccess,
  actions.getArticlesListFail,
);

export const { setArticlesListFilters, setArticlesListOrder } = slice.actions;

export const reducerArticles = slice.reducer;
