const API = 'ExpertProfiles';

export const API_EXPERTS = {
  GET_DYNAMIC: `${API}/GetExpertsDynamic`,
  GET_ALL_DYNAMIC: `${API}/GetPharmaCompanyExpertsDynamic`,
  GET_ACTIVITIES_DYNAMIC: `${API}/GetExpertActivitiesDynamic`,
};

export interface Expert {
  appIdentityUserID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldTitle: string;
  userExpertProfileSpeakLanguageTitles: string;
  secondaryMedicalFieldID: string;
  fullName: string;
  color: string;
  isHmo: boolean;
  hmoID: string;
  hmoTitle: string;
  hmoName: string;
  hmoCityTitle: string;
  isHospital: boolean;
  hospitalID: string;
  hospitalDepartmentID: string;
  hospitalTitle: string;
  hospitalDepartmentTitle: string;
  hospitalPosition: string;
  isPrivateWorkPlaceName: boolean;
  privateWorkPlaceName: string;
  privateWorkPlaceMedicalPractice: string;
  otherWorkPlaceTitle: string;
  expertTypeID: string;
  primaryMedicalFieldID: string;
  subject: string;
  totalActivities: number;
}
export interface ExpertActivity {
  articleID: string;
  publishDate: string;
  articleTitle: string;
  articleCategoryID: string;
  articleCategoryTitle: string;
  articleCategoryIcon: string;
}
