import { selectAccountUser } from 'store/account';
import { useAppSelector } from './use-app-selector';

export const useCurrentUser = () => {
  const user = useAppSelector(selectAccountUser);

  if (!user) {
    throw new Error('private-only');
  }
  return user;
};
