import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSixMonthBeforeTodayDate, getTodayDate } from 'utils';
import { actionAccountCompanySet } from '../account';

interface Filters {
  period: string[];
  articleID: string | null;
  showOnlyMyCompany: boolean;
}

interface State {
  filters: Filters;
}

export enum CATEGORY {
  ACTIVITY = 'activity',
  HMO = 'hmo',
  MEDICAL_FIELDS = 'medical-fields',
  HOSPITALS = 'hospitals',
  EXPOSURES = 'exposures',
}

type Activity = {
  type: CATEGORY.ACTIVITY;
  date?: string;
};

type Hmo = {
  type: CATEGORY.HMO;
  hmoIDs: string[];
};

type MedicalFields = {
  type: CATEGORY.MEDICAL_FIELDS;
  medicalFieldIDs: string[];
};

type Hospitals = {
  type: CATEGORY.HOSPITALS;
  hospitalIDs: string[];
};

type Exposures = {
  type: CATEGORY.EXPOSURES;
};

export type DashboardUsersActivity = Activity | Hmo | MedicalFields | Exposures | Hospitals;

const initState = (): State => {
  return {
    filters: {
      period: [getSixMonthBeforeTodayDate().toISOString(), getTodayDate().toISOString()],
      articleID: null,
      showOnlyMyCompany: true,
    },
  };
};

const slice = createSlice({
  name: 'DASHBOARD_ACTIVITIES',
  initialState: initState(),
  reducers: {
    actionDashboardActivitiesSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAccountCompanySet, (state) => {
      state.filters.articleID = null;
    });
  },
});
const actions = slice.actions;
export const { actionDashboardActivitiesSetFilters } = actions;
export const reducerDashboardActivities = slice.reducer;
