import { useNotify } from 'hooks/use-notify';
import { ModuleExportExcel } from 'modules/export-excel';
import { ModuleExportColumn } from 'modules/export-excel/models';
import { useCallback, useState } from 'react';
import { api } from 'utils/service';

export const useExportToExcel = () => {
  const [loading, setLoading] = useState(false);
  const { onError, onSuccess } = useNotify();

  const exportToExcel = useCallback(
    async (p: { url: string; params: any; columns: ModuleExportColumn[]; title: string }) => {
      const { columns, url, title } = p;
      setLoading(true);
      try {
        const params = {
          select: columns.map(({ field }) => field).join(','),
        };
        const { data } = await api.get(url, { params: { ...params, ...p.params } });

        const { wb_out } = await ModuleExportExcel.export({
          columns,
          data: data.value,
          settings: { title },
        });
        await ModuleExportExcel.save({ wb_out, name: title });
        onSuccess();
      } catch (e) {
        onError(e.e);
      } finally {
        setLoading(false);
      }
    },
    [onSuccess, onError],
  );

  return {
    exportToExcel,
    loading,
  };
};
