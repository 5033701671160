import { call, put, select, takeEvery, throttle } from 'modules/typed-saga';
import { MessageTemplateItem, ServiceMessageTemplates } from 'services/message-templates';
import { createFilter } from 'utils';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifySaga } from 'utils/store';
import { selectAccountCompanyID } from '../account';
import { selectMessageTemplatesFilters } from './selectors';
import { actionMessageTemplatesGet } from './slice';

function* workerGetMessageTemplates() {
  const { search } = yield* select(selectMessageTemplatesFilters);
  const pharmaCompanyID = yield* select(selectAccountCompanyID);

  const filter = createFilter<MessageTemplateItem>(
    ['title', 'subject', 'message'],
    'contains',
    search,
  );

  try {
    if (!pharmaCompanyID) {
      throw new Error('unexpected-behaviour');
    }
    const {
      data: { value },
    } = yield* call(ServiceMessageTemplates.getAllDynamic, {
      pharmaCompanyID,
      filter,
    });
    yield* put(actionMessageTemplatesGet.success({ value }));
  } catch (e) {
    yield* put(actionMessageTemplatesGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasMessageTemplates = [
  throttle(1500, actionMessageTemplatesGet.request, workerGetMessageTemplates),
  takeEvery([actionMessageTemplatesGet.fail], workerErrorNotifySaga),
];
