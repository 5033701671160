import { format } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import { convertToDate, DateValue } from 'utils/dates';

export type ArrayType<T> = T extends Array<infer U> ? U : never;
type Separator = '==' | '!=' | '>=' | '<=' | 'contains';
type Value = null | undefined | string | boolean | number;
type ValidValue = string | boolean | number;

const prepareValue = (value?: ValidValue) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query;
};

type ValueCreator = (name: string, separator: string, value: ValidValue) => string;

const getValueCreator = (separator: Separator): ValueCreator => {
  switch (separator) {
    case 'contains':
      return (name: string, separator: string, value: ValidValue) =>
        `${name}.${separator}("${prepareValue(value)}")`;
    default:
      return (name: string, separator: string, value: ValidValue) =>
        `${name}${separator}"${prepareValue(value)}"`;
  }
};

interface Options {
  valueCreator?: ValueCreator;
  multipleSeparator?: string;
}
export const createFilter = <T>(
  name: keyof T | (keyof T)[],
  separator: Separator,
  value: Value | Value[],
  options?: Options,
): string | undefined => {
  const { valueCreator = getValueCreator(separator), multipleSeparator = '||' } = options || {};
  const names = Array.isArray(name) ? name : [name];
  if (value === '' || value === null || value === undefined) {
    return undefined;
  }

  if (Array.isArray(value)) {
    const values = value.map((v) => createFilter<T>(name, separator, v)).filter(Boolean);
    return values.length ? createStart(value.length, values.join(multipleSeparator)) : undefined;
  }

  return createStart(
    names.length,
    names.map((_n) => valueCreator(String(_n), separator, value)).join(multipleSeparator),
  );
};

export const createFilterDate = <T extends object = {}>(name: keyof T, value: DateValue[]) => {
  const valueCreator: ValueCreator = (name, separator, value) =>
    `${name}${separator}DateTime(${prepareValue(value)})`;
  const options = {
    valueCreator,
  };
  const valueFilter = [
    value[0]
      ? createFilter<T>(name, '>=', format(convertToDate(value[0]), 'yyyy,MM,dd,00,00,00'), options)
      : undefined,
    value[1]
      ? createFilter<T>(name, '<=', format(convertToDate(value[1]), 'yyyy,MM,dd,23,59,59'), options)
      : undefined,
  ];
  return mergeFilters(valueFilter, '&&');
};

export const mergeFilters = (filters: (string | undefined | null)[], separator: string) => {
  const filtered = filters.filter(Boolean);
  if (filtered.length === 0) {
    return undefined;
  }
  return createStart(filtered.length, filtered.join(separator));
};

export const createFilterSmartSearch = <T>(
  name: keyof T | (keyof T)[],
  value: string | undefined,
) => {
  const names = Array.isArray(name) ? name : [name];
  if (!value) return undefined;

  const field = names.map((_name) => `${_name}.replace(" ",String.Empty)`).join('+');
  return createFilter(`(${field})`, 'contains', String(value).replace(/ /g, ''));
};

export const getSixMonthBeforeTodayDate = () => {
  return startOfDay(sub(new Date().setHours(0, 0, 0, 0), { months: 1 }));
};

export const getTodayDate = () => {
  return endOfDay(new Date().setHours(23, 59, 0, 0));
};
