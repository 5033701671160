import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.messages;
export const selectMessagesState = createSelector(selectState, (state) => state);
export const selectMessagesFilters = createSelector(selectMessagesState, ({ filters }) => {
  return filters;
});
export const selectMessagesMessageID = createSelector(
  selectMessagesState,
  ({ currentMessageID }) => {
    return currentMessageID;
  },
);
export const selectMessagesTab = createSelector(selectMessagesState, ({ currentTab }) => {
  return currentTab;
});
