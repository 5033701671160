import React, { useMemo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { useTheme } from '@material-ui/core';

interface Props {
  className?: string;
  divider?: React.ReactNode;
  spacing?: number;
  direction?: 'row' | 'column';
}

export const Stack: React.FC<Props> = ({
  className,
  children,
  divider,
  spacing = 0,
  direction = 'row',
}) => {
  const theme = useTheme();

  const styles = useMemo(() => {
    const space = theme.spacing(spacing);
    return {
      ...(direction === 'row'
        ? { ...(theme.direction === 'ltr' ? { marginLeft: space } : { marginRight: space }) }
        : {}),
      ...(direction === 'column' ? { marginTop: space } : {}),
    };
  }, [direction, spacing, theme]);
  const Items = useMemo(() => {
    return React.Children.toArray(children).map((element, i) => {
      if (React.isValidElement(element)) {
        return React.cloneElement(element, {
          ...element.props,
          style: { ...element.props.style, ...(i !== 0 ? styles : {}) },
        });
      }
      return null;
    });
  }, [children, styles]);

  const Divider = useMemo(() => {
    if (React.isValidElement(divider)) {
      return React.cloneElement(divider, {
        ...divider.props,
        style: { ...divider.props.style, ...styles },
      });
    }
    return null;
  }, [divider, styles]);

  return (
    <div className={clsx(style.root, className)} style={{ flexDirection: direction }}>
      {Items.map((Component, i, arr) => (
        <React.Fragment key={i}>
          {Component}
          {arr.length - 1 !== i && Divider}
        </React.Fragment>
      ))}
    </div>
  );
};
