const API = 'Parameters';

export const API_CREDIT_POINTS = {
  GET_ALL_DYNAMIC: `${API}/GetCreditPointsDynamic`,
};

export interface CreditPoint {
  id: string;
  title: string;
  color: string;
  points: number;
  rank: number;
}
