import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppAsyncThunkConfig } from 'store';
import { parseErrorData } from 'utils/service';
import { AppUser, ServiceAccount, UserCompanyPatch, VisitorNew } from 'services/account';
import { updateAuthTokens } from 'utils/cookies';
import { selectAccountUser } from 'store/account/selectors';

export const actionAccountGetUser = createAsyncThunk<AppUser, void, AppAsyncThunkConfig>(
  `ACCOUNT/getUser`,
  async () => {
    try {
      const { data: user } = await ServiceAccount.getCurrentAppUser();
      return user;
    } catch (e) {
      throw parseErrorData(e);
    }
  },
);

export const actionAccountUpdateUser = createAsyncThunk<
  void,
  Partial<AppUser>,
  AppAsyncThunkConfig
>(`ACCOUNT/updateUser`, async (data, { getState, dispatch }) => {
  try {
    const oldUser = selectAccountUser(getState());
    if (!oldUser) {
      throw new Error('unexpected-behaviour');
    }
    await ServiceAccount.patch(data, oldUser);
    await dispatch(actionAccountGetUser()).unwrap();
  } catch (e) {
    throw parseErrorData(e);
  }
});
export const actionAccountUpdateUserCompany = createAsyncThunk<
  void,
  UserCompanyPatch,
  AppAsyncThunkConfig
>(`ACCOUNT/updateUserCompany`, async (data, { dispatch }) => {
  try {
    await ServiceAccount.patchCompany(data);
    await dispatch(actionAccountGetUser()).unwrap();
  } catch (e) {
    throw parseErrorData(e);
  }
});

export const actionAccountVisitorLogin = createAsyncThunk<void, VisitorNew, AppAsyncThunkConfig>(
  `ACCOUNT/visitor-login`,
  async (data, { dispatch }) => {
    try {
      const {
        data: {
          expires,
          jwt: { token, refreshToken },
        },
      } = await ServiceAccount.registerVisitor(data);
      updateAuthTokens({ token, refreshToken, expires });
      await dispatch(actionAccountGetUser()).unwrap();
    } catch (e) {
      throw parseErrorData(e);
    }
  },
);

export const actionAccountLogin = createAsyncThunk<
  void,
  { mobilePhone: string; code: string },
  AppAsyncThunkConfig
>(`ACCOUNT/login`, async (data, { dispatch }) => {
  try {
    const {
      data: {
        expires,
        jwt: { token, refreshToken },
      },
    } = await ServiceAccount.loginWithCode(data);
    updateAuthTokens({ token, refreshToken, expires });
    await dispatch(actionAccountGetUser()).unwrap();
  } catch (e) {
    throw parseErrorData(e);
  }
});

export const actionAccountLogout = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `ACCOUNT/logout`,
  async () => {
    try {
      await ServiceAccount.logout();
      updateAuthTokens();
    } catch (e) {
      throw parseErrorData(e);
    }
  },
);
export const actionAccountGenerateCode = createAsyncThunk<
  void,
  { mobilePhone: string; email: string },
  AppAsyncThunkConfig
>(`ACCOUNT/generateCode`, async (data) => {
  try {
    await ServiceAccount.generateCode(data);
  } catch (e) {
    throw parseErrorData(e);
  }
});
