import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import 'styles/index.scss';

// store
import { Provider } from 'react-redux';
import { appPersistor, appStore } from 'store';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AppAuthProvider, AppTranslateProvider, AxiosInterceptors, ThemeProvider } from 'contexts';
import { AppNotificationsProvider } from 'contexts/notifications';
import { AppRouter } from 'router';
import { AppInitLoader } from 'contexts/init-loader';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Provider store={appStore}>
        <PersistGate persistor={appPersistor} loading={null}>
          <AppNotificationsProvider>
            <AxiosInterceptors>
              <AppAuthProvider>
                <AppTranslateProvider>
                  <ThemeProvider>
                    <AppInitLoader>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <AppRouter />
                      </MuiPickersUtilsProvider>
                    </AppInitLoader>
                  </ThemeProvider>
                </AppTranslateProvider>
              </AppAuthProvider>
            </AxiosInterceptors>
          </AppNotificationsProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
