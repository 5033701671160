import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { actionAccountGetUser, selectStateAccount } from 'store/account';

export const AppAuthProvider: React.FC = ({ children }) => {
  const auth = useAppSelector(selectStateAccount);
  const { init, loading } = auth;
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!init && !loading) {
      dispatch(actionAccountGetUser());
    }
  }, [init, loading, dispatch]);
  return <>{children}</>;
};
