import { TextFieldProps } from '@material-ui/core';
import { useCallback } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { getHandlerKayPress } from 'utils/handlers';
import { decorateAfter, fieldToLabelKey } from 'utils/other';
import { useTranslate } from './use-translate';

type Props = Omit<TextFieldProps, 'onChange'> & {
  onChange: () => void;
};

export const useFilterInputProps = (props: Props) => {
  const { t } = useTranslate();
  return useCallback(
    (renderProps: ControllerRenderProps) => {
      return {
        ...props,
        ...renderProps,
        onKeyPress: getHandlerKayPress('Enter', props.onChange),
        onBlur: decorateAfter(renderProps.onBlur, props.onChange),
        onClear: props.onChange,
        label: t(fieldToLabelKey(renderProps.name)),
      };
    },
    [props, t],
  );
};
