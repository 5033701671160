import { api } from 'utils/service';
import { API_LABELS, Label } from './models';

export * from './models';

class ServiceLang {
  async getDefaultLanguageID() {
    return api.get<string>(API_LABELS.GET_DEFAULT_LANGUAGE);
  }

  async getLabels(languageID: string) {
    const params = {
      filter: `languageID=="${languageID}"`,
    };
    return api.get<{ value: Label[] }>(API_LABELS.GET_ALL_DYNAMIC, { params });
  }
}

export const ServiceLabels = new ServiceLang();
