import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/pagination';

const selectState = (state: AppState) => state.articles;
const selectArticlesState = createSelector(selectState, (state) => state);

export const selectArticlesFilters = createSelector(selectArticlesState, ({ filters }) => {
  return filters;
});
export const selectArticlesStatuses = createSelector(
  selectArticlesState,
  ({ isInit, isLoading, error, orderBy }) => {
    return { isInit, isLoading, error, orderBy };
  },
);
export const selectArticlesData = createSelector(selectArticlesState, ({ data }) => {
  return data;
});
export const selectArticlesPagination = createSelector(
  selectArticlesState,
  ({ take, page, count }) => {
    return calcPaginationState({ take, page, count });
  },
);
