import { useCallback, useMemo, useState } from 'react';

export const useOpenValue = <T extends any = undefined>(init: T | undefined = undefined) => {
  const [openValue, setOpenValue] = useState<T | undefined>(init);

  const onOpen = useCallback((value: T) => {
    setOpenValue(value);
  }, []);
  const onClose = useCallback(() => {
    setOpenValue(undefined);
  }, []);

  return useMemo(() => {
    return {
      openValue,
      setOpenValue,
      onOpen,
      onClose,
    };
  }, [openValue, setOpenValue, onOpen, onClose]);
};
