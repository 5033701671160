import { useCallback, useRef } from 'react';
import { debounce } from 'utils';

export const useRegisterDebounce = <T extends (...args: any[]) => void>(delay: number) => {
  const ref = useRef<Record<string, T>>({});

  return useCallback(
    (uid: string, cb: T) => {
      if (!ref.current[uid]) {
        ref.current[uid] = debounce(cb, delay) as T;
      }
      return ref.current[uid];
    },
    [delay],
  );
};
