import { createSelector } from 'reselect';
import { Label } from 'services/labels';
import { AppState } from 'store';

export const selectLabelsState = (state: AppState) => state.labels;
export const selectLabels = createSelector(selectLabelsState, (state) => {
  return {
    isLoading: state.isLoading,
    labels: state.labels,
  };
});
export const selectMapLabels = createSelector(selectLabels, ({ labels }) => {
  const map: Record<string, Label> = {};
  return labels.reduce((acc, item) => {
    acc[item.labelKey] = item;
    return acc;
  }, map);
});

export const selectLanguageID = createSelector(selectLabelsState, ({ defaultLanguageID }) => {
  return defaultLanguageID;
});
