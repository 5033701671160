import { Tag } from '../parameters';

const API_ENDPOINT = 'Articles';

export const API_ARTICLES = {
  API_ENDPOINT,
  GET_ARTICLE: (articleID: string) => `${API_ENDPOINT}/GetArticle/${articleID}`,
  GET_ARTICLES_DYNAMIC: `${API_ENDPOINT}/GetArticlesDynamic`,
  GET_ARTICLE_COMMENTS_DYNAMIC: `${API_ENDPOINT}/GetArticleCommentsDynamic`,
  GET_ARTICLE_USER_COMMENTS_DYNAMIC: `${API_ENDPOINT}/GetArticleUserCommentsDynamic`,
  GET_ARTICLE_LIKES_DYNAMIC: `${API_ENDPOINT}/GetArticleUserLikesDynamic`,
  GET_ARTICLE_CATEGORIES_DYNAMIC: `${API_ENDPOINT}/GetArticleCategoriesDynamic`,
  GET_ARTICLE_HMO: `${API_ENDPOINT}/GetArticleAnalyticHMOs`,
  GET_ARTICLE_MEDICAL_FIELDS: `${API_ENDPOINT}/GetArticleAnalyticMedicalFields`,
  GET_ARTICLE_ACTIVITIES: `${API_ENDPOINT}/GetArticleAnalyticActivities`,
  GET_ARTICLE_HOSPITALS: `${API_ENDPOINT}/GetArticleAnalyticHospitals`,
  GET_ARTICLE_ANALYTICS_DYNAMIC: `${API_ENDPOINT}/GetArticleCategoriesAnalyticDynamic`,
  GET_ARTICLE_ANALYTIC_USERS_DYNAMIC: `${API_ENDPOINT}/GetArticleAnalyticUsersDynamic`,
};

export interface ArticleCategory {
  id: string;
  title: string;
  icon: string;
  rank: string;
}

export interface ArticleTotalParams {
  fromDate: string | Date;
  toDate: string | Date;
}

export interface ArticleTotal {
  source: string;
  total: number;
  uniqueTotal: number;
}

export class ArticlePicture {
  id!: string;
  articleID = '';
  picture = '';
  linkURL = '';
  rank = 0;
}

export class ArticleTag {
  id!: string;
  articleID!: Article['id'];
  tagID!: Tag['id'];
}

export class ArticlePatch {
  id!: string;
  isActive = false;
  displayForAll = false;
  html: string = '';
  video: string = '';
  articleCategoryID = '';
  publishDate = '';
  tags: [] = [];
}

export interface Article extends ArticlePatch {
  articleCategoryTitle: string;
  articleCategoryIcon: string;
  updatedDate: string;
  totalExposures: number;
  totalLikes: number;
  totalComments: number;
  totalViews: number;
  totalPlays: number;
  pictures?: ArticlePicture[];
  pharmaCompanyUserProfileFullName?: string;
  pharmaCompanyID?: string;
  pharmaCompanyDepartmentID: string;
  title: string;
}
export interface ArticleBase {
  id: string;
  articleCategoryID: string;
  publishDate: string;
  title: string;
  html: string;
  video: string;
  // totalExposures: number;
}

export interface KPIArticleStat {
  appIdentityUserID: string;
  titleOfName: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  primaryMedicalFieldTitle: string;
}

export interface ArticleCommentReplies {
  appIdentityUserID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  primaryMedicalFieldID: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldID: string;
  fullName: string;
  color: string;
  commentReplyID: string;
  publishDate: string;
  comment: string;
}

export interface ArticleComments {
  appIdentityUserID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  primaryMedicalFieldID: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldID: string;
  fullName: string;
  color: string;
  commentID: string;
  publishDate: string;
  comment: string;
  replies: Array<ArticleCommentReplies>;
}

export interface ArticleReactions {
  appIdentityUserID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  primaryMedicalFieldID: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldID: string;
  fullName: string;
  color: string;
  publishDate: string;
}

export interface ArticleViews {
  appIdentityUserID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  primaryMedicalFieldID: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldID: string;
  fullName: string;
  color: string;
  viewDate: string;
}

export interface ArticleLeads {
  source: string;
  articleID: string;
  articleViewID: string;
  articleTitle: string;
  articleReactionID: string;
  articleCommentID: string;
  date: string;
  comment: string;
  replies: string;
  appIdentityUserID: string;
  titleOfNameID: string;
  titleOfNameTitle: string;
  firstName: string;
  lastName: string;
  userPhoto: string;
  position: string;
  primaryMedicalFieldID: string;
  primaryMedicalFieldTitle: string;
  secondaryMedicalFieldID: string;
  fullName: string;
  color: string;
}

export interface AnalyticsHMO {
  hmoID: string;
  hmoTitle: string;
  totalExposures: number;
}

export interface AnalyticsMedicalFields {
  medicalFieldID: string;
  medicalFieldTitle: string;
  totalExposures: number;
  displayDoctors: boolean;
}

export interface AnalyticsHospitals {
  hospitalID: string;
  hospitalTitle: string;
  totalExposures: number;
}

export interface AnalyticsActivities {
  date: string;
  totalExposures: number;
}

export interface AnalyticsCategoriesTotal {
  id: string;
  title: string;
  icon: string;
  totalArticles: number;
  rank: number;
}

export interface AnalyticsCategories {
  id: string;
  rank: number;
  icon: string;
  title: string;
  totalArticles: number;
}
