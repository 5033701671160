import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AnalyticsActivities,
  AnalyticsHMO,
  AnalyticsHospitals,
  AnalyticsMedicalFields,
} from 'services/articles';
import { Expert } from 'services/experts';
import {
  actionArticleActivitiesGet,
  actionArticleHmosGet,
  actionArticleHospitalsGet,
  actionArticleMedicalFieldsGet,
  actionArticleUsersGet,
} from 'store/article/actions';

type UsersFiltersShared = { search: string };

type UsersFilterHmo = {
  type: ARTICLE_USERS_CATEGORY.HMO;
  ids: string[];
};

type UsersFilterHospital = {
  type: ARTICLE_USERS_CATEGORY.HOSPITALS;
  ids: string[];
};

type UsersFilterMedicalField = {
  type: ARTICLE_USERS_CATEGORY.MEDICAL_FIELDS;
  ids: string[];
};
type UsersFilterActivity = {
  type: ARTICLE_USERS_CATEGORY.ACTIVITY;
  fromDate: string;
  toDate: string;
};
type UsersFilterActivityTotal = {
  type: ARTICLE_USERS_CATEGORY.ACTIVITY_TOTAL;
  fromDate: string;
  toDate: string;
};

type UsersFiltersType =
  | UsersFilterHmo
  | UsersFilterMedicalField
  | UsersFilterHospital
  | UsersFilterActivityTotal
  | UsersFilterActivity;

export type UsersFilters = UsersFiltersType & UsersFiltersShared;

interface Pagination {
  take: number;
  page: number;
  count: number;
}

export enum ARTICLE_USERS_CATEGORY {
  ACTIVITY = 'activity',
  ACTIVITY_TOTAL = 'activity-total',
  HMO = 'hmo',
  HOSPITALS = 'hospitals',
  MEDICAL_FIELDS = 'medical-fields',
}

export const articleItemInitState = (articleID: string): StateItem => {
  const today = new Date().toISOString();
  return {
    articleID,
    period: [],
    hmo: { isLoading: false, isInit: false, error: null, data: [] },
    medicalFields: { isLoading: false, isInit: false, error: null, data: [] },
    hospitals: { isLoading: false, isInit: false, error: null, data: [] },
    activities: { isLoading: false, isInit: false, error: null, data: [] },
    users: {
      isLoading: false,
      isInit: false,
      error: null,
      data: [],
      pagination: { take: 10, page: 1, count: 0 },
      filters: {
        type: ARTICLE_USERS_CATEGORY.ACTIVITY,
        fromDate: today,
        toDate: today,
        search: '',
      },
    },
  };
};

interface StateItem {
  articleID: string;
  period: string[];
  hmo: { isLoading: boolean; isInit: boolean; error: null | Error; data: AnalyticsHMO[] };
  medicalFields: {
    isLoading: boolean;
    isInit: boolean;
    error: null | Error;
    data: AnalyticsMedicalFields[];
  };
  activities: {
    isLoading: boolean;
    isInit: boolean;
    error: null | Error;
    data: AnalyticsActivities[];
  };
  hospitals: {
    isLoading: boolean;
    isInit: boolean;
    error: null | Error;
    data: AnalyticsHospitals[];
  };
  users: {
    isLoading: boolean;
    isInit: boolean;
    error: null | Error;
    data: Expert[];
    pagination: Pagination;
    filters: UsersFilters;
  };
}

type State = { [articleID: string]: StateItem };

const initState = (): State => {
  return {};
};

const slice = createSlice({
  name: 'ARTICLE',
  initialState: initState(),
  reducers: {
    actionArticleUsersFiltersSet(
      state,
      action: PayloadAction<{
        articleID: string;
        filters: UsersFiltersType | UsersFiltersShared | (UsersFiltersType & UsersFiltersShared);
      }>,
    ) {
      const { articleID, filters } = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.users.filters = { ...item.users.filters, ...filters };
      state[articleID] = item;
    },
    actionArticlePeriodSet(
      state,
      action: PayloadAction<{
        articleID: string;
        period: string[];
      }>,
    ) {
      const { articleID, period } = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.period = period;
      state[articleID] = item;
    },
    actionArticleUsersPaginationSet(
      state,
      action: PayloadAction<{ articleID: string; pagination: Partial<Pagination> }>,
    ) {
      const { articleID, pagination } = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.users.pagination = { ...item.users.pagination, ...pagination };
      state[articleID] = item;
    },
  },
  extraReducers: (build) => {
    build.addCase(actionArticleHmosGet.pending, (state, action) => {
      const { articleID } = action.meta.arg;
      const item = state[articleID] || articleItemInitState(articleID);
      item.hmo.isLoading = true;
      state[articleID] = item;
    });
    build.addCase(actionArticleHmosGet.fulfilled, (state, action) => {
      const { articleID } = action.meta.arg;
      const data = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.hmo.isLoading = false;
      item.hmo.isInit = true;
      item.hmo.data = data.sort((a, b) => b.totalExposures - a.totalExposures);
      state[articleID] = item;
    });
    build.addCase(actionArticleHmosGet.rejected, (state, action) => {
      const { articleID } = action.meta.arg;
      const error = action.error;
      const item = state[articleID] || articleItemInitState(articleID);
      item.hmo.isLoading = false;
      item.hmo.isInit = true;
      item.hmo.error = error;
      state[articleID] = item;
    });

    build.addCase(actionArticleMedicalFieldsGet.pending, (state, action) => {
      const { articleID } = action.meta.arg;
      const item = state[articleID] || articleItemInitState(articleID);
      item.medicalFields.isLoading = true;
      state[articleID] = item;
    });
    build.addCase(actionArticleMedicalFieldsGet.fulfilled, (state, action) => {
      const { articleID } = action.meta.arg;
      const data = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.medicalFields.isLoading = false;
      item.medicalFields.isInit = true;
      item.medicalFields.data = data.sort((a, b) => b.totalExposures - a.totalExposures);
      state[articleID] = item;
    });
    build.addCase(actionArticleMedicalFieldsGet.rejected, (state, action) => {
      const { articleID } = action.meta.arg;
      const error = action.error;
      const item = state[articleID] || articleItemInitState(articleID);
      item.medicalFields.isLoading = false;
      item.medicalFields.isInit = true;
      item.medicalFields.error = error;
      state[articleID] = item;
    });

    build.addCase(actionArticleActivitiesGet.pending, (state, action) => {
      const { articleID } = action.meta.arg;
      const item = state[articleID] || articleItemInitState(articleID);
      item.activities.isLoading = true;
      state[articleID] = item;
    });
    build.addCase(actionArticleActivitiesGet.fulfilled, (state, action) => {
      const { articleID } = action.meta.arg;
      const data = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.activities.isLoading = false;
      item.activities.isInit = true;
      item.activities.data = data;
      state[articleID] = item;
    });
    build.addCase(actionArticleActivitiesGet.rejected, (state, action) => {
      const { articleID } = action.meta.arg;
      const error = action.error;
      const item = state[articleID] || articleItemInitState(articleID);
      item.activities.isLoading = false;
      item.activities.isInit = true;
      item.activities.error = error;
      state[articleID] = item;
    });

    build.addCase(actionArticleHospitalsGet.pending, (state, action) => {
      const { articleID } = action.meta.arg;
      const item = state[articleID] || articleItemInitState(articleID);
      item.hospitals.isLoading = true;
      state[articleID] = item;
    });
    build.addCase(actionArticleHospitalsGet.fulfilled, (state, action) => {
      const { articleID } = action.meta.arg;
      const data = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.hospitals.isLoading = false;
      item.hospitals.isInit = true;
      item.hospitals.data = data;
      state[articleID] = item;
    });
    build.addCase(actionArticleHospitalsGet.rejected, (state, action) => {
      const { articleID } = action.meta.arg;
      const error = action.error;
      const item = state[articleID] || articleItemInitState(articleID);
      item.hospitals.isLoading = false;
      item.hospitals.isInit = true;
      item.hospitals.error = error;
      state[articleID] = item;
    });

    build.addCase(actionArticleUsersGet.pending, (state, action) => {
      const { articleID } = action.meta.arg;
      const item = state[articleID] || articleItemInitState(articleID);
      item.users.isLoading = true;
      state[articleID] = item;
    });
    build.addCase(actionArticleUsersGet.fulfilled, (state, action) => {
      const { articleID } = action.meta.arg;
      const { data, count } = action.payload;
      const item = state[articleID] || articleItemInitState(articleID);
      item.users.isLoading = false;
      item.users.isInit = true;
      item.users.data = data;
      item.users.pagination.count = count;
      state[articleID] = item;
    });
    build.addCase(actionArticleUsersGet.rejected, (state, action) => {
      const { articleID } = action.meta.arg;
      const error = action.error;
      const item = state[articleID] || articleItemInitState(articleID);
      item.users.isLoading = false;
      item.users.isInit = true;
      item.users.error = error;
      state[articleID] = item;
    });
  },
});

const actions = slice.actions;
export const {
  actionArticleUsersPaginationSet,
  actionArticleUsersFiltersSet,
  actionArticlePeriodSet,
} = actions;
export const reducerArticle = slice.reducer;
