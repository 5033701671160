import { format, isValid } from 'date-fns';
export const prepareDateToSave = <M extends { [x: string]: any | Date }>(data: M) => {
  let _data = { ...data };
  Object.keys(data).forEach((key: keyof M) => {
    // @ts-ignore
    _data[key] =
      // @ts-ignore
      data[key] && data[key] instanceof Date
        ? // @ts-ignore
          isValid(data[key])
          ? format(data[key], "yyyy-MM-dd'T'HH:mm:ss")
          : data[key]
        : data[key];
  });

  return _data;
};
