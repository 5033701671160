import React, { useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { prepareDateToSave } from 'utils';
import { api } from 'utils/service';

const instances = [api, axios];
const patchDotNet5 = (conf: AxiosRequestConfig) => {
  if (conf.method === 'patch' && conf.data && typeof conf.data === 'object') {
    conf.data = Object.entries(prepareDateToSave(conf.data))
      .filter(([, value]) => value !== undefined)
      .map(([path, value]) => {
        return { path, value: value === 'done' ? '' : value };
      });
  }
  return conf;
};

export const AxiosInterceptorPatch: React.FC = ({ children }) => {
  useEffect(() => {
    instances.map((instance) => {
      return instance.interceptors.request.use(patchDotNet5);
    });
  }, []);

  return <>{children}</>;
};
