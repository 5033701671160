import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSixMonthBeforeTodayDate, getTodayDate } from 'utils';
import { actionAccountCompanySet } from 'store/account';

interface Filters {
  period: string[];
  pharmaCompanyUserID: string | null;
}

interface State {
  filters: Filters;
}

const initState = (): State => {
  return {
    filters: {
      period: [getSixMonthBeforeTodayDate().toISOString(), getTodayDate().toISOString()],
      pharmaCompanyUserID: null,
    },
  };
};

const slice = createSlice({
  name: 'DASHBOARD_INTERACTIONS',
  initialState: initState(),
  reducers: {
    actionDashboardInteractionsSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAccountCompanySet, (state) => {
      state.filters.pharmaCompanyUserID = null;
    });
  },
});
const actions = slice.actions;
export const { actionDashboardInteractionsSetFilters } = actions;
export const reducerDashboardInteractions = slice.reducer;
