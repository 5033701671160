import { useIsMountedRef } from 'hooks/use-mounted-ref';
import { useCallback, useMemo, useState } from 'react';
import { PromiseType } from 'utils';

enum STATUS {
  IS_LOADING,
  DEFAULT,
}

type Request = (...args: any[]) => Promise<{ data: any }>;
type Options<T> = { parser: (prev: T | undefined, next: T) => T };

const defaultParser = <T>(prev: T | undefined, next: T) => {
  return next;
};

export const useFetchDynamic = <T extends Request>(
  request: T,
  options?: Options<PromiseType<ReturnType<T>>['data']>,
) => {
  const [status, setStatus] = useState(STATUS.DEFAULT);
  const [error, setError] = useState<Error | null>(null);
  const { parser = defaultParser } = options || {};

  const [data, setData] = useState<PromiseType<ReturnType<T>>['data']>();

  const mountedRef = useIsMountedRef();
  const trigger = useCallback(
    async (...args: Parameters<T>) => {
      setError(null);
      setStatus(STATUS.IS_LOADING);
      try {
        const result = await request(...args);

        setData((prev) => parser(prev, result.data));
      } catch (e) {
        if (!mountedRef.current) return;
        setError(e);
      } finally {
        if (!mountedRef.current) return;
        setStatus(STATUS.DEFAULT);
      }
    },
    [mountedRef, request, parser],
  );

  const value = useMemo(() => {
    return {
      data,
      setData,
      error,
      isLoading: status === STATUS.IS_LOADING,
    };
  }, [data, error, status]);
  return [trigger, value] as const;
};
