import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

interface Props {
  divider?: React.ReactNode;
  className?: string;
  onClose?: () => void;
}

export const AppDialogTitle: React.FC<Props> = ({ className, divider, children, onClose }) => {
  return (
    <div className={clsx(style.root, className)}>
      {children}
      {divider}
      <IconButton className={style.close} size={'small'} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};
