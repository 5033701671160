const API = 'Accounts';

export const API_ACCOUNTS = {
  API: API,
  PATCH: `${API}/PatchCurrentUser`,
  PATCH_COMPANY: (pharmaCompanyUserID: string) =>
    `${API}/PatchCurrentUserCompany/${pharmaCompanyUserID}`,
  GET_CURRENT_USER: `${API}/GetCurrentAppUser`,
  GET_PHARMA_COMPANIES: `${API}/GetPharmaCompaniesDynamic`,
  LOGIN_OR_REGISTER_VISITOR: `${API}/LoginOrRegisterVisitor`,
  LOGOUT: `${API}/Logout`,
  LOGIN_WITH_CODE: `${API}/LoginWithCode`,
  GENERATE_CODE: `${API}/GeneratePassword`,
  VERIFY_RECAPTCHA: `${API}/VerifyReCaptcha`,
  REFRESH_TOKEN: `${API}/RefreshToken`,
};

export interface UserCompany {
  pharmaCompanyUserID: string;
  pharmaCompanyID: string;
  companyName: string;
  description: string;
  logo: string;
  position: string;
  maxCredits: number;
  usedCredits: number;
  pharmaCompanyDepartmentID: string;
  isAdmin: boolean;
  isGuest: boolean;
}

export class UserCompanyPatch implements UserCompanyPatch {
  pharmaCompanyUserID = '';
  pharmaCompanyDepartmentID = '';
}

export interface AppUser {
  appUserID: string;
  languageID: string;
  fullName: string;
  firstName: string;
  lastName: string;
  userPhoto: string | null;
  position: string;
  email: string;
  mobilePhone: string;
  color: string;
  companies: UserCompany[];
}

export class AppUserPatch
  implements Pick<AppUser, 'lastName' | 'firstName' | 'userPhoto' | 'mobilePhone'>
{
  lastName = '';
  firstName = '';
  userPhoto: null | string = '';
  mobilePhone = '';
}

export class VisitorNew {
  firstName = '';
  lastName = '';
  pharmaCompanyID = '';
  position = '';
  mobilePhone = '';
  email = '';
  code = '';
}

export interface JWT {
  jwt: {
    token: string;
    refreshToken: string;
  };
  expires: string;
}
