import { format, parseISO } from 'date-fns';

export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};

export const convertDateToRDateTime = (date: Date | undefined | null) => {
  if (date) {
    return format(date, 'yyyy,MM,dd,HH,mm,ss');
  } else {
    return date;
  }
};

export const convertRDateTimeToDate = (date: string | undefined | null) => {
  if (date) {
    return parseISO(date);
  } else {
    return date;
  }
};

export const getRandomString = (length = 10, chars = 'aA') => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  let result = '';
  for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export const getUniqueListBy = (arr: Array<any>, key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const checkES6Template = (value: any) => {
  return typeof value === 'string' && value.indexOf('${') !== -1;
};

export const checkEs6AndRun = (val: any, data: any = null) => {
  if (checkES6Template(val)) {
    // eslint-disable-next-line
    let func = new Function('data', `return \`${val}\``);
    return func(data);
  } else if (typeof val === 'function') {
    return val(data);
  } else {
    return val;
  }
};

export const es6Run = (data: any, field: string) => {
  return data[field] !== undefined ? data[field] : checkEs6AndRun(field, data);
};

export const checkActive = (pathname: string, path: string): boolean => {
  // eslint-disable-next-line
  return path === pathname || (path.length > 1 && new RegExp(`${path}(\/|$)`, 'g').test(pathname));
};

const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{.+?}}/gi, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] === undefined ? '-- --' : payload[key];
  });
};

export const fieldToLabelKey = <T extends Record<string, any> = any>(field: keyof T) => {
  return String(field)
    .replace(/ID/g, '')
    .replace(/[A-Z]/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};

const defaultDecorator = () => {};
export const decorateAfter = <T extends (...args: any[]) => any>(
  cb: T,
  decorator: (...args: any[]) => any = defaultDecorator,
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    decorator && decorator(...args);
    return result;
  };
};
