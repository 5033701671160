import { AppScrollbarsProps } from 'components/app-scrollbar';
import { useCallback, useRef } from 'react';

interface Options {
  onToBottom?: () => void;
  onBottom?: () => void;
}
export const useAppScrollHooks = (options: Options) => {
  const refPos = useRef({ top: 0, left: 0 });
  const { onToBottom, onBottom } = options;
  const onScrollFrame = useCallback<Required<AppScrollbarsProps>['onScrollFrame']>(
    (positionValue) => {
      if (positionValue.top > refPos.current.top) {
        onToBottom && onToBottom();
      }
      if (positionValue.top >= 1) {
        onBottom && onBottom();
      }
      refPos.current = { top: positionValue.top, left: positionValue.left };
    },
    [onToBottom, onBottom],
  );

  return { onScrollFrame };
};
