const API = 'Parameters';

export const API_PARAMETERS = {
  MEDICAL_FIELDS: `${API}/GetMedicalFieldsDynamic`,
  HOSPITALS: `${API}/GetHospitalsDynamic`,
  TAGS: `${API}/GetTagsDynamic`,
  DEPARTMENTS: `${API}/GetDepartmentsDynamic`,
  DOCUMENT_TYPES: `${API}/GetDocumentTypesDynamic`,
  HMOS: `${API}/GetHmosDynamic`,
};

export interface BaseParameter {
  id: string;
  title: string;
}

export class Tag {
  id!: string;
  title: string = '';
}
