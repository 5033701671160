import { useCallback, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { parseErrorData } from 'utils/service';

interface Options {
  isTest: boolean;
  debug: boolean;
}

export const useRecaptcha = (
  verify?: (token: string) => Promise<boolean>,
  options?: Partial<Options>,
) => {
  const ref = useRef<ReCAPTCHA>(null);
  const [error, setError] = useState<Error | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { isTest = false, debug = false } = options || {};
  const onExecute = useCallback(async () => {
    if (isTest || (process.env.NODE_ENV === 'development' && debug)) {
      return true;
    }
    setError(null);

    ref.current?.reset();
    setIsLoading(true);
    const promise = ref?.current?.executeAsync() || Promise.reject();
    return promise
      .then((token: string | null) => {
        if (token === null) {
          throw new Error('token is null');
        }
        return verify ? verify(token) : true;
      })
      .catch((e) => {
        setError(parseErrorData(e));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [verify, isTest, debug]);

  return useMemo(() => {
    return {
      ref,
      error,
      isLoading,
      onExecute,
    };
  }, [onExecute, error, isLoading]);
};
