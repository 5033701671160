const _API = 'Labels';

export const API_LABELS = {
  GET_ALL_DYNAMIC: `${_API}/GetLabelsDynamic`,
  GET_DEFAULT_LANGUAGE: `${_API}/GetDefaultLanguage`,
};

export class Label {
  id!: number;
  title: string = '';
  labelKey: string = '';
  languageID!: string;
  isActive: boolean = true;
}
