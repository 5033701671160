import { Label } from 'services/labels';
import { asyncActionCreator, ExtractAsyncAction } from 'utils/store';

export enum LABELS_ACTIONS {
  FLOW_REQUEST = 'FLOW_REQUEST',
  FLOW_SUCCESS = 'FLOW_SUCCESS',
  FLOW_FAIL = 'FLOW_FAIL',
}

export const labelsActions = asyncActionCreator(
  LABELS_ACTIONS.FLOW_REQUEST,
  LABELS_ACTIONS.FLOW_SUCCESS,
  LABELS_ACTIONS.FLOW_FAIL,
)<void, { labels: Label[]; languageID: string }, Error>();

export type LabelsActions = ExtractAsyncAction<typeof labelsActions>;
