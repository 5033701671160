import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/pagination';
import { AppState } from '../index';

const selectState = (state: AppState) => state.myDoctors;

export const selectMyDoctorsState = createSelector(selectState, (state) => state);
export const selectMyDoctorsFilters = createSelector(
  selectMyDoctorsState,
  ({ filters }) => filters,
);
export const selectStateMyDoctorsPagination = createSelector(
  selectState,
  ({ take, page, count }) => {
    return calcPaginationState({ take, page, count });
  },
);
