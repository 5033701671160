import { set } from 'date-fns';
import { createFilter, mergeFilters, PatchModel } from 'utils';
import {
  api,
  apiRtk,
  API_RTK_TAGS,
  BaseParams,
  parseErrorData,
  transformResponseDynamic,
} from 'utils/service';
import {
  API_MESSAGES,
  Document,
  Message,
  MessageLog,
  MessagePatch,
  MessagesForDoctors,
  OpenDocumentProps,
} from './models';

export * from './models';

class Service {
  async post(
    data: Pick<
      MessagePatch,
      'message' | 'subject' | 'pharmaCompanyUserID' | 'userExpertProfileID' | 'documentIDs'
    >,
  ) {
    const { documentIDs, ...rest } = data;
    const { data: createdData } = await api.post<Message>(API_MESSAGES.CREATE_MESSAGE, {
      ...rest,
      messageDate: set(new Date(), { milliseconds: 0 }).toISOString(),
    });

    return ServiceMessages.patch({ documentIDs, messageID: createdData.messageID });
  }

  async patch(data: PatchModel<MessagePatch, 'messageID'>) {
    const { documentIDs, ...rest } = data;
    const { messageID } = data;

    if (documentIDs) {
      await ServiceMessages.attachDocumentsBulk({ messageID, documentIDs });
    }

    const result = await api.patch(API_MESSAGES.PATCH({ id: data.messageID }), {
      ...rest,
      messageID: undefined,
    });

    return { ...result, data: data };
  }

  async getAllMessagesDynamic(params: BaseParams) {
    return await api.get<{ value: Message[]; count: number }>(API_MESSAGES.GET_MESSAGES_DYNAMIC, {
      params,
    });
  }

  async attachDocumentsBulk(data: { messageID: string; documentIDs: string[] }) {
    return api.post(API_MESSAGES.ATTACH_DOCUMENTS_BULK, data);
  }

  async getMessageByID(messageID: string) {
    return await api.get(API_MESSAGES.GET_MESSAGE_BY_ID(messageID));
  }

  async getMessageByToken(token: string) {
    return await api.get<Message>(API_MESSAGES.GET_MESSAGE_BY_TOKEN(token));
  }

  async sendMessage(messageID: string, userID: string) {
    return await api.get(API_MESSAGES.SEND_MESSAGE(messageID, userID));
  }

  async replyToMessage(token: Message['token'], reply: Message['reply']) {
    return await api.put(API_MESSAGES.REPLY(token), {
      reply,
    });
  }

  async openDocument(params: OpenDocumentProps) {
    return await api.post(API_MESSAGES.OPEN_DOCUMENT, {
      ...params,
    });
  }

  async getMessageLog(params: Partial<MessageLog>) {
    return await api.get<{ value: MessageLog[] }>(API_MESSAGES.GET_MESSAGE_LOG_DYNAMIC, {
      params: {
        ...params,
        orderBy: 'eventTime',
        select: ['eventTime', 'eventTitle', 'messageReplied'].join(','),
      },
    });
  }

  async getMessagesForDoctors(params: any) {
    return await api.get<{ value: MessagesForDoctors[]; count: number }>(
      API_MESSAGES.GET_MESSAGES_FOR_DOC_DYNAMIC,
      {
        params: {
          ...params,
        },
      },
    );
  }
}

export const ServiceMessages = new Service();

export const apiMessages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTeamMessagesWithUser: build.query<
      Message[],
      { userExpertProfileID: string; pharmaCompanyID: string; pharmaCompanyUserID: string }
    >({
      query: ({ userExpertProfileID, pharmaCompanyID, pharmaCompanyUserID }) => ({
        url: API_MESSAGES.GET_MESSAGES_DYNAMIC,
        params: {
          pharmaCompanyID,
          filter: mergeFilters(
            [
              createFilter<Message>('userExpertProfileID', '==', userExpertProfileID),
              createFilter<Message>('pharmaCompanyUserID', '!=', pharmaCompanyUserID),
              'isDraft==true',
            ],
            '&&',
          ),
          orderBy: 'messageDate desc',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: (result, error, { userExpertProfileID }) => [
        { type: API_RTK_TAGS.MESSAGE_LIST_WITH_USER, id: userExpertProfileID },
      ],
    }),
    getMessagesWithUser: build.query<
      Message[],
      { userExpertProfileID: string; pharmaCompanyID: string; pharmaCompanyUserID: string }
    >({
      query: ({ userExpertProfileID, pharmaCompanyID, pharmaCompanyUserID }) => ({
        url: API_MESSAGES.GET_MESSAGES_DYNAMIC,
        params: {
          pharmaCompanyID,
          filter: mergeFilters(
            [
              createFilter<Message>('userExpertProfileID', '==', userExpertProfileID),
              createFilter<Message>('pharmaCompanyUserID', '==', pharmaCompanyUserID),
              'isDraft!=true',
            ],
            '&&',
          ),
          orderBy: 'messageDate desc',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: (result, error, { userExpertProfileID }) => [
        { type: API_RTK_TAGS.MESSAGE_LIST_WITH_USER, id: userExpertProfileID },
      ],
    }),
    getMessage: build.query<Message, { messageID: string }>({
      query: ({ messageID }) => ({
        url: API_MESSAGES.GET_MESSAGE_BY_ID(messageID),
      }),
      providesTags: (result, error, { messageID }) => [
        { type: API_RTK_TAGS.MESSAGE, id: messageID },
      ],
    }),
    getMessageDocuments: build.query<Document[], { messageID: string }>({
      query: ({ messageID }) => ({
        url: API_MESSAGES.GET_MESSAGE_DOCS_DYNAMIC,
        params: {
          messageID,
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: (result, error, { messageID }) => [
        { type: API_RTK_TAGS.MESSAGE_DOCUMENTS, id: messageID },
      ],
    }),
    addMessage: build.mutation<
      PatchModel<MessagePatch, 'messageID'>,
      Omit<MessagePatch, 'messageID'>
    >({
      queryFn: async (data) => {
        try {
          const { data: message } = await ServiceMessages.post(data);
          return { data: message };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { userExpertProfileID }) => [
        {
          type: API_RTK_TAGS.MESSAGE_LIST_WITH_USER,
          id: userExpertProfileID,
        },
      ],
    }),
    updateMessage: build.mutation<
      PatchModel<MessagePatch, 'messageID'>,
      PatchModel<MessagePatch, 'messageID'>
    >({
      queryFn: async (data) => {
        try {
          const { data: message } = await ServiceMessages.patch(data);
          return { data: message };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { messageID, userExpertProfileID }) => [
        { type: API_RTK_TAGS.MESSAGE, id: messageID },
        { type: API_RTK_TAGS.MESSAGE_DOCUMENTS, id: messageID },
        {
          type: API_RTK_TAGS.MESSAGE_LIST_WITH_USER,
          id: userExpertProfileID,
        },
      ],
    }),
    sendMessage: build.mutation<
      void,
      Pick<MessagePatch, 'messageID' | 'pharmaCompanyUserID' | 'userExpertProfileID'>
    >({
      queryFn: async ({ messageID, pharmaCompanyUserID }) => {
        try {
          await ServiceMessages.sendMessage(messageID, pharmaCompanyUserID);
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { messageID, userExpertProfileID }) => [
        { type: API_RTK_TAGS.MESSAGE, id: messageID },
        {
          type: API_RTK_TAGS.MESSAGE_LIST_WITH_USER,
          id: userExpertProfileID,
        },
      ],
    }),
    removeMessage: build.mutation<
      void,
      PatchModel<MessagePatch, 'messageID' | 'userExpertProfileID'>
    >({
      queryFn: async ({ messageID }) => {
        try {
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { messageID, userExpertProfileID }) => [
        { type: API_RTK_TAGS.MESSAGE, id: messageID },
        {
          type: API_RTK_TAGS.MESSAGE_LIST_WITH_USER,
          id: userExpertProfileID,
        },
      ],
    }),
  }),
});
