import { PatchModel } from 'utils';
import { api, apiRtk, BaseParams, DynamicResult, transformResponseDynamic } from 'utils/service';
import { Expert } from '../experts';
import {
  AnalyticsActivities,
  AnalyticsCategories,
  AnalyticsCategoriesTotal,
  AnalyticsHMO,
  AnalyticsHospitals,
  AnalyticsMedicalFields,
  API_ARTICLES,
  Article,
  ArticleBase,
  ArticleCategory,
  ArticleComments,
  KPIArticleStat,
} from './models';

export * from './models';

class Service {
  async getAllDynamic(params: BaseParams) {
    return await api.get<{ value: ArticleBase[]; count: number }>(
      API_ARTICLES.GET_ARTICLES_DYNAMIC,
      {
        params,
      },
    );
  }

  async getArticle(id: string) {
    return await api.get<Article>(API_ARTICLES.GET_ARTICLE(id));
  }

  async getCommentsDynamic(params: BaseParams) {
    return await api.get<{ value: ArticleComments[]; count: number }>(
      API_ARTICLES.GET_ARTICLE_COMMENTS_DYNAMIC,
      {
        params,
      },
    );
  }

  async getReactionsDynamic(params: BaseParams) {
    return await api.get<{ value: KPIArticleStat[]; count: number }>(
      API_ARTICLES.GET_ARTICLE_LIKES_DYNAMIC,
      {
        params,
      },
    );
  }

  async getArticleHMO(params: BaseParams) {
    return await api.get<AnalyticsHMO[]>(API_ARTICLES.GET_ARTICLE_HMO, {
      params,
    });
  }

  async getArticleMedicalFields(params: BaseParams) {
    return await api.get<AnalyticsMedicalFields[]>(API_ARTICLES.GET_ARTICLE_MEDICAL_FIELDS, {
      params,
    });
  }

  async getArticleActivities(params: BaseParams) {
    return await api.get<AnalyticsActivities[]>(API_ARTICLES.GET_ARTICLE_ACTIVITIES, {
      params,
    });
  }

  async getArticleHospitals(params: BaseParams) {
    return await api.get<AnalyticsHospitals[]>(API_ARTICLES.GET_ARTICLE_HOSPITALS, {
      params,
    });
  }

  async getLikesUsers(params: BaseParams) {
    return await api.get<{ value: Expert[]; count: number }>(
      API_ARTICLES.GET_ARTICLE_LIKES_DYNAMIC,
      {
        params,
      },
    );
  }

  async getTotalAnalytics(params: any) {
    return await api.get<{ value: AnalyticsCategories[] }>(
      API_ARTICLES.GET_ARTICLE_ANALYTICS_DYNAMIC,
      {
        params,
      },
    );
  }

  async getArticleAnalyticUsers<T extends PatchModel<BaseParams, 'articleID' | 'pharmaCompanyID'>>(
    params: T,
  ) {
    return api.get<DynamicResult<Expert, T>>(API_ARTICLES.GET_ARTICLE_ANALYTIC_USERS_DYNAMIC, {
      params,
    });
  }
}

export const ServiceArticles = new Service();

export const apiArticles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<ArticleCategory[], { includeVimed?: boolean }>({
      query: ({ includeVimed }) => ({
        url: API_ARTICLES.GET_ARTICLE_CATEGORIES_DYNAMIC,
        params: { orderBy: 'rank asc', includeVimed },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getArticle: build.query<Article, { articleID: string }>({
      query: ({ articleID }) => ({
        url: API_ARTICLES.GET_ARTICLE(articleID),
      }),
    }),
    getAnalyticsCategoriesTotal: build.query<
      AnalyticsCategoriesTotal[],
      { pharmaCompanyID: string; pharmaCompanyDepartmentID?: string }
    >({
      query: ({ pharmaCompanyID, pharmaCompanyDepartmentID = '' }) => ({
        url: API_ARTICLES.GET_ARTICLE_ANALYTICS_DYNAMIC,
        params: { pharmaCompanyID, pharmaCompanyDepartmentID, orderBy: 'rank asc' },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getArticleComments: build.query<
      ArticleComments[],
      { articleID: string; pharmaCompanyID: string }
    >({
      query: ({ articleID, pharmaCompanyID }) => ({
        url: API_ARTICLES.GET_ARTICLE_COMMENTS_DYNAMIC,
        params: { articleID, pharmaCompanyID },
      }),
      transformResponse: transformResponseDynamic,
    }),
  }),
});
