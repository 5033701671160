import React, { memo } from 'react';
import { useAppSelector } from 'hooks';
import { HeaderNav } from 'components/header';
import { Footer } from 'components/footer';
import { selectAccountUser } from 'store/account';
import style from './index.module.scss';

export const LayoutPrivate = memo(({ children }) => {
  const user = useAppSelector(selectAccountUser);

  return (
    <>
      {user && <HeaderNav />}
      <main className={style.wrap}>{children}</main>
      <Footer />
    </>
  );
});
