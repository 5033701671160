import React from 'react';
import { Dialog, DialogProps } from '@material-ui/core';

export * from './components';

export interface Props extends DialogProps {}

export const AppDialog: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Dialog fullWidth maxWidth={'md'} {...rest}>
      {children}
    </Dialog>
  );
};
