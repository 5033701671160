import React, { useEffect } from 'react';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import { themeOptions } from 'styles/theme';
import { createTheme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const jssRTL = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: 'jss-insertion-point',
});
export const themeRTL = createTheme(themeOptions, { direction: 'rtl' });

export const ThemeProvider: React.FC = ({ children }) => {
  useEffect(() => {
    const el = document.getElementsByTagName('html')[0];
    el?.setAttribute('dir', 'rtl');
  }, []);

  return (
    <StylesProvider jss={jssRTL}>
      <CssBaseline />
      <MaterialThemeProvider theme={themeRTL}>{children}</MaterialThemeProvider>
    </StylesProvider>
  );
};
