import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: AppState) => state.documents;
export const selectDocumentsState = createSelector(selectState, (state) => state);
export const selectDocumentsData = createSelector(selectDocumentsState, ({ data }) => data);
export const selectDocumentsStatuses = createSelector(
  selectDocumentsState,
  ({ isInit, isLoading }) => ({ isLoading, isInit }),
);
export const selectDocumentsFilters = createSelector(
  selectDocumentsState,
  ({ filters }) => filters,
);
