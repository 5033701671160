import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import style from './index.module.scss';

interface Props extends Omit<TypographyProps, 'ref'> {
  className?: string;
  html: string | undefined | null;
}

export const RawContent: React.FC<Props> = ({ className, html, ...rest }) => {
  return (
    <Typography
      className={clsx(style.root, className)}
      dangerouslySetInnerHTML={{ __html: html || '' }}
      {...rest}
    />
  );
};
