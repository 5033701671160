import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues, UseFormOptions } from 'react-hook-form/dist/types';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';

interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
  extends UseFormOptions<TFieldValues, TContext> {
  onUpdate?: (value: UnpackNestedValue<TFieldValues>) => void;
}
export const useFilterForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
>(
  props: Props<TFieldValues, TContext>,
) => {
  const result = useForm(props);

  const { defaultValues, onUpdate } = props;

  const { reset, getValues } = result;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onChangeItem = useCallback(() => {
    const prevValues = JSON.stringify(defaultValues);
    const nextValue = JSON.stringify({ ...defaultValues, ...getValues() });

    if (prevValues !== nextValue) {
      onUpdate && onUpdate(getValues());
    }
  }, [getValues, defaultValues, onUpdate]);

  return { ...result, onChangeItem };
};
