export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
export type PatchModel<T extends {}, ID extends keyof T> = Pick<T, ID> & Partial<Omit<T, ID>>;
export type ArrayModel<T> = T extends (infer U)[] ? U : never;

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  ...args: Partial<Model>[]
) => {
  const argsRevers = args.filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find(
      (item) => item.hasOwnProperty(key) && item[key] !== '' && item[key] !== null,
    );
    acc[key] = targetValue ? targetValue[key] : model[key];
    return acc;
  }, {}) as Model;
};

export const createObjectMap = <M extends any[]>(
  source: M,
  key: keyof ArrayModel<M>,
): { [x: string]: undefined | ArrayModel<M> } => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};

export type PromiseType<T> = T extends Promise<infer U> ? U : never;
