import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentProps } from 'services/documents';
import { actionAccountLogout } from 'store/account';
import { createCombineAction } from 'utils/store';

interface Filters {
  search: string;
  pharmaCompanyDocumentTypeID: string;
}

interface State {
  data: DocumentProps[];
  isLoading: boolean;
  error: null | Error;
  filters: Filters;
  isInit: boolean;
}

const initState = (): State => {
  return {
    data: [],
    isLoading: false,
    isInit: false,
    error: null,
    filters: {
      search: '',
      pharmaCompanyDocumentTypeID: '',
    },
  };
};

const slice = createSlice({
  name: 'DOCUMENTS',
  initialState: initState(),
  reducers: {
    getDocumentsRequest(state) {
      state.error = null;
      state.isLoading = true;
    },
    getDocumentsSuccess(
      state,
      action: PayloadAction<{
        value: DocumentProps[];
      }>,
    ) {
      const { value } = action.payload;
      state.isLoading = false;
      state.data = value;
    },
    getDocumentsFail(state, action: PayloadAction<{ error: Error }>) {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    actionDocumentsSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

const action = slice.actions;
export const { actionDocumentsSetFilters } = action;

export const actionDocumentsGet = createCombineAction(
  action.getDocumentsRequest,
  action.getDocumentsSuccess,
  action.getDocumentsFail,
);

export const reducerDocuments = slice.reducer;
