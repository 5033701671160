import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import {
  AnalyticsActivities,
  AnalyticsHMO,
  AnalyticsHospitals,
  AnalyticsMedicalFields,
  ServiceArticles,
} from 'services/articles';
import { Expert } from 'services/experts';
import { AppAsyncThunkConfig } from 'store';
import { selectAccountCompanyID } from 'store/account';
import { selectArticleStateByID } from 'store/article/selectors';
import { createFilter, createFilterSmartSearch, mergeFilters } from 'utils';
import { convertToDate, DateValue } from 'utils/dates';
import { parseErrorData } from 'utils/service';
import { ARTICLE_USERS_CATEGORY, UsersFilters } from './slice';

export const actionArticleHmosGet = createAsyncThunk<
  AnalyticsHMO[],
  { articleID: string; pharmaCompanyDepartmentID?: string },
  AppAsyncThunkConfig
>(`ARTICLE/hmosGet`, async ({ articleID, pharmaCompanyDepartmentID }, { getState }) => {
  try {
    const { period } = selectArticleStateByID(getState(), articleID);

    const fromDate = period[0] ? convertToDate(period[0]).toISOString() : undefined;
    const toDate = period[1] ? convertToDate(period[1]).toISOString() : undefined;

    const { data } = await ServiceArticles.getArticleHMO({
      articleID,
      fromDate,
      toDate,
      pharmaCompanyDepartmentID,
    });
    return data;
  } catch (e) {
    throw parseErrorData(e);
  }
});

export const actionArticleMedicalFieldsGet = createAsyncThunk<
  AnalyticsMedicalFields[],
  { articleID: string; pharmaCompanyDepartmentID?: string },
  AppAsyncThunkConfig
>(`ARTICLE/medicalFieldsGet`, async ({ articleID, pharmaCompanyDepartmentID }, { getState }) => {
  try {
    const { period } = selectArticleStateByID(getState(), articleID);
    const fromDate = period[0] ? convertToDate(period[0]).toISOString() : undefined;
    const toDate = period[1] ? convertToDate(period[1]).toISOString() : undefined;
    const { data } = await ServiceArticles.getArticleMedicalFields({
      articleID,
      fromDate,
      toDate,
      pharmaCompanyDepartmentID,
    });
    return data;
  } catch (e) {
    throw parseErrorData(e);
  }
});

export const actionArticleHospitalsGet = createAsyncThunk<
  AnalyticsHospitals[],
  { articleID: string; pharmaCompanyDepartmentID?: string },
  AppAsyncThunkConfig
>(`ARTICLE/hospitalsGet`, async ({ articleID, pharmaCompanyDepartmentID }, { getState }) => {
  try {
    const { period } = selectArticleStateByID(getState(), articleID);
    const fromDate = period[0] ? convertToDate(period[0]).toISOString() : undefined;
    const toDate = period[1] ? convertToDate(period[1]).toISOString() : undefined;
    const { data } = await ServiceArticles.getArticleHospitals({
      articleID,
      fromDate,
      toDate,
      pharmaCompanyDepartmentID,
    });
    return data;
  } catch (e) {
    throw parseErrorData(e);
  }
});

export const actionArticleActivitiesGet = createAsyncThunk<
  AnalyticsActivities[],
  { articleID: string; pharmaCompanyDepartmentID?: string },
  AppAsyncThunkConfig
>(`ARTICLE/activitiesGet`, async ({ articleID, pharmaCompanyDepartmentID }, { getState }) => {
  try {
    const { period } = selectArticleStateByID(getState(), articleID);
    const fromDate = period[0] ? convertToDate(period[0]).toISOString() : undefined;
    const toDate = period[1] ? convertToDate(period[1]).toISOString() : undefined;
    const { data } = await ServiceArticles.getArticleActivities({
      articleID,
      fromDate,
      toDate,
      pharmaCompanyDepartmentID,
    });
    return data;
  } catch (e) {
    throw parseErrorData(e);
  }
});

const getDateParam = (value: DateValue | undefined) => {
  return value ? convertToDate(value).toISOString() : undefined;
};
const getUsersParams = (userFilters: UsersFilters, period: string[]) => {
  const fromDate = getDateParam(period[0]);
  const toDate = getDateParam(period[1]);

  switch (userFilters.type) {
    case ARTICLE_USERS_CATEGORY.HMO:
      return {
        filter: createFilter<Expert>(
          'hmoID',
          userFilters.ids.length > 1 ? '!=' : '==',
          userFilters.ids,
          { multipleSeparator: '&&' },
        ),
        fromDate,
        toDate,
      };
    case ARTICLE_USERS_CATEGORY.HOSPITALS:
      return {
        filter: mergeFilters(
          [
            // createFilter<Expert>(
            //   'hospitalID',
            //   userFilters.ids.length > 1 ? '!=' : '==',
            //   userFilters.ids,
            //   { multipleSeparator: '&&' },
            // ),
            'hospitalID!=null',
          ],
          '&&',
        ),
        fromDate,
        toDate,
      };
    case ARTICLE_USERS_CATEGORY.MEDICAL_FIELDS:
      return {
        filter: createFilter<Expert>(
          'primaryMedicalFieldID',
          userFilters.ids.length > 1 ? '!=' : '==',
          userFilters.ids,
          { multipleSeparator: '&&' },
        ),
        fromDate,
        toDate,
      };
    case ARTICLE_USERS_CATEGORY.ACTIVITY_TOTAL:
    case ARTICLE_USERS_CATEGORY.ACTIVITY: {
      const userFromDate = format(convertToDate(getDateParam(userFilters.fromDate)), 'yyyy-MM-dd');
      const userToDate = format(convertToDate(getDateParam(userFilters.toDate)), 'yyyy-MM-dd');

      return {
        fromDate: userFromDate || fromDate,
        toDate: userToDate || toDate,
      };
    }
  }
};

export const actionArticleUsersGet = createAsyncThunk<
  { data: Expert[]; count: number },
  { articleID: string },
  AppAsyncThunkConfig
>(`ARTICLE/usersGet`, async ({ articleID }, { getState }) => {
  try {
    const {
      period,
      users: {
        filters: userFilters,
        pagination: { skip, take },
      },
    } = selectArticleStateByID(getState(), articleID);

    const pharmaCompanyID = selectAccountCompanyID(getState());

    if (!pharmaCompanyID) {
      throw new Error('error');
    }

    const params = getUsersParams(userFilters, period);

    const searchFilter = createFilterSmartSearch<Expert>(
      ['firstName', 'lastName'],
      userFilters.search,
    );

    const {
      data: { value, count },
    } = await ServiceArticles.getArticleAnalyticUsers({
      ...params,
      filter: mergeFilters([searchFilter, params.filter], '&&'),
      articleID,
      pharmaCompanyID,
      take,
      skip,
      count: true,
    });
    return { data: value, count };
  } catch (e) {
    throw parseErrorData(e);
  }
});
