import { API_MEDIA_UPLOADS, MediaUploadFile, MediaUploadHtml } from './models';
import { api } from 'utils/service';
import { getRandomString, PatchModel, setToModel } from 'utils';
import { base64ToFileStream } from 'utils/file-uploader';

export const MEDIA_PREFIX = '@@CLOUD@@';

export * from './models';

class Service {
  async remove(filePath: string) {
    return api.delete(API_MEDIA_UPLOADS.REMOVE_FILE({ filePath: filePath }));
  }

  async uploadFile(data: PatchModel<MediaUploadFile, 'fileStreamString'>) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_FILE, data);
  }

  async uploadHtml(data: PatchModel<MediaUploadHtml, 'htmlContent'>) {
    return api.post<MediaUploadHtml>(API_MEDIA_UPLOADS.UPLOAD_HTML, { ...data });
  }

  async uploadImage(data: PatchModel<MediaUploadFile, 'fileStreamString'>) {
    return ServiceMediaUploads.upload(API_MEDIA_UPLOADS.UPLOAD_IMAGE, data);
  }

  private async upload(url: string, data: PatchModel<MediaUploadFile, 'fileStreamString'>) {
    const fileName = `${MEDIA_PREFIX}__${data.fileName ? data.fileName : getRandomString(20)}`;
    const fileStreamString = base64ToFileStream(data.fileStreamString);
    return api.post<MediaUploadFile>(
      url,
      setToModel(new MediaUploadFile(), { ...data, fileName, fileStreamString }),
    );
  }
}

export const ServiceMediaUploads = new Service();
