import { call, put, takeLatest } from 'modules/typed-saga';
import { LABELS_ACTIONS, labelsActions } from './actions';
import { ServiceLabels } from 'services/labels';

function* labelsFlow() {
  try {
    const { data: languageID } = yield* call(ServiceLabels.getDefaultLanguageID);
    const {
      data: { value: labels },
    } = yield* call(ServiceLabels.getLabels, languageID);
    yield put(labelsActions.success({ labels, languageID }));
  } catch (e) {
    yield put(labelsActions.fail(e));
  }
}

export const sagasLabels = [takeLatest(LABELS_ACTIONS.FLOW_REQUEST, labelsFlow)];
