import React, { memo } from 'react';
import { AppCircleProgress } from 'components/app-circle-progress';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  zIndex?: number;
  background?: string;
  size?: number;
}

export const CoreLoading = memo<Props>(
  ({ active = true, zIndex = 1000, background = 'rgba(54, 88, 122,.5)', size = 50, ...rest }) => {
    if (!active) return null;
    return (
      <div
        {...rest}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: zIndex,
          background: background,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(2px)',
          color: 'white',
        }}
      >
        <AppCircleProgress size={size} />
      </div>
    );
  },
);
