import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Box, BoxProps } from '@material-ui/core';

interface Props extends BoxProps {
  className?: string;
}

export const AppDialogContent: React.FC<Props> = ({ className, children, ...rest }) => {
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      {children}
    </Box>
  );
};
