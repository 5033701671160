import React, { useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { prepareDateToSave } from 'utils';
import { api } from 'utils/service';

const instances = [api, axios];
const prepareConfig = (conf: AxiosRequestConfig) => {
  if (conf.method === 'post' && conf.data && typeof conf.data === 'object') {
    conf.data = prepareDateToSave(conf.data);
  }
  return conf;
};

export const AxiosInterceptorPost: React.FC = ({ children }) => {
  useEffect(() => {
    instances.map((instance) => {
      return instance.interceptors.request.use(prepareConfig);
    });
  }, []);

  return <>{children}</>;
};
