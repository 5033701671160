import { apiRtk, transformResponseDynamic } from 'utils/service';
import { CreditPoint, API_CREDIT_POINTS } from './models';

export * from './models';

export const apiCreditPoints = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getPoints: build.query<CreditPoint[], void>({
      query: () => ({
        url: API_CREDIT_POINTS.GET_ALL_DYNAMIC,
        params: { orderBy: 'rank' },
      }),
      transformResponse: transformResponseDynamic,
    }),
  }),
});
