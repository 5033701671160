import {
  api,
  apiRtk,
  BaseParams,
  DynamicResult,
  transformResponseDynamic,
  transformResponseDynamicItem,
} from 'utils/service';
import { API_EXPERTS, Expert, ExpertActivity } from './models';
import { createFilter } from 'utils';

export * from './models';

class Service {
  async getCurrentExpert(id: string) {
    const params = {
      filter: `appIdentityUserID=="${id}"`,
    };
    return api.get<{ value: Expert[] }>(API_EXPERTS.GET_DYNAMIC, { params });
  }

  async getExpertsDynamic(params: BaseParams) {
    return await api.get<{ value: Expert[] }>(API_EXPERTS.GET_DYNAMIC, { params });
  }

  async getExpertsActivitiesDynamic(params: BaseParams & { appUserID: string }) {
    return await api.get<DynamicResult<ExpertActivity, { count: true }>>(
      API_EXPERTS.GET_ACTIVITIES_DYNAMIC,
      {
        params,
      },
    );
  }
}

export const ServiceExperts = new Service();

export const apiExperts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getLatestExperts: build.query<Expert[], void>({
      query: () => ({
        url: API_EXPERTS.GET_ALL_DYNAMIC,
        params: { take: 5 },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getExpert: build.query<Expert, { expertID: string }>({
      query: ({ expertID }) => ({
        url: API_EXPERTS.GET_DYNAMIC,
        params: {
          take: 1,
          filter: createFilter<Expert>('appIdentityUserID', '==', expertID),
        },
      }),
      transformResponse: transformResponseDynamicItem,
    }),
  }),
});
