import { useAppSelector } from 'hooks/use-app-selector';
import { useCurrentCompany } from 'hooks/use-current-company';
import { useFetchDynamic } from 'hooks/use-fetch-dynamic';
import { useMap } from 'hooks/use-map';
import { useEffectNotifyError } from 'hooks/use-notify';
import { useTranslate } from 'hooks/use-translate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiAccounts } from 'services/account';
import { apiArticles } from 'services/articles';
import { apiCreditPoints } from 'services/credit-points';
import { apiDashboard } from 'services/dashboard';
import { apiDocuments } from 'services/documents';
import { apiExperts, ExpertActivity, ServiceExperts } from 'services/experts';
import { apiMessageTemplates } from 'services/message-templates';
import { apiParameters } from 'services/parameters';
import { selectAccountCompanyID } from 'store/account';
import { ArrayModel, ArrayType } from 'utils';
import { calcPaginationState } from 'utils/pagination';
import { DynamicResult } from 'utils/service';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return { ...v, data };
};
const useSourceDataMap = <T extends { data: any[] }>(v: T, mapKey: keyof ArrayModel<T['data']>) => {
  const map = useMap(v.data as ArrayModel<T['data']>[], String(mapKey));
  return { ...v, map };
};
export const useSourceTranslate = <T extends { data: any[] }>(
  v: T,
  translateKey: keyof ArrayType<T['data']>,
) => {
  const { t } = useTranslate();

  const data = useMemo(() => {
    return v.data.map((item) => ({ ...item, title: t(item[translateKey]) }));
  }, [v.data, t, translateKey]);
  return useMemo(() => {
    const { data: _, ...rest } = v;
    return { ...rest, data: data as (ArrayType<T['data']> & { title: string })[] };
  }, [v, data]);
};

export const useSourcesDashboardExperts = () => {
  return useSourceData(apiExperts.endpoints.getLatestExperts.useQuery());
};

export const useSourcesDashboardCreditPoints = () => {
  return useSourceData(apiCreditPoints.endpoints.getPoints.useQuery());
};

export const useSourceMedicalFields = () => {
  return useSourceData(apiParameters.endpoints.getMedicalFields.useQuery());
};

export const useSourceHospitals = () => {
  return useSourceData(apiParameters.endpoints.getHospitals.useQuery());
};
export const useSourceHmos = () => {
  return useSourceData(apiParameters.endpoints.getHmos.useQuery());
};

export const useSourceTags = () => {
  return useSourceData(apiParameters.endpoints.getTags.useQuery());
};

export const useSourceArticleCategories = (includeVimed?: boolean) => {
  return useSourceDataMap(
    useSourceData(apiArticles.endpoints.getCategories.useQuery({ includeVimed })),
    'id',
  );
};

export const useSourcesDepartments = () => {
  const pharmaCompanyID = useAppSelector(selectAccountCompanyID);
  return useSourceData(
    apiParameters.endpoints.getDepartments.useQuery({ pharmaCompanyID: pharmaCompanyID || '' }),
  );
};
export const useSourcesDepartmentsByCompanyID = (pharmaCompanyID: string) => {
  return useSourceData(apiParameters.endpoints.getDepartments.useQuery({ pharmaCompanyID }));
};

export const useSourcesDocumentTypes = () => {
  return useSourceDataMap(useSourceData(apiParameters.endpoints.getDocumentTypes.useQuery()), 'id');
};
export const useSourcesDocuments = () => {
  const { pharmaCompanyID } = useCurrentCompany();
  return useSourceData(apiDocuments.endpoints.getAllDocuments.useQuery({ pharmaCompanyID }));
};
export const useSourcesDashboardMedicalFieldsKPI = () => {
  const pharmaCompanyID = useAppSelector(selectAccountCompanyID);
  return useSourceData(
    apiDashboard.endpoints.getMedicalFieldsKPI.useQuery({ pharmaCompanyID: pharmaCompanyID || '' }),
  );
};
export const useSourcesDashboardHospitalsKPI = () => {
  const pharmaCompanyID = useAppSelector(selectAccountCompanyID);
  return useSourceData(
    apiDashboard.endpoints.getHospitalsKPI.useQuery({ pharmaCompanyID: pharmaCompanyID || '' }),
  );
};
export const useSourcesDashboardHmosKPI = () => {
  const pharmaCompanyID = useAppSelector(selectAccountCompanyID);
  return useSourceData(
    apiDashboard.endpoints.getHmosKPI.useQuery({ pharmaCompanyID: pharmaCompanyID || '' }),
  );
};
export const useSourcesPharmaUsers = () => {
  const pharmaCompanyID = useAppSelector(selectAccountCompanyID);
  return useSourceData(
    apiDashboard.endpoints.getPharmaCompanyUsers.useQuery({
      pharmaCompanyID: pharmaCompanyID || '',
    }),
  );
};
export const useSourceVisitorPharmaCompanies = () => {
  return useSourceData(apiAccounts.endpoints.getPharmaCompanies.useQuery());
};
export const useSourcesMessageTemplates = () => {
  const { pharmaCompanyID } = useCurrentCompany();
  return useSourceData(
    apiMessageTemplates.endpoints.getAllMessageTemplates.useQuery({ pharmaCompanyID }),
  );
};

const defaultValueExpertActivities: DynamicResult<ExpertActivity, { count: true }> = {
  value: [],
  count: 0,
};

export const useFetchExpertActivities = (options: { appUserID: string; take?: number }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { appUserID, take = 10 } = options;

  const { pharmaCompanyID } = useCurrentCompany();

  const parser = useCallback(
    (
      prev: typeof defaultValueExpertActivities | undefined,
      next: typeof defaultValueExpertActivities,
    ) => {
      return prev ? { ...prev, value: [...prev.value, ...next.value] } : next;
    },
    [],
  );

  const [trigger, { data = defaultValueExpertActivities, isLoading, error }] = useFetchDynamic(
    ServiceExperts.getExpertsActivitiesDynamic,
    {
      parser,
    },
  );
  useEffectNotifyError(error);

  const { count } = data;

  const onMore = useCallback(() => {
    const { isLastPage, skip } = calcPaginationState({
      take,
      page: currentPage,
      count,
    });
    if (isLastPage) return;
    trigger({
      appUserID,
      count: true,
      skip,
      take,
      pharmaCompanyID,
    });
    setCurrentPage(currentPage + 1);
  }, [currentPage, pharmaCompanyID, count, trigger, take, appUserID]);

  useEffect(() => {
    trigger({ appUserID, pharmaCompanyID, count: true, take });
  }, [trigger, appUserID, pharmaCompanyID, take]);

  return { data, onMore, isLoading };
};
