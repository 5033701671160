import React, { useEffect } from 'react';
import { labelsActions } from 'store/labels';
import { useAppDispatch } from 'hooks';

export const AppTranslateProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(labelsActions.request());
  }, [dispatch]);

  return <>{children}</>;
};
