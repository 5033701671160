import { CoreLoading } from 'components/loading';
import React, { useMemo } from 'react';
import { useAppSelector } from 'hooks';
import { selectStateAccountStatuses } from 'store/account';
import { selectLabels } from 'store/labels';
export const AppInitLoader: React.FC = ({ children }) => {
  const { init: isInitAccount, loading: isLoadingAccount } = useAppSelector(
    selectStateAccountStatuses,
  );
  const { isLoading: isLoadingLabels } = useAppSelector(selectLabels);

  const isInit = useMemo(() => {
    return [isInitAccount].every(Boolean);
  }, [isInitAccount]);

  const isLoading = useMemo(() => {
    return [!isInitAccount && isLoadingAccount, isLoadingLabels].some(Boolean);
  }, [isInitAccount, isLoadingAccount, isLoadingLabels]);

  return (
    <>
      {isInit && children}
      {isLoading && <CoreLoading active={true} />}
    </>
  );
};
