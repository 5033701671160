import DOMPurify from 'dompurify';
const patterns_: { [x: string]: RegExp } = {
  '#': /\d/,
};

export const templateValue = (value: string, templates: string[], patterns = patterns_) => {
  let _templates = Array.from(templates);
  const _value = Array.from(String(value || ''));

  return _value.reduce((result, char, i) => {
    _templates = _templates.filter((template) => {
      const pattern = patterns[template[i]];
      return pattern ? pattern.test(char) : !!template[i];
    });
    const isMatch = !!_templates.length;

    result += isMatch
      ? _templates[0][i]
        ? patterns[_templates[0][i]] && patterns[_templates[0][i]].test(char)
          ? char
          : _templates[0][i]
        : ''
      : '';
    return result;
  }, '');
};

export const replaceLabel = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/#.+?#/gi, (v: string) => {
    const key = v.substring(1, v.length - 1);
    return replacer(key);
  });
};

export const calcRequestLabel = (value: string, replacer: (v: string) => string) => {
  const _value = String(value);
  if (RegExp(/^@.+@$/gi).test(_value)) {
    return replaceLabel(_value.substring(1, _value.length - 1), replacer);
  } else {
    return replacer(_value);
  }
};

export const sanitizePostHtml = (html?: string) =>
  DOMPurify.sanitize(html || '', {
    ADD_ATTR: ['target'],
    ALLOWED_TAGS: ['b', 'strong', 'ul', 'li', 'a', 'p', 'br'],
    ALLOWED_ATTR: ['style', 'href', 'target'],
  }).replace(/\n/gi, '</br>');
