import { Box, Button, DialogActions, DialogContent } from '@material-ui/core';
import { AppDialog, AppDialogTitle } from 'components/app-dialog';
import { CoreLoading } from 'components/loading';
import { NativeScroll } from 'components/native-scroll';
import { RawContent } from 'components/raw-content';
import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

interface Props {
  title: string;
  html?: string;
  isLoading?: boolean;
  onClose: () => void;
}

export const DialogInfo: React.FC<Props> = ({ title, html, isLoading = true, onClose }) => {
  const { t } = useTranslate();
  return (
    <AppDialog open={true} maxWidth={'lg'}>
      <AppDialogTitle onClose={onClose}>{title}</AppDialogTitle>
      <NativeScroll>
        <DialogContent>
          <RawContent html={html} />
          {isLoading && (
            <Box className={style.loading}>
              <CoreLoading active={true} />
            </Box>
          )}
        </DialogContent>
      </NativeScroll>
      <DialogActions className={style.controls}>
        <Button disabled={isLoading} color={'secondary'} variant={'contained'} onClick={onClose}>
          {t('agree')}
        </Button>
      </DialogActions>
    </AppDialog>
  );
};
