const API = 'Pages';

export const API_PAGES = {
  GET_PAGE_LAYOUT: `${API}/GetPageLayout`,
  GET_TERMS: `${API}/GetTermsAndConditions`,
  GET_PRIVACY: `${API}/GetPrivacyPolicy`,
};
export interface Items {
  id: string;
  title: string;
  description: string;
  picture: string;
  rank: number;
}

export interface Page {
  id: string;
  languageID: string;
  title: string;
  htmlURL: string;
  generalBodyScripts: string;
  generalFooterScripts: string;
  generalHeaderScripts: string;
  googleAnalyticScripts: string;
  fullStoryCode: string;
  metaTitle: string;
  metaDescription: string;
  metaKeyWords: string;
  items: Items[];
}
