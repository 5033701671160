import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.auth;

export const selectStateAccount = createSelector(selectState, (state) => state);
export const selectStateAccountStatuses = createSelector(
  selectStateAccount,
  ({ loading, error, code, init }) => ({ loading, error, code, init }),
);
export const selectAccountUser = createSelector(selectStateAccount, ({ user }) => user);
export const selectAccountCompanyID = createSelector(
  selectStateAccount,
  ({ userCompanyID }) => userCompanyID,
);
export const selectAccountCompanies = createSelector(
  selectStateAccount,
  ({ user }) => user?.companies || [],
);
export const selectAccountCompany = createSelector(
  selectAccountCompanyID,
  selectAccountCompanies,
  (companyID, companies) => companies.find((item) => item.pharmaCompanyID === companyID),
);

const selectAccountCompanyPermissions = createSelector(selectAccountCompany, (company) => {
  return {
    isHasCredits: company ? company.usedCredits < company.maxCredits : false,
    isAdmin: company ? company.isAdmin : false,
    isGuest: company ? company.isGuest : true,
  };
});

export const selectAccountPermissions = createSelector(
  selectAccountCompanyPermissions,
  (companyPermissions) => {
    return {
      ...companyPermissions,
      isAllowViewAnalyticOfOtherUsers: companyPermissions.isAdmin,
      isAllowSendMessages: !companyPermissions.isGuest,
      isAllowViewMessagesTabTeam: companyPermissions.isAdmin,
      isAllowGuestEdit: !companyPermissions.isGuest,
    };
  },
);
