import { LABELS_ACTIONS, LabelsActions } from './actions';
import { Label } from 'services/labels';

export interface StateLabels {
  isLoading: boolean;
  defaultLanguageID: string | null;
  error: null | Error;
  labels: Label[];
}

const initState = () => {
  return {
    defaultLanguageID: null,
    isLoading: false,
    error: null,
    labels: [],
  };
};

export const reducerLabels = (
  state: StateLabels = initState(),
  action: LabelsActions,
): StateLabels => {
  switch (action.type) {
    case LABELS_ACTIONS.FLOW_REQUEST:
      return { ...state, isLoading: true, error: null };
    case LABELS_ACTIONS.FLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        labels: action.payload.labels,
        defaultLanguageID: action.payload.languageID,
      };
    case LABELS_ACTIONS.FLOW_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
