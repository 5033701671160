import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message } from 'services/messages';
import { createCombineAction } from 'utils/store';

export enum MESSAGE_FILTER {
  INBOX = 'inbox',
  SENT = 'sent',
  DRAFT = 'draft',
  TEAM = 'team',
}

export interface Filters {
  search: string;
  date: string[];
}

interface State {
  currentTab: MESSAGE_FILTER;
  currentMessageID: string | null;
  data: Message[];
  filters: Filters;
  isLoading: boolean;
  error: null | Error;
}

const initState = (): State => {
  return {
    currentTab: MESSAGE_FILTER.INBOX,
    currentMessageID: null,
    data: [],
    filters: {
      search: '',
      date: [],
    },
    isLoading: false,
    error: null,
  };
};

const slice = createSlice({
  name: 'Messages',
  initialState: initState(),
  reducers: {
    getMessagesRequest(state) {
      state.error = null;
      state.isLoading = true;
    },
    getMessagesSuccess(
      state,
      action: PayloadAction<{
        value: Message[];
      }>,
    ) {
      const { value } = action.payload;
      state.isLoading = false;
      state.data = value;
    },
    getMessagesFail(state, action: PayloadAction<{ error: Error }>) {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    actionMessagesSetFilter(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionMessagesSetTab(state, action: PayloadAction<State['currentTab']>) {
      state.currentTab = action.payload;
    },
    actionMessagesSetMessageID(state, action: PayloadAction<State['currentMessageID']>) {
      state.currentMessageID = action.payload;
    },
  },
});

const action = slice.actions;
export const { actionMessagesSetFilter, actionMessagesSetTab, actionMessagesSetMessageID } = action;

export const actionMessagesGet = createCombineAction(
  action.getMessagesRequest,
  action.getMessagesSuccess,
  action.getMessagesFail,
);

export const reducerMessages = slice.reducer;
