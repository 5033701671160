import { LayoutPrivate, LayoutVisitors } from 'layouts';
import { createTypedRoute } from 'modules/typed-routes';
import { ComponentType, lazy } from 'react';
import { MESSAGE_FILTER } from 'store/messages';
import { SETTINGS_FILTER } from 'utils/service';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  PROFILE: createTypedRoute('/profile'),
  MESSAGES: createTypedRoute<{ tab?: MESSAGE_FILTER; messageID?: string } | void>('/messages'),
  ARTICLES: createTypedRoute('/articles'),
  ARTICLE: createTypedRoute<{ articleID: string }>('/articles/:articleID'),
  DOCUMENTS: createTypedRoute('/documents'),
  SEARCH: createTypedRoute('/search'),
  LOGIN: createTypedRoute('/login'),
  VISITOR_LANDING: createTypedRoute('/visitor'),
  VISITOR_LOGIN: createTypedRoute('/visitor/login'),
  REPLY: createTypedRoute<{ token: string }>('/reply/:token'),
  ABOUT: createTypedRoute('/about'),
  SETTINGS: createTypedRoute<{ tab?: SETTINGS_FILTER }>('/settings'),
  MY_DOCTORS: createTypedRoute('/my-doctors'),
};

export interface IRoute {
  path: string;
  title: string;
  private: boolean;
  company: boolean;
  component?: any;
  Layout?: ComponentType;
}

export const routes: IRoute[] = [
  {
    path: APP_ROUTES.DASHBOARD.path,
    title: 'dashboard',
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/Dashboard')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: APP_ROUTES.MESSAGES.path,
    title: 'messages',
    component: lazy(() => import(/* webpackChunkName: 'MessagesDetails' */ 'pages/Messages')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: APP_ROUTES.ARTICLES.path,
    title: 'articles',
    component: lazy(() => import(/* webpackChunkName: 'ArticlesList' */ 'pages/Articles')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: APP_ROUTES.ARTICLE.path,
    title: 'article',
    component: lazy(() => import(/* webpackChunkName: 'Article' */ 'pages/Article')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  // {
  //   path: APP_ROUTES.DOCUMENTS.path,
  //   title: 'documents',
  //   component: lazy(() => import(/* webpackChunkName: 'Documents' */ 'pages/Documents')),
  //   private: true,
  //   company: true,
  //   Layout: LayoutPrivate,
  // },
  {
    path: APP_ROUTES.SEARCH.path,
    title: 'search',
    component: lazy(() => import(/* webpackChunkName: 'Search' */ 'pages/Search')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: APP_ROUTES.PROFILE.path,
    title: 'profile',
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: APP_ROUTES.LOGIN.path,
    title: 'login',
    private: false,
    company: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
  {
    path: APP_ROUTES.VISITOR_LANDING.path,
    title: 'visitor-landing',
    private: false,
    company: false,
    component: lazy(() => import(/* webpackChunkName: 'VisitorLanding' */ 'pages/VisitorLanding')),
    Layout: LayoutVisitors,
  },
  {
    path: APP_ROUTES.ABOUT.path,
    title: 'about-vimed',
    private: false,
    company: false,
    component: lazy(() => import(/* webpackChunkName: 'VisitorLanding' */ 'pages/About')),
  },
  {
    path: APP_ROUTES.VISITOR_LOGIN.path,
    title: 'visitor-login',
    private: false,
    company: false,
    component: lazy(() => import(/* webpackChunkName: 'VisitorLogin' */ 'pages/VisitorLogin')),
    Layout: LayoutVisitors,
  },
  {
    path: APP_ROUTES.REPLY.path,
    title: 'reply',
    private: false,
    company: false,
    component: lazy(
      () => import(/* webpackChunkName: 'ReplyMessageLanding' */ 'pages/ReplyMessageLanding'),
    ),
  },
  {
    path: APP_ROUTES.SETTINGS.path,
    title: 'settings',
    component: lazy(() => import(/* webpackChunkName: 'Documents' */ 'pages/Settings')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: APP_ROUTES.MY_DOCTORS.path,
    title: 'search',
    component: lazy(() => import(/* webpackChunkName: 'Search' */ 'pages/MyDoctors')),
    private: true,
    company: true,
    Layout: LayoutPrivate,
  },
  {
    path: '*',
    title: 'not-found',
    private: false,
    company: false,
    component: lazy(() => import(/* webpackChunkName: 'NotFound' */ 'pages/NotFound')),
  },
];
