import { useTranslate } from 'hooks/use-translate';
import { useMemo } from 'react';
import { Expert } from 'services/experts';

export const useExpertInfo = (expert: Partial<Expert>) => {
  const { tp } = useTranslate();
  const {
    primaryMedicalFieldTitle,
    userExpertProfileSpeakLanguageTitles,
    hmoTitle,
    hmoName,
    hmoCityTitle,

    hospitalTitle,
    hospitalPosition,
    hospitalDepartmentTitle,

    privateWorkPlaceName,
    privateWorkPlaceMedicalPractice,
  } = expert;
  const generalItems = useMemo(() => {
    return [
      { title: tp('medical-field'), value: primaryMedicalFieldTitle },
      { title: tp('speak-languages'), value: userExpertProfileSpeakLanguageTitles },
    ];
  }, [tp, primaryMedicalFieldTitle, userExpertProfileSpeakLanguageTitles]);

  const hmoItems = useMemo(() => {
    return [
      { title: tp('hmo'), value: hmoTitle },
      { title: tp('hmo-name'), value: hmoName },
      { title: tp('hmo-city'), value: hmoCityTitle },
    ];
  }, [tp, hmoTitle, hmoName, hmoCityTitle]);

  const hospitalItems = useMemo(() => {
    return [
      { title: tp('hospital'), value: hospitalTitle },
      { title: tp('hospital-department'), value: hospitalDepartmentTitle },
      { title: tp('hospital-position'), value: hospitalPosition },
    ];
  }, [tp, hospitalTitle, hospitalDepartmentTitle, hospitalPosition]);

  const privateItems = useMemo(() => {
    return [
      { title: tp('private-work-place-name'), value: privateWorkPlaceName },
      { title: tp('private-work-place-medical-practice'), value: privateWorkPlaceMedicalPractice },
    ];
  }, [tp, privateWorkPlaceName, privateWorkPlaceMedicalPractice]);

  return { generalItems, hmoItems, hospitalItems, privateItems };
};
