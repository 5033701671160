import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { articleItemInitState } from 'store/article/slice';
import { calcPaginationState } from 'utils/pagination';

const selectState = (state: AppState) => state.article;
const selectItemID = (state: AppState, articleID: string) => articleID;

export const selectArticleStateByID = createSelector(
  [selectState, selectItemID],
  (state, articleID) => {
    const item = state[articleID] || articleItemInitState(articleID);

    return {
      ...item,
      users: { ...item.users, pagination: calcPaginationState(item.users.pagination) },
    };
  },
);
