import React from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

interface Props extends CircularProgressProps {
  className?: string;
}

export const AppCircleProgress: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <div className={clsx(style.root, className)}>
      <CircularProgress
        variant={'determinate'}
        className={style.bottom}
        size={40}
        thickness={4}
        {...rest}
        value={100}
      />
      <CircularProgress
        variant={'indeterminate'}
        disableShrink
        className={style.top}
        classes={{
          circle: style.topCircle,
        }}
        size={40}
        thickness={4}
        {...rest}
      />
    </div>
  );
};
