import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Expert } from 'services/experts';
import { createCombineAction } from 'utils/store';

export type ExpertSearch = Pick<
  Expert,
  | 'appIdentityUserID'
  | 'fullName'
  | 'primaryMedicalFieldTitle'
  | 'userExpertProfileSpeakLanguageTitles'
  | 'position'
  | 'userPhoto'
  | 'totalActivities'
  | 'color'
  | 'isHmo'
  | 'hmoTitle'
  | 'hmoName'
  | 'hmoCityTitle'
  | 'isHospital'
  | 'hospitalTitle'
  | 'hospitalDepartmentTitle'
  | 'hospitalPosition'
  | 'isPrivateWorkPlaceName'
  | 'privateWorkPlaceName'
  | 'privateWorkPlaceMedicalPractice'
>;

export enum EXPERTS_SORT {
  DEFAULT = 'default',
  NAME = 'name',
  MOST_ACTIVE = 'active',
}

export interface StateExpertsFilters {
  search: string;
  medicalFields: string[];
  hospitals: string[];
  hmoID: string;
}

interface StateExperts {
  experts: ExpertSearch[];
  isLoading: boolean;
  error: any;
  filters: StateExpertsFilters;
  take: number;
  page: number;
  count: number;
  sort: EXPERTS_SORT;
}

const initState = (): StateExperts => {
  return {
    experts: [],
    isLoading: false,
    error: null,
    filters: {
      search: '',
      medicalFields: [],
      hospitals: [],
      hmoID: '',
    },
    take: 20,
    page: 1,
    count: 0,
    sort: EXPERTS_SORT.DEFAULT,
  };
};

const slice = createSlice({
  name: 'EXPERTS',
  initialState: initState(),
  reducers: {
    getExpertsRequest(state, action: PayloadAction<{ page: number } | undefined>) {
      state.error = null;
      state.isLoading = true;
      state.page = (action.payload && action.payload.page) || state.page;
    },
    getExpertsListSuccess(
      state,
      action: PayloadAction<{
        value: ExpertSearch[];
        count: number;
      }>,
    ) {
      const { value, count } = action.payload;
      state.isLoading = false;
      state.experts = value;
      state.count = count;
    },
    getExpertsListFail(state, action: PayloadAction<{ error: Error }>) {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    actionExpertsFiltersSet(state, action: PayloadAction<Partial<StateExpertsFilters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionExpertsFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionExpertsSortSet(state, action: PayloadAction<EXPERTS_SORT>) {
      state.sort = action.payload;
    },
  },
});
const actions = slice.actions;

export const actionGetExpertsList = createCombineAction(
  actions.getExpertsRequest,
  actions.getExpertsListSuccess,
  actions.getExpertsListFail,
);

export const { actionExpertsFiltersSet, actionExpertsFiltersReset, actionExpertsSortSet } =
  slice.actions;

export const reducerExperts = slice.reducer;
