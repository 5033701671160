import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { getTranslateValue } from 'hooks';
import { toast } from 'react-toastify';
import { selectMapLabels } from 'store/labels';
import { parseErrorData } from 'utils/service';

/**
 * Log a warning and show a toast!
 */
export const rtkErrorLoggerMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = parseErrorData(action.error);

    const message = getTranslateValue(selectMapLabels(api.getState()), error.message);

    toast.error(message);
  }

  return next(action);
};
