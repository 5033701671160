import {
  api,
  API_RTK_TAGS,
  apiRtk,
  BaseParams,
  parseErrorData,
  transformResponseDynamic,
  transformResponseDynamicItem,
  uploadFile,
} from 'utils/service';
import { API_DOCUMENTS, DocumentItem, DocumentProps } from './models';
import { createFilter, PatchModel } from 'utils';

export * from './models';

class Service {
  async getAllDynamic(params: BaseParams) {
    return await api.get<{ value: DocumentProps[] }>(API_DOCUMENTS.GET_DOCUMENTS_DYNAMIC, {
      params,
    });
  }

  async create(data: DocumentItem) {
    const { linkURL, ...rest } = data;
    const result = await api.post<DocumentItem>(API_DOCUMENTS.POST, rest);
    await ServiceDocuments.patch({ linkURL }, result.data);

    return { data: { ...result.data } };
  }

  async patch(newData: Partial<DocumentItem>, oldData: PatchModel<DocumentItem, 'id'>) {
    const linkURL = await uploadFile(newData.linkURL, oldData.linkURL);

    return api.patch(API_DOCUMENTS.PATCH(oldData), { ...newData, linkURL });
  }

  async delete(p: { id: string }) {
    return api.delete(API_DOCUMENTS.DELETE(p));
  }
}

export const ServiceDocuments = new Service();

export const apiDocuments = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllDocuments: build.query<DocumentProps[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_DOCUMENTS.GET_DOCUMENTS_DYNAMIC,
        params: {
          pharmaCompanyID,
          orderBy: 'pharmaCompanyDepartmentID desc,updatedDate desc',
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: API_RTK_TAGS.DOCUMENTS_LIST }],
    }),
    getDocument: build.query<DocumentItem, { documentID: string; pharmaCompanyID: string }>({
      query: ({ documentID, pharmaCompanyID }) => ({
        url: API_DOCUMENTS.GET_DOCUMENTS_DYNAMIC,
        params: {
          take: 1,
          pharmaCompanyID,
          filter: createFilter<DocumentItem>('id', '==', documentID),
        },
      }),
      transformResponse: transformResponseDynamicItem,
      providesTags: (result, error, { documentID }) => [
        { type: API_RTK_TAGS.DOCUMENT, id: documentID },
      ],
    }),
    addDocument: build.mutation<DocumentItem, DocumentItem>({
      queryFn: async (data) => {
        try {
          const result = await ServiceDocuments.create(data);
          return { data: result.data };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: [{ type: API_RTK_TAGS.DOCUMENTS_LIST }],
    }),
    updateDocument: build.mutation<
      void,
      { newData: Partial<DocumentItem>; oldData: PatchModel<DocumentItem, 'id'> }
    >({
      queryFn: async ({ newData, oldData }) => {
        try {
          await ServiceDocuments.patch(newData, oldData);
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { oldData }) => [
        { type: API_RTK_TAGS.DOCUMENTS_LIST },
        { type: API_RTK_TAGS.DOCUMENT, id: oldData.id },
      ],
    }),
    removeDocument: build.mutation<void, PatchModel<DocumentItem, 'id'>>({
      queryFn: async ({ id }) => {
        try {
          await ServiceDocuments.delete({ id });
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: API_RTK_TAGS.DOCUMENTS_LIST },
        { type: API_RTK_TAGS.DOCUMENT, id },
      ],
    }),
  }),
});
