import { Typography } from '@material-ui/core';
import { useTranslate } from 'hooks';
import React from 'react';
import style from './index.module.scss';

export const Footer = () => {
  const { t } = useTranslate();

  return (
    <footer className={style.footer}>
      <div className="container">
        <Typography
          component={'p'}
          variant={'body2'}
          color={'textPrimary'}
          className={style.copyright}
        >
          {t('copyright')}
        </Typography>
      </div>
    </footer>
  );
};
