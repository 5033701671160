import { call, put, select, takeEvery, throttle } from 'modules/typed-saga';
import { Expert, ServiceExperts } from 'services/experts';
import { selectAccountCompanyID } from 'store/account';
import { createFilter, mergeFilters } from 'utils';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifySaga } from 'utils/store';
import {
  selectExpertsSort,
  selectStateExpertsFilters,
  selectStateExpertsPagination,
} from './selectors';
import { actionGetExpertsList, ExpertSearch, EXPERTS_SORT } from './slice';

const calcOrderBy = (sort: EXPERTS_SORT) => {
  switch (sort) {
    case EXPERTS_SORT.NAME:
      return `firstName,lastName`;
    case EXPERTS_SORT.MOST_ACTIVE:
      return 'totalActivities desc';
    default:
      return undefined;
  }
};

const DEFAULT_PARAMS = {
  count: true,
};

function* searchExperts() {
  const { search, medicalFields, hospitals, hmoID } = yield* select(selectStateExpertsFilters);
  const { skip, take } = yield* select(selectStateExpertsPagination);
  const pharmaCompanyID = yield* select(selectAccountCompanyID);
  const sort = yield* select(selectExpertsSort);

  if (!pharmaCompanyID) {
    throw new Error('unexpected-behaviour');
  }

  const filter = mergeFilters(
    [
      createFilter<Expert>(
        [
          'firstName',
          'lastName',
          'primaryMedicalFieldTitle',
          'secondaryMedicalFieldTitle',
          'hmoCityTitle',
          'hmoTitle',
          'hmoName',
          'position',
          'hospitalDepartmentTitle',
          'hospitalTitle',
          'hospitalPosition',
          'privateWorkPlaceName',
          'privateWorkPlaceMedicalPractice',
        ],
        'contains',
        search,
      ),
      createFilter<Expert>(
        ['primaryMedicalFieldID', 'secondaryMedicalFieldID'],
        '==',
        medicalFields,
      ),
      createFilter<Expert>(['hospitalID', 'hospitalDepartmentID'], '==', hospitals),
      createFilter<Expert>(['hmoID'], '==', hmoID),
    ],
    '&&',
  );

  const selectItems: (keyof ExpertSearch)[] = [
    'appIdentityUserID',
    'fullName',
    'primaryMedicalFieldTitle',
    'userExpertProfileSpeakLanguageTitles',
    'position',
    'userPhoto',
    ...(sort === EXPERTS_SORT.MOST_ACTIVE ? (['totalActivities'] as const) : []),
    'color',
    'isHmo',
    'hmoTitle',
    'hmoName',
    'hmoCityTitle',
    'isHospital',
    'hospitalTitle',
    'hospitalDepartmentTitle',
    'hospitalPosition',
    'isPrivateWorkPlaceName',
    'privateWorkPlaceName',
    'privateWorkPlaceMedicalPractice',
  ];

  const orderBy = yield* call(calcOrderBy, sort);
  try {
    const {
      data: { value, count },
    } = yield call(ServiceExperts.getExpertsDynamic, {
      ...DEFAULT_PARAMS,
      select: selectItems.join(','),
      filter,
      skip,
      take,
      orderBy,
      pharmaCompanyID,
    });
    yield put(actionGetExpertsList.success({ value, count }));
  } catch (e) {
    yield* put(actionGetExpertsList.fail({ error: parseErrorData(e) }));
  }
}

export const sagasExperts = [
  throttle(1500, actionGetExpertsList.request, searchExperts),
  takeEvery([actionGetExpertsList.fail], workerErrorNotifySaga),
];
