import { call, put, select, takeEvery, throttle } from 'modules/typed-saga';
import { Article, ServiceArticles } from 'services/articles';
import {
  selectAccountCompany,
  selectAccountCompanyID,
  selectAccountPermissions,
} from 'store/account';
import { createFilter, createFilterDate, mergeFilters } from 'utils/filters';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifySaga } from 'utils/store';
import {
  selectArticlesFilters,
  selectArticlesPagination,
  selectArticlesStatuses,
} from './selectors';
import { actionArticlesGet } from './slice';

const DEFAULT_PARAMS = {
  count: true,
};

function* workerGetArticles() {
  const { search, date, category, showOnlyMyCompany } = yield select(selectArticlesFilters);
  const { skip, take } = yield select(selectArticlesPagination);
  const { orderBy } = yield select(selectArticlesStatuses);
  const pharmaCompanyID = yield select(selectAccountCompanyID);

  const company = yield select(selectAccountCompany);

  const { isAdmin } = yield* select(selectAccountPermissions);

  const filter = mergeFilters(
    [
      mergeFilters(
        [
          mergeFilters(
            [search && 'html!=null', createFilter<Article>('html', 'contains', search)],
            '&&',
          ),
          mergeFilters(
            [
              search && 'articleCategoryTitle!=null',
              createFilter<Article>('articleCategoryTitle', 'contains', search),
            ],
            '&&',
          ),
        ],
        '||',
      ),
      createFilterDate<Article>('publishDate', date),
      createFilter<Article>('articleCategoryID', '==', category),
    ],
    '&&',
  );

  try {
    const {
      data: { value, count },
    } = yield* call(ServiceArticles.getAllDynamic, {
      ...DEFAULT_PARAMS,
      pharmaCompanyID,
      filter,
      skip,
      take,
      orderBy,
      includeVimed: !showOnlyMyCompany,
      pharmaCompanyDepartmentID: !isAdmin ? company.pharmaCompanyDepartmentID : '',
      select: [
        'id',
        'articleCategoryID',
        'publishDate',
        'title',
        'html',
        'video',
        'totalExposures',
      ].join(','),
    });
    yield* put(actionArticlesGet.success({ value, count }));
  } catch (e) {
    yield* put(actionArticlesGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasArticles = [
  throttle(1500, actionArticlesGet.request, workerGetArticles),
  takeEvery([actionArticlesGet.fail], workerErrorNotifySaga),
];
