import { DialogInfo } from 'components/dialog-info';
import React, { useMemo } from 'react';
import { apiPages } from 'services/pages';
interface Props {
  onClose: () => void;
}

export const DialogTerms: React.FC<Props> = ({ onClose }) => {
  const { data, isLoading } = apiPages.endpoints.getTerms.useQuery();

  const html = useMemo(() => {
    // return sanitizeHtmlContent(data?.htmlURL || '');
    return data?.htmlURL;
  }, [data]);

  return (
    <DialogInfo title={data?.title || ''} html={html} isLoading={isLoading} onClose={onClose} />
  );
};
