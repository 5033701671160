import { useNotify } from 'hooks/use-notify';
import { useTranslate } from 'hooks/use-translate';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ErrorFile,
  ErrorFileCode,
  fileToBase64,
  getFileAccept,
  validateFileSize,
  validateFileType,
  ValueFileUploader,
} from 'utils/file-uploader';

interface BaseProps {
  accept?: string[];
  maxSize?: number;
  disabled: boolean | undefined;
  convertFileToBase64?: (file: Blob) => Promise<string>;
}

interface MultipleProps extends BaseProps {
  multiple: true;
  onChange?: (v: ValueFileUploader[], files: File[]) => void;
}

interface SingleProps extends BaseProps {
  multiple?: false;
  onChange?: (v: ValueFileUploader, file: File) => void;
}

export type Options = MultipleProps | SingleProps;

export const useFileUploader = (p: Options) => {
  const { accept, maxSize = 2, disabled, convertFileToBase64 = fileToBase64 } = p;
  const ref = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<null | ErrorFile>(null);

  const { inputType, contentTypes } = useMemo(() => getFileAccept(accept), [accept]);

  const onChangeWrap = useCallback(
    (v: ValueFileUploader[], files: File[]) => {
      if (p.multiple) {
        p.onChange && p.onChange(v, files);
      } else {
        p.onChange && p.onChange(v[0] || '', files[0]);
      }
    },
    [p],
  );

  const _onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(null);
      let { files } = e.target;
      if (!files) {
        return;
      }
      try {
        const result = await Promise.all(
          Array.from(files).map(async (file) => {
            await validateFileType(file, contentTypes);
            await validateFileSize(file, maxSize);

            let value = await convertFileToBase64(file);

            return { name: file.name, size: file.size, type: file.type, value, file };
          }),
        );
        onChangeWrap(result, Array.from(files || []));
      } catch (e) {
        setError(e);
      }
    },
    [contentTypes, maxSize, onChangeWrap, convertFileToBase64],
  );

  const inputProps = useMemo(() => {
    return {
      ref,
      type: 'file',
      hidden: true,
      onChange: _onChange,
      accept: inputType,
      multiple: p.multiple,
      disabled: disabled,
      onClick: () => {
        if (ref.current) {
          ref.current.value = '';
        }
      },
    };
  }, [inputType, _onChange, p.multiple, disabled]);

  const onOpen = useCallback(() => {
    ref.current?.click();
  }, []);
  const onRemove = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
      onChangeWrap([], []);
    }
  }, [onChangeWrap]);

  return { inputProps, onOpen, onRemove, error, setError };
};
export const useFileUploaderNotifyError = (
  error: ErrorFile | null,
  option: { maxSize: number; accept: string[] },
) => {
  const { maxSize, accept: _accept } = option;
  const accept = String(_accept);
  const { tp } = useTranslate();
  const { onError } = useNotify();

  useEffect(() => {
    if (error?.code === ErrorFileCode.MAX_SIZE) {
      toast.error(tp('file-error-size', { maxSize }));
    } else if (error?.code === ErrorFileCode.FILE_TYPE) {
      toast?.error(tp('file-error-type', { accept }));
    } else if (error) {
      onError(error);
    }
  }, [error, tp, accept, maxSize, onError]);
};
