import { Button, ClickAwayListener } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuIconOpen from '@material-ui/icons/MenuOpen';
import clsx from 'clsx';
import { APP_ROUTES } from 'configs/routes';
import { useOpen, useTranslate } from 'hooks';
import { ReactComponent as LogoIcon } from 'images/logo-analytics.svg';
import React, { useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { MESSAGE_FILTER } from 'store/messages';
import { SETTINGS_FILTER } from '../../utils/service';
import style from './index.module.scss';

export const HeaderNav: React.FC = () => {
  const location = useLocation();

  const { t } = useTranslate();
  const { isOpen, handleClose, handleToggle } = useOpen();
  const menu = useMemo(() => {
    return [
      {
        title: t('dashboard'),
        config: APP_ROUTES.DASHBOARD().config,
        isActive: Boolean(
          matchPath(location.pathname, { path: APP_ROUTES.DASHBOARD.path, exact: true }),
        ),
      },
      {
        title: t('search-doctors'),
        config: APP_ROUTES.SEARCH().config,
        isActive: Boolean(
          matchPath(location.pathname, { path: APP_ROUTES.SEARCH.path, exact: true }),
        ),
      },
      {
        title: t('messages'),
        config: APP_ROUTES.MESSAGES({ tab: MESSAGE_FILTER.INBOX }).config,
        isActive: Boolean(
          matchPath(location.pathname, { path: APP_ROUTES.MESSAGES.path, exact: true }),
        ),
      },
      {
        title: t('articles'),
        config: APP_ROUTES.ARTICLES().config,
        isActive: Boolean(
          matchPath(location.pathname, {
            path: [APP_ROUTES.ARTICLES.path, APP_ROUTES.ARTICLE.path],
            exact: true,
          }),
        ),
      },
      {
        title: t('my-doctors'),
        config: APP_ROUTES.MY_DOCTORS().config,
        isActive: Boolean(
          matchPath(location.pathname, { path: APP_ROUTES.MY_DOCTORS.path, exact: true }),
        ),
      },
      {
        title: t('settings'),
        config: APP_ROUTES.SETTINGS({ tab: SETTINGS_FILTER.DOCUMENTS }).config,
        isActive: Boolean(
          matchPath(location.pathname, { path: APP_ROUTES.SETTINGS.path, exact: true }),
        ),
      },
    ];
  }, [t, location.pathname]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <header className={style.root}>
        <nav className={clsx(style.mainNav, 'container')}>
          <Link
            to={APP_ROUTES.DASHBOARD().config}
            className={style.logoWrapper}
            onClick={handleClose}
          >
            <LogoIcon />
          </Link>
          <div className={clsx(style.linkWrapper, isOpen && style.opened)}>
            {menu.map(({ title, config, isActive }, i) => (
              <Button
                key={i}
                className={clsx(style.navButton, isActive && style.active)}
                component={Link}
                to={config}
              >
                {title}
              </Button>
            ))}
          </div>
          <Button className={style.burgerBtn} onClick={handleToggle}>
            {isOpen ? <MenuIconOpen fontSize={'large'} /> : <MenuIcon fontSize={'large'} />}
          </Button>
        </nav>
      </header>
    </ClickAwayListener>
  );
};
