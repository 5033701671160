import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessagesForDoctors } from 'services/messages';
import { getSixMonthBeforeTodayDate, getTodayDate } from 'utils';
import { createCombineAction } from 'utils/store';
import { actionAccountCompanySet, actionAccountLogout } from '../account';

interface Filters {
  search: string;
  pharmaCompanyUserID: string | null;
  period: string[];
}

interface State {
  data: MessagesForDoctors[];
  isLoading: boolean;
  error: null | Error;
  filters: Filters;
  take: number;
  page: number;
  count: number;
}

const initState = (): State => {
  return {
    data: [],
    isLoading: false,
    error: null,
    filters: {
      search: '',
      pharmaCompanyUserID: null,
      period: [getSixMonthBeforeTodayDate().toISOString(), getTodayDate().toISOString()],
    },
    take: 20,
    page: 1,
    count: 0,
  };
};

const slice = createSlice({
  name: 'MY_DOCTORS',
  initialState: initState(),
  reducers: {
    getDoctorsRequest(state, action: PayloadAction<{ page: number } | undefined>) {
      state.error = null;
      state.isLoading = true;
      state.page = (action.payload && action.payload.page) || state.page;
    },
    getDoctorsSuccess(
      state,
      action: PayloadAction<{
        value: MessagesForDoctors[];
        count: number;
      }>,
    ) {
      const { value, count } = action.payload;
      state.isLoading = false;
      state.data = value;
      state.count = count;
    },
    getDoctorsFail(state, action: PayloadAction<{ error: Error }>) {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    actionMyDoctorsSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
    build.addCase(actionAccountCompanySet, (state) => {
      state.filters.pharmaCompanyUserID = null;
    });
  },
});

const action = slice.actions;
export const { actionMyDoctorsSetFilters } = action;

export const actionGetMyDoctors = createCombineAction(
  action.getDoctorsRequest,
  action.getDoctorsSuccess,
  action.getDoctorsFail,
);

export const reducerMyDoctors = slice.reducer;
