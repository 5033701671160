import { CommonSelectProps } from 'components/select';
import { useCallback } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { decorateAfter, fieldToLabelKey } from 'utils/other';
import { useTranslate } from './use-translate';

interface Props extends Partial<CommonSelectProps> {}

export const useFilterSelectProps = (props?: Props) => {
  const { t } = useTranslate();
  return useCallback(
    (renderProps: ControllerRenderProps) => {
      const valueArrayHas = Array.isArray(renderProps.value) && renderProps.value.length;
      return {
        ...props,
        RenderInputProps: {
          ...props?.RenderInputProps,
          InputLabelProps: {
            ...props?.RenderInputProps?.InputLabelProps,
          },
        },
        ...renderProps,
        label: t(fieldToLabelKey(renderProps.name)),
        placeholder: valueArrayHas ? undefined : t('all'),
        onChange: decorateAfter(renderProps.onChange, props?.onChange),
      };
    },
    [props, t],
  );
};
