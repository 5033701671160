import { format } from 'date-fns';
import { call, put, select, takeEvery, throttle } from '../../modules/typed-saga';
import { MessagesForDoctors, ServiceMessages } from '../../services/messages';
import { createFilter, mergeFilters } from '../../utils';
import { convertToDate } from '../../utils/dates';
import { parseErrorData } from '../../utils/service';
import { workerErrorNotifySaga } from '../../utils/store';
import { selectAccountCompanyID } from '../account';
import { selectStateExpertsPagination } from '../experts';
import { selectMyDoctorsFilters } from './selectors';
import { actionGetMyDoctors } from './slice';

function* workerGetMessagesFormDoctors() {
  const { search, pharmaCompanyUserID, period } = yield* select(selectMyDoctorsFilters);
  const { skip, take } = yield* select(selectStateExpertsPagination);
  const pharmaCompanyID = yield* select(selectAccountCompanyID);

  const fromDate = period[0] ? format(convertToDate(period[0]), 'yyyy-MM-dd') : undefined;
  const toDate = period[1] ? format(convertToDate(period[1]), 'yyyy-MM-dd') : undefined;

  const filter = mergeFilters(
    [
      createFilter<MessagesForDoctors>(
        ['userExpertProfileFirstName', 'userExpertProfileLastName'],
        'contains',
        search,
      ),
    ],
    '&&',
  );

  try {
    if (!pharmaCompanyID) {
      throw new Error('unexpected-behaviour');
    }
    const {
      data: { value, count },
    } = yield* call(ServiceMessages.getMessagesForDoctors, {
      pharmaCompanyID,
      pharmaCompanyUserID,
      fromDate,
      toDate,
      filter,
      skip,
      take,
      orderBy: 'totalMessageSent desc',
      count: true,
    });
    yield* put(actionGetMyDoctors.success({ value, count }));
  } catch (e) {
    yield* put(actionGetMyDoctors.fail({ error: parseErrorData(e) }));
  }
}

export const sagasMyDoctors = [
  throttle(1500, actionGetMyDoctors.request, workerGetMessagesFormDoctors),
  takeEvery([actionGetMyDoctors.fail], workerErrorNotifySaga),
];
