import React, { useCallback } from 'react';
import { AxiosInterceptorsToken } from './token';
import { AxiosInterceptorsRefreshToken } from './refresh-token';
import { AxiosInterceptorPatch } from './patch';

import { AxiosInterceptorPost } from './post';
import { getAuthTokens, updateAuthTokens } from 'utils/cookies';
import { ServiceAccount } from 'services/account';

export const AxiosInterceptors: React.FC = ({ children }) => {
  const onRefresh = useCallback(async () => {
    const { token, refreshToken, expires } = getAuthTokens();

    if (!token || !refreshToken || !expires) {
      throw new Error('token-not-exist');
    }

    const {
      data: { refreshToken: RefreshToken, token: Token },
    } = await ServiceAccount.refreshToken({ token, refreshToken });

    return { token: Token, refreshToken: RefreshToken, expires };
  }, []);

  const onError = useCallback((e: Error) => {
    if (window.location.pathname !== '/login') {
      window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}`;
    }
  }, []);

  return (
    <AxiosInterceptorPost>
      <AxiosInterceptorPatch>
        <AxiosInterceptorsToken>
          <AxiosInterceptorsRefreshToken
            onRefresh={onRefresh}
            onSuccess={updateAuthTokens}
            onError={onError}
          >
            {children}
          </AxiosInterceptorsRefreshToken>
        </AxiosInterceptorsToken>
      </AxiosInterceptorPatch>
    </AxiosInterceptorPost>
  );
};
