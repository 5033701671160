import { useAppSelector } from 'hooks/use-app-selector';
import { selectAccountCompany } from 'store/account';

export const useCurrentCompany = () => {
  const company = useAppSelector(selectAccountCompany);

  if (!company) {
    throw new Error('company: unexpected behaviour');
  }

  return company;
};
