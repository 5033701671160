import { createFilter, PatchModel } from 'utils';
import {
  api,
  apiRtk,
  API_RTK_TAGS,
  BaseParams,
  parseErrorData,
  transformResponseDynamic,
  transformResponseDynamicItem,
} from 'utils/service';
import { API_MESSAGE_TEMPLATES, MessageTemplateItem, MessageTemplateProps } from './models';

export * from './models';

class Service {
  async getAllDynamic(params: BaseParams) {
    return await api.get<{ value: MessageTemplateProps[] }>(
      API_MESSAGE_TEMPLATES.GET_MESSAGE_TEMPLATES_DYNAMIC,
      {
        params,
      },
    );
  }

  async create(data: MessageTemplateItem) {
    const { ...rest } = data;
    const result = await api.post<MessageTemplateProps>(API_MESSAGE_TEMPLATES.POST, rest);
    await ServiceMessageTemplates.patch({ ...rest }, result.data);

    return { data: { ...result.data } };
  }

  async patch(
    newData: Partial<MessageTemplateProps>,
    oldData: PatchModel<MessageTemplateProps, 'id'>,
  ) {
    return api.patch(API_MESSAGE_TEMPLATES.PATCH(oldData), { ...newData });
  }

  async delete(p: { id: string }) {
    return api.delete(API_MESSAGE_TEMPLATES.DELETE(p));
  }
}

export const ServiceMessageTemplates = new Service();

export const apiMessageTemplates = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllMessageTemplates: build.query<MessageTemplateProps[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_MESSAGE_TEMPLATES.GET_MESSAGE_TEMPLATES_DYNAMIC,
        params: {
          pharmaCompanyID,
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: API_RTK_TAGS.MESSAGE_TEMPLATES }],
    }),
    getMessageTemplate: build.query<
      MessageTemplateItem,
      { templateID: string; pharmaCompanyID: string }
    >({
      query: ({ templateID, pharmaCompanyID }) => ({
        url: API_MESSAGE_TEMPLATES.GET_MESSAGE_TEMPLATES_DYNAMIC,
        params: {
          take: 1,
          pharmaCompanyID,
          filter: createFilter<MessageTemplateItem>('id', '==', templateID),
        },
      }),
      transformResponse: transformResponseDynamicItem,
      providesTags: (result, error, { templateID }) => [
        { type: API_RTK_TAGS.MESSAGE_TEMPLATES, id: templateID },
      ],
    }),
    addMessageTemplate: build.mutation<MessageTemplateProps, MessageTemplateProps>({
      queryFn: async (data) => {
        try {
          const result = await ServiceMessageTemplates.create(data);
          return { data: result.data };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: [{ type: API_RTK_TAGS.MESSAGE_TEMPLATES }],
    }),
    updateMessageTemplate: build.mutation<
      void,
      { newData: Partial<MessageTemplateProps>; oldData: PatchModel<MessageTemplateProps, 'id'> }
    >({
      queryFn: async ({ newData, oldData }) => {
        try {
          await ServiceMessageTemplates.patch(newData, oldData);
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { oldData }) => [
        { type: API_RTK_TAGS.MESSAGE_TEMPLATES },
        { type: API_RTK_TAGS.MESSAGE_TEMPLATES, id: oldData.id },
      ],
    }),
    removeMessageTemplate: build.mutation<void, PatchModel<MessageTemplateItem, 'id'>>({
      queryFn: async ({ id }) => {
        try {
          await ServiceMessageTemplates.delete({ id });
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: API_RTK_TAGS.MESSAGE_TEMPLATES },
        { type: API_RTK_TAGS.MESSAGE_TEMPLATES, id },
      ],
    }),
  }),
});
