const API_ENDPOINT = 'Dashboards';

export const API_DASHBOARDS = {
  GET_KPI_GENERAL: (pharmaCompanyID: string) =>
    `${API_ENDPOINT}/GetVimedGeneralKPIs/${pharmaCompanyID}`,
  GET_KPI_HMOS: (pharmaCompanyID: string) => `${API_ENDPOINT}/GetVimedHMOKPIs/${pharmaCompanyID}`,
  GET_KPI_MEDICAL_FIELDS: (pharmaCompanyID: string) =>
    `${API_ENDPOINT}/GetVimedMedicalFieldKPIs/${pharmaCompanyID}`,
  GET_KPI_HOSPITALS: (pharmaCompanyID: string) =>
    `${API_ENDPOINT}/GetVimedHospitalKPIs/${pharmaCompanyID}`,
  GET_PHARMA_USERS_DYNAMIC: `${API_ENDPOINT}/GetPharmaUsersDynamic`,
  GET_KPI_MESSAGE: `${API_ENDPOINT}/GetMessageKPIsForUser`,
  GET_CREDITS_PER_USER: `${API_ENDPOINT}/GetCreditsPerUser`,
  GET_INTERACTIONS_OVER_TIME: `${API_ENDPOINT}/GetInteractionsOverTimeDynamic`,

  GET_KPI_VIEWS_DYNAMIC: `${API_ENDPOINT}/GetGeneralUserViewsDynamic`,
  GET_KPI_PLAYS_DYNAMIC: `${API_ENDPOINT}/GetGeneralUserPlaysDynamic`,
  GET_KPI_COMMENTS_DYNAMIC: `${API_ENDPOINT}/GetGeneralUserCommentsDynamic`,
  GET_ARTICLE_KPI: (articleID: string) => `${API_ENDPOINT}/GetArticleKPIs/${articleID}`,
  // GET_ARTICLE_USER_EXPOSURES_DYNAMIC: `${API_ENDPOINT}/GetArticeUserExposuresDynamic`,
  GET_ARTICLE_ANALYTICS: `${API_ENDPOINT}/GetArticleAnalytics`,
  GET_ARTICLE_ACTIVITIES: `${API_ENDPOINT}/GetArticleAnalyticActivities`,
  GET_ARTICLE_HMO: `${API_ENDPOINT}/GetArticleAnalyticHMOs`,
  GET_ARTICLE_MEDICAL_FIELDS: `${API_ENDPOINT}/GetArticleAnalyticMedicalFields`,
  GET_ARTICLE_HOSPITALS: `${API_ENDPOINT}/GetArticleAnalyticHospitals`,
  GET_DOCUMENTS: `${API_ENDPOINT}/GetDocumentsAnalyticOverTimeDynamic`,

  // GET_HMO_USERS_DYNAMIC: `${API_ENDPOINT}/GetArticleAnalyticHmoUsersDynamic`,
  // GET_MEDICAL_FIELDS_USERS_DYNAMIC: `${API_ENDPOINT}/GetArticleAnalyticMedicalFieldUsersDynamic`,
  // GET_ACTIVITIES_USERS_DYNAMIC: `${API_ENDPOINT}/GetArticleAnalyticActivityUsersDynamic`,
  // GET_HOSPITAL_USERS_DYNAMIC: `${API_ENDPOINT}/GetArticleAnalyticHospitalUsersDynamic`,

  GET_ARTICLE_ANALYTIC_USERS: `${API_ENDPOINT}/GetArticleAnalyticUsersDynamic`,
  GET_DOCUMENTS_USERS_DYNAMIC: `${API_ENDPOINT}/GetDocumentUsersDynamic`,

  GET_MESSAGES_ANALYTICS_HMO: `${API_ENDPOINT}/GetMessagesAnalyticHMOs`,
  GET_MESSAGES_ANALYTICS_HOSPITALS: `${API_ENDPOINT}/GetMessagesAnalyticHospitals`,
  GET_MESSAGES_ANALYTICS_MEDICAL_FIELDS: `${API_ENDPOINT}/GetMessagesAnalyticMedicalFields`,

  GET_MESSAGES_ANALYTICS_USERS_DYNAMIC: `${API_ENDPOINT}/GetMessagesAnalyticUsersDynamic`,
};

export interface KPIArticleCategories {
  articleCategoryIcon: string;
  articleCategoryTitle: string;
  total: number;
}

export interface KPIHMOS {
  hmoName: string;
  total: number;
}

export interface KPIHospitals {
  hospitalName: string;
  total: number;
}

export interface KPIMedicalFields {
  medicalFieldTitle: string;
  total: number;
}

export interface GeneralKPI {
  kpiMedicalFields: KPIMedicalFields[];
  kpiHmos: KPIHMOS[];
  kpiHospitals: KPIHospitals[];
  kpiArticleCategories: KPIArticleCategories[];
  totalExposures: number;
  totalLikes: number;
  totalComments: number;
  totalViews: number;
  totalPlays: number;
}
export interface GeneralKPIs {
  totalDoctors: number;
  totalExposures: number;
}
export interface MedicalFieldKPI {
  id: string;
  medicalFieldTitle: string;
  total: number;
}
export interface HmoKPI {
  id: string;
  hmoName: string;
  total: number;
}
export interface HospitalKPI {
  id: string;
  hospitalName: string;
  total: number;
}

export interface PharmaUser {
  pharmaCompanyUserID: string;
  pharmaCompanyUserFirstName: string;
  pharmaCompanyUserLastName: string;
  pharmaCompanyUserPhoto: string;
  pharmaCompanyUserPosition: string;
  pharmaCompanyUserColor: string;
  pharmaCompanyDepartmentID: string;
}

export interface PharmaUserMessagesKPI {
  totalSent: number;
  totalViewed: number;
  totalReplied: number;
  totalAttachmentClicked: number;
}
export interface CreditsPerUser {
  pharmaCompanyUserID: string;
  pharmaCompanyUserFirstName: string;
  pharmaCompanyUserLastName: string;
  pharmaCompanyUserPhoto: string;
  pharmaCompanyUserPosition: string;
  pharmaCompanyUserColor: string;
  maxCreditsPerMonth: number;
  usedCredits: number;
}

export interface InteractionOverTime {
  pharmaCompanyDepartmentID: string;
  pharmaCompanyUserID: string;
  pharmaCompanyUserFirstName: string;
  pharmaCompanyUserLastName: string;
  pharmaCompanyUserPhoto: string;
  pharmaCompanyUserPosition: string;
  pharmaCompanyUserColor: string;
  entryDate: string;
  totalInteractions: number;
}

export interface Analytics {
  articleID: string;
  articleTitle: string;
  totalExposures: number;
  allowClick: boolean;
  pharmaCompanyDepartmentID: string;
}

export interface DocumentOverTime {
  documentID: string;
  documentTitle: string;
  documentTypeID: string;
  documentTypeIcon: string;
  documentTypeTitle: string;
  pharmaCompanyDepartmentID: string;
  totalSent: number;
  totalViewed: number;
  totalClicked: number;
}
