import { call, put, select, takeEvery, throttle } from 'modules/typed-saga';
import { DocumentProps, ServiceDocuments } from 'services/documents';
import { selectAccountCompanyID } from 'store/account';
import { createFilter, mergeFilters } from 'utils';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifySaga } from 'utils/store';
import { selectDocumentsFilters } from './selectors';
import { actionDocumentsGet } from './slice';

function* workerGetDocuments() {
  const { search, pharmaCompanyDocumentTypeID } = yield* select(selectDocumentsFilters);
  const pharmaCompanyID = yield* select(selectAccountCompanyID);

  const filter = mergeFilters(
    [
      createFilter<DocumentProps>(
        [
          'title',
          'pharmaCompanyDepartmentName',
          'documentTypeTitle',
          'pharmaCompanyUserFirstName',
          'pharmaCompanyUserLastName',
        ],
        'contains',
        search,
      ),
      createFilter<DocumentProps>('pharmaCompanyDocumentTypeID', '==', pharmaCompanyDocumentTypeID),
    ],
    '&&',
  );

  try {
    if (!pharmaCompanyID) {
      throw new Error('unexpected-behaviour');
    }
    const {
      data: { value },
    } = yield* call(ServiceDocuments.getAllDynamic, {
      pharmaCompanyID,
      filter,
      orderBy: 'pharmaCompanyDepartmentID desc,updatedDate desc',
    });
    yield* put(actionDocumentsGet.success({ value }));
  } catch (e) {
    yield* put(actionDocumentsGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasDocuments = [
  throttle(1500, actionDocumentsGet.request, workerGetDocuments),
  takeEvery([actionDocumentsGet.fail], workerErrorNotifySaga),
];
