import { useCallback, useRef, useState } from 'react';

export const useStateRef = <T = undefined>(initSate: T) => {
  const ref = useRef(initSate);
  const [state, _setState] = useState(initSate);

  const setSate = useCallback((newSate: T) => {
    _setState(newSate);
    ref.current = newSate;
  }, []);

  const getSate = useCallback(() => {
    return ref.current;
  }, []);

  return [state, setSate, getSate] as const;
};
