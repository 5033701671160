import { apiRtk, transformResponseHtml } from 'utils/service';
import { API_PAGES, Page } from './models';

export * from './models';

export const apiPages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getTerms: build.query<Page, void>({
      query: () => ({
        url: API_PAGES.GET_TERMS,
      }),
      transformResponse: (data: Page) => transformResponseHtml(data, ['htmlURL']),
    }),
    getPrivacy: build.query<Page, void>({
      query: () => ({
        url: API_PAGES.GET_PRIVACY,
      }),
      transformResponse: (data: Page) => transformResponseHtml(data, ['htmlURL']),
    }),
    getPageLayout: build.query<Page, void>({
      query: () => ({
        url: API_PAGES.GET_PAGE_LAYOUT,
      }),
      transformResponse: (data: Page) => transformResponseHtml(data, ['htmlURL']),
    }),
  }),
});
