import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageTemplateItem } from 'services/message-templates';
import { createCombineAction } from 'utils/store';
import { actionAccountLogout } from '../account';

interface Filters {
  search: string;
}

interface State {
  data: MessageTemplateItem[];
  isLoading: boolean;
  error: null | Error;
  filters: Filters;
  isInit: boolean;
}

const initState = (): State => {
  return {
    data: [],
    isLoading: false,
    isInit: false,
    error: null,
    filters: {
      search: '',
    },
  };
};

const slice = createSlice({
  name: 'MESSAGE_TEMPLATES',
  initialState: initState(),
  reducers: {
    getMessageTemplatesRequest(state) {
      state.error = null;
      state.isLoading = true;
    },
    getMessageTemplatesSuccess(
      state,
      action: PayloadAction<{
        value: any[];
      }>,
    ) {
      const { value } = action.payload;
      state.isLoading = false;
      state.data = value;
    },
    getMessageTemplatesFail(state, action: PayloadAction<{ error: Error }>) {
      state.error = action.payload.error;
      state.isLoading = false;
    },
    actionMessageTemplatesSetFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(actionAccountLogout.fulfilled, () => {
      return initState();
    });
  },
});

const action = slice.actions;
export const { actionMessageTemplatesSetFilters } = action;

export const actionMessageTemplatesGet = createCombineAction(
  action.getMessageTemplatesRequest,
  action.getMessageTemplatesSuccess,
  action.getMessageTemplatesFail,
);

export const reducerMessageTemplates = slice.reducer;
