import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { ServiceMediaUploads } from 'services/media-uploads';
import { isFileLike, ValueFileUploader } from './file-uploader';

export type DynamicResult<T extends any, P extends BaseParams = {}> = P extends {
  count: boolean;
}
  ? { value: T[]; count: number }
  : { value: T[]; count: undefined };

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,7}$/gi.test(String(value));
};

export const axiosDateTransformer: AxiosTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      if (typeof value === 'string' && isServerDateString(value)) {
        const _date = Date.parse(value);
        if (_date) {
          return `${value.split('.')[0]}.000Z`;
        }
      }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const BASE_URL = process.env.REACT_APP_API_SERVER as string;
export const api = axios.create({
  baseURL: `${BASE_URL}/api`,
  transformResponse: [axiosDateTransformer],
});

export interface BaseParams {
  select?: string;
  filter?: string;
  orderBy?: string;
  take?: number;
  skip?: number;
  count?: boolean;
  articleID?: string;
  surveyID?: string;
  pharmaCompanyID?: string;
  pharmaCompanyUserID?: string;
  pharmaCompanyDepartmentID?: string;
  fromDate?: string;
  toDate?: string;
  documentID?: string;
  includeVimed?: boolean;
}

export const axiosBaseGetQuery =
  (): BaseQueryFn<Pick<AxiosRequestConfig, 'params' | 'url'>, unknown, Error> =>
  async ({ url, params }) => {
    try {
      const result = await api.get(url || '', { params });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: parseErrorData(err),
      };
    }
  };

export const parseErrorData = <T = string>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }
    return { message: 'error', ...errorData };
  }
  return new Error(error.message);
};

export enum API_RTK_TAGS {
  DOCUMENT = 'document',
  MESSAGE = 'message',
  MESSAGE_DOCUMENTS = 'message-documents',
  MESSAGE_LIST_WITH_USER = 'message-list-with-user',
  MESSAGE_TEMPLATES = 'message-templates',
  MESSAGE_REPLY_TEMPLATES = 'message-templates',
  DOCUMENTS_LIST = 'documents-list',
}
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseGetQuery(),
  endpoints: () => ({}),
  tagTypes: [
    API_RTK_TAGS.DOCUMENT,
    API_RTK_TAGS.DOCUMENTS_LIST,
    API_RTK_TAGS.MESSAGE,
    API_RTK_TAGS.MESSAGE_LIST_WITH_USER,
    API_RTK_TAGS.MESSAGE_DOCUMENTS,
    API_RTK_TAGS.MESSAGE_TEMPLATES,
    API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES,
  ],
});

export const fetchHtml = async (url: string | null | undefined) => {
  if (!url) {
    return '';
  }
  try {
    const result = await fetch(url);
    if (result.ok) {
      return result.text();
    }
    return '';
  } catch (e) {
    return '';
  }
};

export const transformResponseHtml = async <T extends Record<string, any>, K extends keyof T>(
  data: T,
  keys: (keyof T)[],
) => {
  const result = await Promise.all(
    keys.map(async (key) => {
      return { key, value: (await fetchHtml(data[key])) as T[keyof T] };
    }),
  );

  const rest = result.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {} as T);

  return { ...data, ...rest };
};
export const transformResponseDynamic = <T extends { value: any[] }>(data: T) => {
  return data.value;
};
export const transformResponseDynamicItem = <T extends { value: any[] }>(data: T) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item;
};
export const isRtkMutationRejected = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isRtkMutationFulfilled = <T>(mutationResult: any): mutationResult is { data: T } => {
  return Boolean(
    mutationResult && mutationResult.hasOwnProperty && mutationResult.hasOwnProperty('data'),
  );
};

const uploadFlowActions = async <
  N extends ValueFileUploader | undefined | null = undefined,
  O extends string | undefined | null = undefined,
>(
  newValue: N,
  oldValue: O,
  uploader: (v: Exclude<N, undefined>) => Promise<{ data: { filePath: string | null } }>,
) => {
  let result = undefined;

  // @ts-ignore
  if (oldValue === newValue) {
    return Promise.resolve(result);
  }

  if (oldValue && (newValue || newValue === '')) {
    try {
      // @ts-ignore
      await ServiceMediaUploads.remove(oldValue);
    } catch (e) {}
    result = '';
  }

  if (newValue) {
    const {
      data: { filePath },
    } = await uploader(newValue as Exclude<N, undefined>);
    result = filePath;
  }

  return Promise.resolve(result);
};

const uploadFileLike = (
  value: ValueFileUploader | null,
  uploader: (
    v: Exclude<ValueFileUploader, string | undefined | null>,
  ) => Promise<{ data: { filePath: string } }>,
) => {
  if (isFileLike(value)) {
    return uploader(value);
  }
  return Promise.resolve({ data: { filePath: value } });
};

export const uploadHtml = async (
  newValue: string | undefined,
  oldValue: string | undefined,
  fileName?: string,
) => {
  return uploadFlowActions(newValue, oldValue, async (htmlContent) => {
    return ServiceMediaUploads.uploadHtml({ htmlContent, fileName });
  });
};
export const uploadImage = async (
  newValue: ValueFileUploader | undefined | null,
  oldValue: string | undefined | null,
) => {
  return uploadFlowActions(newValue, oldValue, async (value) => {
    return uploadFileLike(value, ({ value: fileStreamString, name }) =>
      ServiceMediaUploads.uploadImage({ fileStreamString, fileName: name }),
    );
  });
};
export const uploadFile = async (
  newValue: ValueFileUploader | undefined | null,
  oldValue: string | undefined | null,
) => {
  return uploadFlowActions(newValue, oldValue, async (value) => {
    return uploadFileLike(value, ({ value: fileStreamString, name }) =>
      ServiceMediaUploads.uploadFile({ fileStreamString, fileName: name }),
    );
  });
};

export enum SETTINGS_FILTER {
  DOCUMENTS = 'documents',
  MESSAGE_TEMPLATES = 'message-templates',
  DOCTORS_REPLY = 'doctors-reply',
}
