import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: AppState) => state.dashboardInteractions;

const selectDashboardInteractionsState = createSelector(selectState, (state) => state);
export const selectDashboardInteractionsFilters = createSelector(
  selectDashboardInteractionsState,
  ({ filters }) => filters,
);
