import { useCallback, useMemo, useState } from 'react';
export const useOpen = (init: boolean = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(init);
  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleToggle = useCallback(() => {
    setIsOpen((prev) => {
      return !prev;
    });
  }, []);

  return useMemo(() => {
    return {
      isOpen,
      setIsOpen,
      handleOpen,
      handleClose,
      handleToggle,
    };
  }, [isOpen, setIsOpen, handleOpen, handleClose, handleToggle]);
};
