const API_ENDPOINT = 'MessageTemplates';

export const API_MESSAGE_TEMPLATES = {
  API_ENDPOINT,
  GET_MESSAGE_TEMPLATES_DYNAMIC: `${API_ENDPOINT}/GetMessageTemplatesDynamic`,
  POST: `${API_ENDPOINT}/CreateMessageTemplate`,
  DELETE: (data: { id: MessageTemplateItem['id'] }) =>
    `${API_ENDPOINT}/DeleteMessageTemplate/${data.id}`,
  PATCH: (data: { id: MessageTemplateItem['id'] }) =>
    `${API_ENDPOINT}/PatchMessageTemplate/${data.id}`,
};

export class MessageTemplateItem {
  id!: string;
  title = '';
  subject = '';
  message = '';
}

export interface MessageTemplateProps extends MessageTemplateItem {
  pharmaCompanyID: string;
}
