import { ForwardedRef } from 'react';

export const mergeRef = <T>(...refs: ForwardedRef<T>[]) => {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        return ref(instance);
      }
      if (ref) {
        ref.current = instance;
      }
    });
  };
};

export const useMergeRef = <T>(...refs: (ForwardedRef<T> | undefined)[]) => {
  return (instance: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        return ref(instance);
      }
      if (ref) {
        ref.current = instance;
      }
    });
  };
};
