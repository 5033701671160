const API_ENDPOINT = 'Documents';

export const API_DOCUMENTS = {
  API_ENDPOINT,
  GET_DOCUMENTS_DYNAMIC: `${API_ENDPOINT}/GetDocumentsDynamic`,
  POST: `${API_ENDPOINT}/CreateDocument`,
  DELETE: (data: { id: DocumentItem['id'] }) => `${API_ENDPOINT}/DeleteDocument/${data.id}`,
  PATCH: (data: { id: DocumentItem['id'] }) => `${API_ENDPOINT}/PatchDocument/${data.id}`,
};

export class DocumentItem {
  id!: string;
  title = '';
  pharmaCompanyDocumentTypeID = '';
  pharmaCompanyDepartmentID = '';
  pharmaCompanyUserID = '';
  pharmaCompanyID = '';
  linkURL = '';
  isActive = false;
}

export interface DocumentProps extends DocumentItem {
  pharmaCompanyID: string;
  pharmaCompanyDepartmentName: string;
  pharmaCompanyUserID: string;
  pharmaCompanyUserFirstName: string;
  pharmaCompanyUserLastName: string;
  pharmaCompanyUserPhoto: string;
  documentTypeIcon: string;
  documentTypeTitle: string;
  totalAttachedMessages: number;
  updatedDate: string;
  position: string;
}
