import { takeEvery } from 'modules/typed-saga';
import {
  actionArticleActivitiesGet,
  actionArticleHmosGet,
  actionArticleHospitalsGet,
  actionArticleMedicalFieldsGet,
  actionArticleUsersGet,
} from 'store/article/actions';
import { workerErrorNotifyThunk } from 'utils/store';

export const sagasArticle = [
  takeEvery(
    [
      actionArticleHmosGet.rejected,
      actionArticleMedicalFieldsGet.rejected,
      actionArticleActivitiesGet.rejected,
      actionArticleUsersGet.rejected,
      actionArticleHospitalsGet.rejected,
    ],
    workerErrorNotifyThunk,
  ),
];
