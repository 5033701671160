import { api, apiRtk, BaseParams, DynamicResult, transformResponseDynamic } from 'utils/service';
import {
  AnalyticsActivities,
  AnalyticsHMO,
  AnalyticsHospitals,
  AnalyticsMedicalFields,
  KPIArticleStat,
} from '../articles';
import { Expert } from '../experts';
import {
  Analytics,
  API_DASHBOARDS,
  CreditsPerUser,
  DocumentOverTime,
  GeneralKPI,
  GeneralKPIs,
  HmoKPI,
  HospitalKPI,
  InteractionOverTime,
  MedicalFieldKPI,
  PharmaUser,
  PharmaUserMessagesKPI,
} from './models';

export * from './models';

class Service {
  async getMessageKPI(params: BaseParams & { pharmaCompanyUserID: string }) {
    return api.get<PharmaUserMessagesKPI>(API_DASHBOARDS.GET_KPI_MESSAGE, { params });
  }
  async getCreditsPerUser(params: BaseParams) {
    return api.get<DynamicResult<CreditsPerUser>>(API_DASHBOARDS.GET_CREDITS_PER_USER, { params });
  }
  async getInteractionsOverTime(params: BaseParams) {
    return api.get<DynamicResult<InteractionOverTime>>(API_DASHBOARDS.GET_INTERACTIONS_OVER_TIME, {
      params,
    });
  }
  async getGeneralUsersViewsDynamic(params: BaseParams) {
    return await api.get<{ value: KPIArticleStat[]; count: number }>(
      API_DASHBOARDS.GET_KPI_VIEWS_DYNAMIC,
      {
        params,
      },
    );
  }

  async getGeneralUsersCommentsDynamic(params: BaseParams) {
    return await api.get<{ value: KPIArticleStat[]; count: number }>(
      API_DASHBOARDS.GET_KPI_COMMENTS_DYNAMIC,
      {
        params,
      },
    );
  }

  async getGeneralUsersPlaysDynamic(params: BaseParams) {
    return await api.get<{ value: KPIArticleStat[]; count: number }>(
      API_DASHBOARDS.GET_KPI_PLAYS_DYNAMIC,
      {
        params,
      },
    );
  }

  async getArticlesKPIs(articleID: string) {
    return await api.get<Partial<GeneralKPI>>(API_DASHBOARDS.GET_ARTICLE_KPI(articleID));
  }

  // async getArticleExposures(params: BaseParams) {
  //   return await api.get<{ value: Expert[]; count: number }>(
  //     API_DASHBOARDS.GET_ARTICLE_USER_EXPOSURES_DYNAMIC,
  //     {
  //       params,
  //     },
  //   );
  // }

  async getArticleAnalytics(params: BaseParams) {
    return await api.get<Analytics[]>(API_DASHBOARDS.GET_ARTICLE_ANALYTICS, { params });
  }

  async getArticleActivities(params: BaseParams) {
    return await api.get<AnalyticsActivities[]>(API_DASHBOARDS.GET_ARTICLE_ACTIVITIES, {
      params,
    });
  }

  async getArticleHmo(params: BaseParams) {
    return await api.get<AnalyticsHMO[]>(API_DASHBOARDS.GET_ARTICLE_HMO, {
      params,
    });
  }

  async getArticleMedicalFields(params: BaseParams) {
    return await api.get<AnalyticsMedicalFields[]>(API_DASHBOARDS.GET_ARTICLE_MEDICAL_FIELDS, {
      params,
    });
  }

  async getArticleHospitals(params: BaseParams) {
    return await api.get<AnalyticsHospitals[]>(API_DASHBOARDS.GET_ARTICLE_HOSPITALS, {
      params,
    });
  }

  async getMessagesHmo(params: BaseParams) {
    return await api.get<AnalyticsHMO[]>(API_DASHBOARDS.GET_MESSAGES_ANALYTICS_HMO, {
      params,
    });
  }

  async getMessagesMedicalFields(params: BaseParams) {
    return await api.get<AnalyticsMedicalFields[]>(
      API_DASHBOARDS.GET_MESSAGES_ANALYTICS_MEDICAL_FIELDS,
      {
        params,
      },
    );
  }

  async getMessagesHospitals(params: BaseParams) {
    return await api.get<AnalyticsHospitals[]>(API_DASHBOARDS.GET_MESSAGES_ANALYTICS_HOSPITALS, {
      params,
    });
  }

  async getDocuments(params: BaseParams) {
    return await api.get<{ value: DocumentOverTime[]; count: number }>(
      API_DASHBOARDS.GET_DOCUMENTS,
      {
        params,
      },
    );
  }

  // async getHMOUsers(params: any) {
  //   return await api.get<{ value: Expert[]; count: number }>(API_DASHBOARDS.GET_HMO_USERS_DYNAMIC, {
  //     params,
  //   });
  // }

  // async getMedicalFieldUsers(params: any) {
  //   return await api.get<{ value: Expert[]; count: number }>(
  //     API_DASHBOARDS.GET_MEDICAL_FIELDS_USERS_DYNAMIC,
  //     {
  //       params,
  //     },
  //   );
  // }

  // async getHospitalUsers(params: any) {
  //   return await api.get<{ value: Expert[]; count: number }>(
  //     API_DASHBOARDS.GET_HOSPITAL_USERS_DYNAMIC,
  //     {
  //       params,
  //     },
  //   );
  // }

  // async getActivityUsers(params: BaseParams) {
  //   return await api.get<{ value: Expert[]; count: number }>(
  //     API_DASHBOARDS.GET_ACTIVITIES_USERS_DYNAMIC,
  //     {
  //       params,
  //     },
  //   );
  // }
  async getAnalyticUsers(params: BaseParams) {
    return api.get<DynamicResult<Expert, typeof params>>(
      API_DASHBOARDS.GET_ARTICLE_ANALYTIC_USERS,
      { params },
    );
  }

  async getMessagesUsers(params: BaseParams) {
    return api.get<DynamicResult<Expert, typeof params>>(
      API_DASHBOARDS.GET_MESSAGES_ANALYTICS_USERS_DYNAMIC,
      { params },
    );
  }

  async getDocumentUsers(params: BaseParams) {
    return api.get<DynamicResult<Expert, typeof params>>(
      API_DASHBOARDS.GET_DOCUMENTS_USERS_DYNAMIC,
      { params },
    );
  }
}

export const ServiceDashboard = new Service();

export const apiDashboard = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGeneralKPI: build.query<GeneralKPIs, { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_DASHBOARDS.GET_KPI_GENERAL(pharmaCompanyID),
      }),
    }),
    getMedicalFieldsKPI: build.query<MedicalFieldKPI[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_DASHBOARDS.GET_KPI_MEDICAL_FIELDS(pharmaCompanyID),
      }),
      transformResponse: transformResponseDynamic,
    }),
    getHospitalsKPI: build.query<HospitalKPI[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_DASHBOARDS.GET_KPI_HOSPITALS(pharmaCompanyID),
      }),
      transformResponse: transformResponseDynamic,
    }),
    getHmosKPI: build.query<HmoKPI[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_DASHBOARDS.GET_KPI_HMOS(pharmaCompanyID),
      }),
      transformResponse: transformResponseDynamic,
    }),
    getPharmaCompanyUsers: build.query<PharmaUser[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_DASHBOARDS.GET_PHARMA_USERS_DYNAMIC,
        params: { pharmaCompanyID },
      }),
      transformResponse: transformResponseDynamic,
    }),
  }),
});
