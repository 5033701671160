import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React, { forwardRef, HTMLProps, memo, useEffect } from 'react';
import style from './index.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  className?: string;
  isVisible?: boolean;
}

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, isVisible, children, onScroll, ...rest }, ref) => {
    useEffect(() => () => {
      const list = document.querySelector(`.${style.root}`);

      if (list !== null) {
        list.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
    return (
      <Box ref={ref} className={clsx(style.root, className, isVisible && style.visible)} {...rest}>
        {children}
      </Box>
    );
  },
);

export const NativeScroll = memo(Component) as typeof Component;
