import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';

const selectState = (state: AppState) => state.dashboardActivities;

const selectDashboardActivitiesState = createSelector(selectState, (state) => state);
export const selectDashboardActivitiesFilters = createSelector(
  selectDashboardActivitiesState,
  ({ filters }) => filters,
);
