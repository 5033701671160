import { apiRtk, transformResponseDynamic } from 'utils/service';
import { API_PARAMETERS, BaseParameter, Tag } from './models';

export * from './models';

export const apiParameters = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getMedicalFields: build.query<BaseParameter[], void>({
      query: () => ({
        url: API_PARAMETERS.MEDICAL_FIELDS,
        params: { orderBy: 'title asc' },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getHospitals: build.query<BaseParameter[], void>({
      query: () => ({
        url: API_PARAMETERS.HOSPITALS,
        params: { orderBy: 'title asc' },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getTags: build.query<Tag[], void>({
      query: () => ({
        url: API_PARAMETERS.TAGS,
      }),
      transformResponse: transformResponseDynamic,
    }),
    getDepartments: build.query<BaseParameter[], { pharmaCompanyID: string }>({
      query: ({ pharmaCompanyID }) => ({
        url: API_PARAMETERS.DEPARTMENTS,
        params: { pharmaCompanyID },
      }),
      transformResponse: transformResponseDynamic,
    }),
    getHmos: build.query<BaseParameter[], void>({
      query: () => ({
        url: API_PARAMETERS.HMOS,
      }),
      transformResponse: transformResponseDynamic,
    }),
    getDocumentTypes: build.query<BaseParameter[], void>({
      query: () => ({
        url: API_PARAMETERS.DOCUMENT_TYPES,
      }),
      transformResponse: transformResponseDynamic,
    }),
  }),
});
