import { CoreLoading } from 'components/loading';
import { APP_ROUTES, routes } from 'configs/routes';
import { useAppSelector } from 'hooks';
import { LayoutEmpty } from 'layouts';
import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { selectAccountCompanyID, selectStateAccount } from 'store/account';

const _ScrollTop: React.FC<RouteComponentProps> = ({ children, history }) => {
  useEffect(() => {
    const subscribe = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    history.listen(subscribe);
  }, [history]);
  return <>{children}</>;
};
const ScrollPageTop = withRouter(_ScrollTop);

const PrivateWrap: React.FC<{ withPrivate: boolean }> = ({ children, withPrivate }) => {
  const { init, loading, user } = useAppSelector(selectStateAccount);

  if (!withPrivate) {
    return <>{children}</>;
  } else if (!init && loading) {
    return <h1>Loading...</h1>;
  } else if (init && !user) {
    const to = APP_ROUTES.LOGIN().config;
    return <Redirect to={to} />;
  } else if (init && user) {
    return <>{children}</>;
  } else {
    throw new Error('PrivateWrap -> Unexpected behaviour');
  }
};
const CompanyWrap: React.FC<{ withCompany: boolean }> = ({ children, withCompany }) => {
  const companyID = useAppSelector(selectAccountCompanyID);

  if (!withCompany) {
    return <>{children}</>;
  } else if (!companyID) {
    const to = APP_ROUTES.LOGIN().config;
    return <Redirect to={to} />;
  } else if (companyID) {
    return <>{children}</>;
  } else {
    throw new Error('PrivateWrap -> Unexpected behaviour');
  }
};

export const AppRouter: React.FC = () => {
  return (
    <ScrollPageTop>
      <Switch>
        {routes.map(
          ({
            component: Component,
            Layout = LayoutEmpty,
            private: privateRoute,
            company: companyRoute,
            ...props
          }) => {
            return (
              <Route key={props.path} {...props} exact={true}>
                <Suspense fallback={<CoreLoading active={true} />}>
                  <PrivateWrap withPrivate={privateRoute}>
                    <CompanyWrap withCompany={companyRoute}>
                      <Layout>
                        <Component />
                      </Layout>
                    </CompanyWrap>
                  </PrivateWrap>
                </Suspense>
              </Route>
            );
          },
        )}
      </Switch>
    </ScrollPageTop>
  );
};
