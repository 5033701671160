import { Divider, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { DialogPrivacy } from 'components/dialog-privacy';
import { DialogTerms } from 'components/dialog-terms';
import { Stack } from 'components/stack';
import { useOpen, useTranslate } from 'hooks';
import LogoSrc from 'images/logo-analytics.svg';
import React from 'react';
import style from './index.module.scss';

interface Props {}
export const LayoutVisitors: React.FC<Props> = ({ children }) => {
  const { t } = useTranslate();
  const termsState = useOpen();
  const privacyState = useOpen();
  return (
    <div className={clsx(style.root)}>
      <header className={style.header}>
        <img src={LogoSrc} className={style.logo} alt={''} />
        <Typography className={style.title} variant={'h1'} component={'h1'}>
          {t('welcome-to-vimed-analytics-title')}
        </Typography>
        <Typography className={style.subTitle} variant={'h1'} component={'p'}>
          {t('welcome-to-vimed-analytics-subtitle')}
        </Typography>
      </header>
      <main>{children}</main>
      <footer className={style.footer}>
        <Stack spacing={1}>
          <Typography>{t('copyright')}</Typography>
          <Link onClick={termsState.handleOpen} component={'button'} color={'textPrimary'}>
            {t('terms')}
          </Link>
          <Divider orientation={'vertical'} flexItem light />
          <Link onClick={privacyState.handleOpen} component={'button'} color={'textPrimary'}>
            {t('privacy')}
          </Link>
        </Stack>
      </footer>
      {termsState.isOpen && <DialogTerms onClose={termsState.handleClose} />}
      {privacyState.isOpen && <DialogPrivacy onClose={privacyState.handleClose} />}
    </div>
  );
};
