const API_ENDPOINT = 'Messages';

export const API_MESSAGES = {
  API_ENDPOINT,
  GET_MESSAGES_DYNAMIC: `${API_ENDPOINT}/GetMessagesDynamic`,
  GET_MESSAGE_DOCS_DYNAMIC: `${API_ENDPOINT}/GetMessageDocumentsDynamic`,
  GET_MESSAGE_BY_ID: (messageID: string) => `${API_ENDPOINT}/GetMessageByID/${messageID}`,
  GET_MESSAGE_BY_TOKEN: (token: string) => `${API_ENDPOINT}/GetMessageByToken/${token}`,
  SEND_MESSAGE: (messageID: string, userID: string) =>
    `${API_ENDPOINT}/SendMessage/${messageID}/${userID}`,
  CREATE_MESSAGE: `${API_ENDPOINT}/CreateMessage`,
  ATTACH_DOC: `${API_ENDPOINT}/AttachDocumentToMessage`,
  DELETE_MESSAGE: (id: Message['messageID']) => `${API_ENDPOINT}/DeleteMessage/${id}`,
  DETACH_DOC: (id: Message['messageID']) => `${API_ENDPOINT}/DetachDocumentFromMessage/${id}`,
  PATCH: (data: { id: Message['messageID'] }) => `${API_ENDPOINT}/PatchMessage/${data.id}`,
  REPLY: (token: Message['token']) => `${API_ENDPOINT}/ReplyToMessage/${token}`,
  OPEN_DOCUMENT: `${API_ENDPOINT}/OpenDocument`,
  ATTACH_DOCUMENTS_BULK: `${API_ENDPOINT}/AttachDocumentsBulkToMessage`,
  GET_MESSAGE_LOG_DYNAMIC: `${API_ENDPOINT}/GetMessageLogsDynamic`,
  GET_MESSAGES_FOR_DOC_DYNAMIC: `${API_ENDPOINT}/GetMessagesForDoctorsDynamic`,
};

export class MessagePatch {
  messageID!: string;
  message: string = '';
  subject: string = '';
  pharmaCompanyUserID: string = '';
  userExpertProfileID: string = '';
  documentIDs: string[] = [];
}

export interface Message extends Omit<MessagePatch, 'documentIDs'> {
  messageDate: string;
  pharmaCompanyUserFirstName: string;
  pharmaCompanyUserLastName: string;
  pharmaCompanyUserPhoto: string;
  pharmaCompanyUserPosition: string;
  isAdmin: boolean;
  maxCredits: number;
  pharmaCompanyDepartmentID: string;
  pharmaCompanyDepartmentTitle: string;
  pharmaCompanyName: string;
  userExpertProfileFirstName: string;
  userExpertProfileLastName: string;
  userExpertProfileTitleOfName: string;
  userExpertProfileUserPhoto: string;
  userExpertProfileUserColor: string;
  userExpertProfilePrimaryMedicalFieldID: string;
  userExpertProfilePrimaryMedicalFieldTitle: string;
  token: string;
  replyDate: string;
  reply: string;
  replies: string[];
  isDraft: true;
  totalDocuments: number;
  documents: Document[];
}

export interface MessageDocs {
  messageDocumentID: string;
  documentID: string;
  messageID: string;
  linkURL: string;
  documentTypeID: string;
  documentTypeTitle: string;
  documentTitle: string;
  documentDate: string;
}

export interface Document {
  documentDate: string;
  documentID: string;
  documentTitle: string;
  documentTypeID: string;
  documentTypeTitle: string;
  documentTypeIcon: string;
  linkURL: string;
  messageDocumentID: string;
  messageID: string;
}

export interface OpenDocumentProps {
  token: string;
  userExpertProfileID: string;
  messageID: string;
  messageDocumentID: string;
}

export interface MessageLog {
  messageID: string;
  eventTime: string;
  eventTitle: string;
  messageReplied: boolean;
}

export interface MessagesForDoctors {
  userExpertProfileID: string;
  userExpertProfileFirstName: string;
  userExpertProfileLastName: string;
  userExpertProfileTitleOfName: string;
  userExpertProfileUserPhoto: string;
  userExpertProfileUserColor: string;
  userExpertProfilePrimaryMedicalFieldID: string;
  userExpertProfilePrimaryMedicalFieldTitle: string;
  userExpertProfileSecondaryMedicalFieldID: string;
  userExpertProfileSecondaryMedicalFieldTitle: string;
  lastMessageDate: string;
  totalMessageSent: number;
  totalMessageViewed: number;
}
