import { actionChannel, call, debounce, put, select, take, takeEvery } from 'modules/typed-saga';
import { ServiceAccount } from 'services/account';
import { selectAccountUser } from 'store/account/selectors';
import { actionAccountAutoSave } from 'store/account/slice';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifyThunk } from 'utils/store';
import {
  actionAccountGetUser,
  actionAccountLogout,
  actionAccountUpdateUser,
  actionAccountUpdateUserCompany,
  actionAccountVisitorLogin,
} from './actions';

export const ERROR_AS_SUCCESS_GENERATE = 'already-generated';

function* patchUser(action: ReturnType<typeof actionAccountAutoSave.request>) {
  try {
    const oldUser = yield* select(selectAccountUser);
    if (!oldUser) {
      throw new Error('unexpected-behaviour');
    }
    yield* call(ServiceAccount.patch, action.payload, oldUser);
    yield* put(actionAccountAutoSave.success());
  } catch (e) {
    yield* put(actionAccountAutoSave.fail({ error: parseErrorData(e) }));
  }
}

function* sagaAutoSave() {
  const channel = yield* actionChannel(actionAccountAutoSave.request);

  while (true) {
    const action = yield* take(channel);
    yield* call(patchUser, action);
  }
}
function* watchAutoSaveSuccess() {
  yield* put(actionAccountGetUser());
}

export const sagasAccount = [
  sagaAutoSave(),
  debounce(5000, actionAccountAutoSave.success, watchAutoSaveSuccess),
  takeEvery(
    [
      actionAccountVisitorLogin.rejected,
      actionAccountLogout.rejected,
      actionAccountUpdateUser.rejected,
      actionAccountUpdateUserCompany.rejected,
    ],
    workerErrorNotifyThunk,
  ),
];
