import React, { useEffect, useState } from 'react';
import { getAuthTokens } from 'utils/cookies';
import { api } from 'utils/service';

let isRefreshing = false;
let refreshingCall: Promise<any>;

interface AxiosInterceptorsRefreshToken {
  onRefresh: () => Promise<{ token: string; refreshToken: string; expires: string }>;
  onSuccess: (p: { token: string; refreshToken: string; expires: string }) => void;
  onError: (e: Error) => void;
}

export const AxiosInterceptorsRefreshToken: React.FC<AxiosInterceptorsRefreshToken> = ({
  children,
  onRefresh,
  onSuccess,
  onError,
}) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
    const ID = api.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;

        const { token: Token, refreshToken: RefreshToken } = getAuthTokens();

        if (status !== 401 || !(Token && RefreshToken)) {
          return Promise.reject(error);
        }

        if (isRefreshing) {
          return refreshingCall.then(() => {
            return api(error.config);
          });
        }
        isRefreshing = true;
        refreshingCall = onRefresh()
          .then((res) => {
            isRefreshing = false;
            const { token, refreshToken, expires } = res;
            onSuccess({ token, refreshToken, expires });
            return api(error.config);
          })
          .catch((err) => {
            isRefreshing = false;
            onError(err);
            return Promise.reject(err);
          });
        return refreshingCall;
      },
    );

    return () => {
      api.interceptors.response.eject(ID);
    };
  }, [onRefresh, onSuccess, onError]);

  return <>{canRender ? children : null}</>;
};
