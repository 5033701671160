export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};

export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isLastPage,
  };
};
