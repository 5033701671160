import { useCallback, useState } from 'react';

interface Request<T = any> {
  (...args: any[]): Promise<{ data: T }>;
}

type RequestData<T> = T extends Request<infer U> ? U : never;

interface UseGetRequestProps<T extends Request> {
  request: T;
  loading?: boolean;
}

interface UseGetRequestMethods<T extends Request> {
  data: RequestData<T> | null;
  loading: boolean;
  error: Error | null;
  request: T;

  setData(v: this['data']): void;

  setLoading(v: this['loading']): void;

  setError(v: this['error']): void;
}

export const useGetRequest = <T extends Request>({
  request: originRequest,
  loading: initLoading = false,
}: UseGetRequestProps<T>): UseGetRequestMethods<T> => {
  const [error, setError] = useState<UseGetRequestMethods<T>['error']>(null);
  const [loading, setLoading] = useState<UseGetRequestMethods<T>['loading']>(initLoading);
  const [data, setData] = useState<UseGetRequestMethods<T>['data']>(null);

  const request = useCallback(
    async (...args: any[]) => {
      setError(null);
      setLoading(true);
      try {
        const result = await originRequest(...args);
        const { data: requestData } = result;
        setData(requestData);
        setLoading(false);
        return result;
      } catch (e) {
        setLoading(false);
        setError(e);
        throw e;
      }
    },
    [originRequest],
  ) as UseGetRequestMethods<T>['request'];

  return {
    data,
    setData,
    error,
    setError,
    loading,
    setLoading,
    request,
  };
};
