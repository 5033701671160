import { toDate as fnsToDate } from 'date-fns';

export type DateValue = Date | string | number | null | undefined;

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};
