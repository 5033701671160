import { call, put, select, takeEvery, throttle } from 'modules/typed-saga';
import {
  MessageReplyTemplateItem,
  ServiceMessageReplyTemplates,
} from 'services/message-reply-templates';
import { createFilter } from 'utils';
import { parseErrorData } from 'utils/service';
import { workerErrorNotifySaga } from 'utils/store';
import { selectAccountCompanyID } from '../account';
import { selectMessageReplyTemplatesFilters } from './selectors';
import { actionMessageReplyTemplatesGet } from './slice';

function* workerGetMessageTemplates() {
  const { search } = yield* select(selectMessageReplyTemplatesFilters);
  const pharmaCompanyID = yield* select(selectAccountCompanyID);

  const filter = createFilter<MessageReplyTemplateItem>(['title'], 'contains', search);

  try {
    if (!pharmaCompanyID) {
      throw new Error('unexpected-behaviour');
    }
    const {
      data: { value },
    } = yield* call(ServiceMessageReplyTemplates.getAllDynamic, {
      pharmaCompanyID,
      filter,
    });
    yield* put(actionMessageReplyTemplatesGet.success({ value }));
  } catch (e) {
    yield* put(actionMessageReplyTemplatesGet.fail({ error: parseErrorData(e) }));
  }
}

export const sagasMessageReplyTemplates = [
  throttle(1500, actionMessageReplyTemplatesGet.request, workerGetMessageTemplates),
  takeEvery([actionMessageReplyTemplatesGet.fail], workerErrorNotifySaga),
];
