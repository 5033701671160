import { useTranslate } from 'hooks/use-translate';
import { useCallback } from 'react';
import {} from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';

interface Options {
  onChange: (...event: any[]) => void;
  onBlur: () => void;
  value: any;
  name: string;
}

interface Props {
  errors: Record<string, { message?: string } | undefined>;
}

export const useFormInputProps = (props: Props) => {
  const { t } = useTranslate();
  return useCallback(
    (field: Options) => {
      const error = props.errors[field.name];
      const helperText = error?.message ? t(error.message) : ' ';

      return {
        ...field,
        helperText,
        error: Boolean(error),
        fullWidth: true,
        label: t(fieldToLabelKey(field.name)),
      };
    },
    [t, props],
  );
};
