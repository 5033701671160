import { useMemo } from 'react';
import { Control, UseFormMethods } from 'react-hook-form';

export const useFormControlProps = <T>(props: {
  control: Control<T>;
  errors: UseFormMethods<T>['errors'];
  name: keyof T;
}) => {
  const { name, control, errors } = props;
  return useMemo(() => {
    return {
      name,
      control,
      error: Boolean(errors[name]),
      // @ts-ignore
      helperText: Array.isArray(errors[name]) ? undefined : errors[name]?.message,
    };
  }, [name, control, errors]);
};
