const API_ENDPOINT = 'MessageReplyTemplates';

export const API_MESSAGE_REPLY_TEMPLATES = {
  API_ENDPOINT,
  GET_MESSAGE_REPLY_TEMPLATES_DYNAMIC: `${API_ENDPOINT}/GetMessageReplyTemplatesDynamic`,
  IMPORT_MESSAGE_REPLY_TEMPLATES: `${API_ENDPOINT}/ImportMessageReplyTemplates`,
  POST: `${API_ENDPOINT}/CreateMessageReplyTemplate`,
  DELETE: (data: { id: MessageReplyTemplateItem['id'] }) =>
    `${API_ENDPOINT}/DeleteMessageReplyTemplate/${data.id}`,
  PATCH: (data: { id: MessageReplyTemplateItem['id'] }) =>
    `${API_ENDPOINT}/PatchMessageReplyTemplate/${data.id}`,
};

export class MessageReplyTemplateItem {
  id!: string;
  title = '';
}

export interface MessageReplyTemplateProps extends MessageReplyTemplateItem {
  pharmaCompanyID: string;
}
