import { useMap } from 'hooks/use-map';
import { useCallback, useMemo } from 'react';

type DefaultModel = { id: string };

interface UseSelectBaseProps<T extends Record<string, any> = DefaultModel> {
  options?: T[];
  valueKey?: keyof T;
}

interface UseSelectSingleProps<T extends Record<string, any> = DefaultModel>
  extends UseSelectBaseProps<T> {
  multiple?: false;
  value?: string;
  onChange?: (v: string) => void;
}
interface UseSelectMultipleProps<T extends Record<string, any> = DefaultModel>
  extends UseSelectBaseProps<T> {
  multiple: true;
  value?: string[];
  onChange?: (v: string[], option?: T) => void;
  options?: T[];
}

type UseSelectProps<T extends Record<string, any> = DefaultModel> =
  | UseSelectMultipleProps<T>
  | UseSelectSingleProps<T>;

export const useSelect = <T extends Record<string, any> = DefaultModel>(
  props: UseSelectProps<T>,
) => {
  const { options = [], valueKey = 'id' } = props;

  const values = useMemo(() => {
    if (Array.isArray(props.value)) {
      return props.value;
    } else if (props.value) {
      return [props.value];
    } else {
      return [];
    }
  }, [props.value]);
  const mapSelected = useMap(values);

  const onChangeItem = useCallback(
    (v: string) => {
      if (props.multiple) {
        const newValues = mapSelected[v] ? values.filter((value) => value !== v) : [...values, v];
        props.onChange && props.onChange(newValues);
      } else {
        props.onChange && props.onChange(v);
      }
    },
    [props, values, mapSelected],
  );

  const isSelectedAll = useMemo(() => {
    return values.length === options.length;
  }, [values.length, options.length]);
  const onSelectAll = useCallback(() => {
    if (props.multiple) {
      props.onChange && props.onChange(isSelectedAll ? [] : options.map((item) => item[valueKey]));
    }
  }, [props, options, isSelectedAll, valueKey]);

  return { mapSelected, onChangeItem, onSelectAll, isSelectedAll };
};
