import { AppState } from '../index';
import { createSelector } from '@reduxjs/toolkit';
import { calcPaginationState } from 'utils/pagination';

export const selectStateExperts = (state: AppState) => state.experts;
export const selectStateExpertsFilters = createSelector(selectStateExperts, ({ filters }) => {
  return filters;
});
export const selectStateExpertsPagination = createSelector(
  selectStateExperts,
  ({ take, page, count }) => {
    return calcPaginationState({ take, page, count });
  },
);
export const selectExpertsSort = createSelector(selectStateExperts, ({ sort }) => {
  return sort;
});
