import { createFilter, PatchModel } from 'utils';
import {
  api,
  apiRtk,
  API_RTK_TAGS,
  BaseParams,
  parseErrorData,
  transformResponseDynamic,
  transformResponseDynamicItem,
} from 'utils/service';
import {
  API_MESSAGE_REPLY_TEMPLATES,
  MessageReplyTemplateItem,
  MessageReplyTemplateProps,
} from './models';

export * from './models';

class Service {
  async getAllDynamic(params: BaseParams) {
    return await api.get<{ value: MessageReplyTemplateProps[] }>(
      API_MESSAGE_REPLY_TEMPLATES.GET_MESSAGE_REPLY_TEMPLATES_DYNAMIC,
      {
        params,
      },
    );
  }

  async create(data: MessageReplyTemplateItem) {
    const { ...rest } = data;
    const result = await api.post<MessageReplyTemplateProps>(
      API_MESSAGE_REPLY_TEMPLATES.POST,
      rest,
    );
    await ServiceMessageReplyTemplates.patch({ ...rest }, result.data);

    return { data: { ...result.data } };
  }

  async patch(
    newData: Partial<MessageReplyTemplateProps>,
    oldData: PatchModel<MessageReplyTemplateProps, 'id'>,
  ) {
    return api.patch(API_MESSAGE_REPLY_TEMPLATES.PATCH(oldData), { ...newData });
  }

  async delete(p: { id: string }) {
    return api.delete(API_MESSAGE_REPLY_TEMPLATES.DELETE(p));
  }

  async importMessageReplyTemplatesFromVimed(params: BaseParams) {
    return await api.get(API_MESSAGE_REPLY_TEMPLATES.IMPORT_MESSAGE_REPLY_TEMPLATES, {
      params,
    });
  }
}

export const ServiceMessageReplyTemplates = new Service();

export const apiMessageReplyTemplates = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllMessageReplyTemplates: build.query<
      MessageReplyTemplateProps[],
      { pharmaCompanyID: string }
    >({
      query: ({ pharmaCompanyID }) => ({
        url: API_MESSAGE_REPLY_TEMPLATES.GET_MESSAGE_REPLY_TEMPLATES_DYNAMIC,
        params: {
          pharmaCompanyID,
        },
      }),
      transformResponse: transformResponseDynamic,
      providesTags: [{ type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES }],
    }),
    getMessageReplyTemplate: build.query<
      MessageReplyTemplateItem,
      { templateID: string; pharmaCompanyID: string }
    >({
      query: ({ templateID, pharmaCompanyID }) => ({
        url: API_MESSAGE_REPLY_TEMPLATES.GET_MESSAGE_REPLY_TEMPLATES_DYNAMIC,
        params: {
          take: 1,
          pharmaCompanyID,
          filter: createFilter<MessageReplyTemplateItem>('id', '==', templateID),
        },
      }),
      transformResponse: transformResponseDynamicItem,
      providesTags: (result, error, { templateID }) => [
        { type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES, id: templateID },
      ],
    }),
    addMessageReplyTemplate: build.mutation<MessageReplyTemplateProps, MessageReplyTemplateProps>({
      queryFn: async (data) => {
        try {
          const result = await ServiceMessageReplyTemplates.create(data);
          return { data: result.data };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: [{ type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES }],
    }),
    updateMessageReplyTemplate: build.mutation<
      void,
      {
        newData: Partial<MessageReplyTemplateProps>;
        oldData: PatchModel<MessageReplyTemplateProps, 'id'>;
      }
    >({
      queryFn: async ({ newData, oldData }) => {
        try {
          await ServiceMessageReplyTemplates.patch(newData, oldData);
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { oldData }) => [
        { type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES },
        { type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES, id: oldData.id },
      ],
    }),
    removeMessageReplyTemplate: build.mutation<void, PatchModel<MessageReplyTemplateItem, 'id'>>({
      queryFn: async ({ id }) => {
        try {
          await ServiceMessageReplyTemplates.delete({ id });
          return { data: undefined };
        } catch (e) {
          return { error: parseErrorData(e) };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES },
        { type: API_RTK_TAGS.MESSAGE_REPLY_TEMPLATES, id },
      ],
    }),
  }),
});
